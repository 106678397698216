"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("高效·保障·安全的一站式危化物流解决方案")]), _c("div", {
    staticClass: "flex-row-sb align-item-center flex-warp margin-top-40"
  }, _vm._l(_vm.resolveList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-center margin-top-20"
    }, [_c("img", {
      staticClass: "width-44",
      attrs: {
        src: item.url
      }
    }), _c("span", {
      staticClass: "font-20 color-444444 margin-top-15"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("服务优势")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25 line-height-16 text-left",
    staticStyle: {
      "text-indent": "2em"
    }
  }, [_vm._v(" 危化镖局作为国内化工行业最大的物流、仓储综合服务平台，日均发布物流订单10万单，有15万合作运力，依托于强大的运力体系，联合三方物流服务商，依靠强大技术和运营管控支持，根据路线及车辆载货信息，智能匹配货源需求，实时推荐沿途货源，优化企业运输模式，为企业实现降本增效之目的，为行业提供更优质的服务。 ")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-90"
  }, _vm._l(_vm.serviceList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-start"
    }, [_c("img", {
      staticClass: "width-240",
      attrs: {
        src: item.url
      }
    }), _c("span", {
      staticClass: "font-22 color-212121 margin-top-50"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-6E6E6E margin-top-20"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("物流解决方案")]), _c("div", {
    staticClass: "flex-row-sb align-item-center flex-warp margin-top-40"
  }, _vm._l(_vm.programList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-start margin-top-20"
    }, [_c("span", {
      staticClass: "font-20 color-3269F6 font-weight-500"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-20 text-align-left"
    }, [_vm._v(_vm._s(item.desc))]), item.dot ? _c("ul", {
      staticClass: "margin-top-45"
    }, _vm._l(item.dot, function (citem, index) {
      return _c("li", {
        staticClass: "font-16 color-3269F6 text-align-left line-height-16",
        staticStyle: {
          "list-style-type": "disc",
          "margin-left": "1.5em"
        }
      }, [_c("span", {
        staticClass: "color-777777"
      }, [_vm._v(_vm._s(citem))])]);
    }), 0) : _vm._e(), _c("div", {
      staticClass: "item-header font-20 color-white"
    }, [_vm._v(_vm._s(item.header))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("专项物流解决方案")]), _c("div", {
    staticClass: "flex-row-sb align-item-center flex-warp margin-top-40"
  }, _vm._l(_vm.SpecialList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-start margin-top-20"
    }, [_c("span", {
      staticClass: "font-24 color-2A559D font-weight-500"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-20 text-align-left"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _vm._m(0), _c("appointment-four", {
    attrs: {
      sourceType: "60"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-05"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("img", {
    staticClass: "width-440",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/logistics/16.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "item text-align-left"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("合作案例——宝武化工宝")]), _c("ul", {
    staticClass: "margin-top-45"
  }, [_c("li", {
    staticClass: "font-16 color-3269F6 text-align-left line-height-16",
    staticStyle: {
      "list-style-type": "disc",
      "margin-left": "1.5em"
    }
  }, [_c("span", {
    staticClass: "color-6E6E6E"
  }, [_vm._v(" 化工宝作为上海宝武碳业旗下煤化领域大宗交易平台，行业内有着超过12%市场份额，危化镖局与化工宝物流对接，其化工交易客户通过危化镖局寻找运输伙伴。 ")])]), _c("li", {
    staticClass: "font-16 color-3269F6 text-align-left line-height-16 margin-top-10",
    staticStyle: {
      "list-style-type": "disc",
      "margin-left": "1.5em"
    }
  }, [_c("span", {
    staticClass: "color-6E6E6E"
  }, [_vm._v(" 2022年化工宝通过危化镖局发货量超10万吨，产品为煤焦油、粗苯、轻苯、工业萘、过硫酸铵、沥青等。 ")])]), _c("li", {
    staticClass: "font-16 color-3269F6 text-align-left line-height-16 margin-top-10",
    staticStyle: {
      "list-style-type": "disc",
      "margin-left": "1.5em"
    }
  }, [_c("span", {
    staticClass: "color-6E6E6E"
  }, [_vm._v(" 危化镖局发挥平台优势，充分整合运力资源，为化工宝平台用户及宝武集团旗下各大工厂提供运力服务，为其降低时间成本、运输成本、人工成本超过13%，并提供全国20多家危化品仓储服务网点，全流程提升企业货物流转效率。 ")])])])])])]);
}];
render._withStripped = true;