"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _typeof2 = _interopRequireDefault(require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/typeof.js"));
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
var _vue = _interopRequireDefault(require("vue"));
var _vueRouter = _interopRequireDefault(require("vue-router"));
var _MainLayout = _interopRequireDefault(require("./components/MainLayout.vue"));
var _welcome = _interopRequireDefault(require("./views/welcome/welcome"));
var _landingpage = _interopRequireDefault(require("./views/landingpage/landingpage.vue"));
var _reg_first = _interopRequireDefault(require("./views/landingpage/reg_first.vue"));
var _reg_second = _interopRequireDefault(require("./views/landingpage/reg_second.vue"));
var _reg_third = _interopRequireDefault(require("./views/landingpage/reg_third.vue"));
var _regpage = _interopRequireDefault(require("./views/landingpage/regpage"));
var _downguid = _interopRequireDefault(require("./components/downguid.vue"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != (0, _typeof2.default)(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
_vue.default.use(_vueRouter.default);
var _default = exports.default = new _vueRouter.default({
  mode: 'history',
  base: '/ptnpc',
  routes: [{
    path: '/',
    name: 'mainlayout',
    component: _MainLayout.default,
    children: [
    // 	{
    // 	path: '/',
    // 	name: 'welcome',
    // 	component: Welcome,
    // 	meta: {
    // 		metaInfo: {
    // 			title: '危化镖局-危化品物流平台-危险化学品运输-危险品押运',
    // 			keywords: '危化镖局,安全管理条例,油罐车,危险品车,危险化学品,危化品,危险品,运输,危运员,押运员,运输车,物流平台,货车导航,招聘',
    // 			description: '危化镖局专注危险化学品物流行业的交易、物流、仓储以及线上线下延伸服务的专业解决方案供应商。以化工行业独有的物流管理系统、仓储管理系统，一站式交易与信息平台三大主体功能，专业为化工企业、货运司机以及下游工厂提供一站式综合供应链服务。'
    // 		}
    // 	}
    // }, 
    {
      path: '/',
      redirect: '/home'
    }, {
      path: '/home',
      name: 'home',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/home/home.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局-危化品物流平台-危险化学品运输-危险品押运',
          keywords: '危化镖局,安全管理条例,油罐车,危险品车,危险化学品,危化品,危险品,运输,危运员,押运员,运输车,物流平台,货车导航,招聘',
          description: '危化镖局专注危险化学品物流行业的交易、物流、仓储以及线上线下延伸服务的专业解决方案供应商。以化工行业独有的物流管理系统、仓储管理系统，一站式交易与信息平台三大主体功能，专业为化工企业、货运司机以及下游工厂提供一站式综合供应链服务。'
        }
      }
    },
    // PC落地页  外部网站登陆后跳到这个页面
    {
      path: '/groundPage',
      name: 'groundPage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/groundPage/groundPage.vue"));
        });
      }
    }, {
      path: '/wllm',
      name: 'wllm',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/wllm/wllm.vue"));
        });
      }
    }, {
      path: '/software',
      name: 'software',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/software.vue"));
        });
      }
    }, {
      path: '/insurance',
      name: 'insurance',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/insurance.vue"));
        });
      }
    }, {
      path: '/wisdom5G',
      name: 'wisdom5G',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/wisdom5G.vue"));
        });
      }
    }, {
      path: '/TMS',
      name: 'TMS',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/TMS.vue"));
        });
      }
    }, {
      path: '/WMS',
      name: 'WMS',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/WMS.vue"));
        });
      }
    }, {
      path: '/chain',
      name: 'chain',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/chain.vue"));
        });
      }
    }, {
      path: '/logisticsPark',
      name: 'logisticsPark',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/logisticsPark.vue"));
        });
      }
    }, {
      path: '/bussiness',
      name: 'bussiness',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/bussiness.vue"));
        });
      }
    }, {
      path: '/finance',
      name: 'finance',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/finance.vue"));
        });
      }
    }, {
      path: '/logistics',
      name: 'logistics',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/softBusinessLogistics/logistics.vue"));
        });
      }
    }, {
      path: '/products',
      name: 'products',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/products/products_home.vue"));
        });
      },
      children: [{
        path: '/tms',
        name: 'tms',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require("./views/products/tms.vue"));
          });
        },
        meta: {
          metaInfo: {
            title: '危化镖局产品与服务-化工运输综合服务',
            keywords: '化工园区WMS,危险品运输TMS,危险品车辆买卖,危运招聘求职,押运员,化工运输招标,' + '危险品运输招标,化工仓储,危运车辆保险',
            description: '提供化工供应链包括交易、运输、车后系列服务'
          }
        }
      }, {
        path: '/pt',
        name: 'pt',
        component: function component() {
          return Promise.resolve().then(function () {
            return _interopRequireWildcard(require("./views/products/pt.vue"));
          });
        },
        meta: {
          metaInfo: {
            title: '危化镖局产品与服务-化工运输综合服务',
            keywords: '化工园区WMS,危险品运输TMS,危险品车辆买卖,危运招聘求职,押运员,化工运输招标,' + '危险品运输招标,化工仓储,危运车辆保险',
            description: '提供化工供应链包括交易、运输、车后系列服务'
          }
        }
      }]
    }, {
      path: '/waybill',
      name: 'waybill',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/waybill/waybill.vue"));
        });
      }
    }, {
      path: '/chemicalMall',
      name: 'chemicalMall',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/chemicalMall/chemicalMall.vue"));
        });
      }
    }, {
      path: '/chemicalStorage',
      name: 'chemicalStorage',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/chemicalStorage/chemicalStorage.vue"));
        });
      }
    }, {
      path: '/specialVehicle',
      name: 'specialVehicle',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/specialVehicle/specialVehicle.vue"));
        });
      }
    }, {
      path: '/businessjoin',
      name: 'businessjoin',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/businessjoin/businessjoin.vue"));
        });
      }
    }, {
      path: '/WMS',
      name: 'WMS',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/wms/WMS.vue"));
        });
      }
    }, {
      path: '/aboutus',
      name: 'aboutus',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/aboutus/aboutus.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局产品与服务-化工运输综合服务',
          keywords: '化工园区WMS,危险品运输TMS,危险品车辆买卖,危运招聘求职,押运员,化工运输招标,' + '危险品运输招标,化工仓储,危运车辆保险',
          description: '提供化工供应链包括交易、运输、车后系列服务'
        }
      }
    }, {
      path: '/newslist',
      name: 'newslist',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/news/newslist.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局·危化头条-一手政策解读,行业资讯先览',
          keywords: '化工政策,危运政策,危险品政策,危险品车辆限行,化工资讯,危运资讯,MSDS,道路限行,化工生产',
          description: '提供化工及物流行业的资讯政策消息'
        }
      }
    }, {
      path: '/msdsList',
      name: 'msdsList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/msds/msdsList.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局·MSDS-一手政策解读,行业资讯先览',
          keywords: '化工政策,危运政策,危险品政策,危险品车辆限行,化工资讯,危运资讯,MSDS,道路限行,化工生产',
          description: '提供化工及物流行业的资讯政策消息'
        }
      }
    }, {
      path: '/search',
      name: 'search',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/news/newslist.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局·危化头条-一手政策解读,行业资讯先览',
          keywords: '化工政策,危运政策,危险品政策,危险品车辆限行,化工资讯,危运资讯,MSDS,道路限行,化工生产',
          description: '提供化工及物流行业的资讯政策消息'
        }
      }
    }, {
      path: '/cooperationMall/zpList',
      name: 'mallZpList',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/cooperationMall/cooperationMall.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化商铺'
        }
      }
    }, {
      path: '/detail',
      name: 'detail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/news/newslist.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局·危化头条-一手政策解读,行业资讯先览',
          keywords: '化工政策,危运政策,危险品政策,危险品车辆限行,化工资讯,危运资讯,MSDS,道路限行,化工生产',
          description: '提供化工及物流行业的资讯政策消息'
        }
      }
    }, {
      path: '/goods',
      name: 'goods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/goods.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/cars',
      name: 'cars',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/cars.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/pubcars',
      name: 'pubcars',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/pubcars.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/pubgoods',
      name: 'pubgoods',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/pubgoods.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/pubcarsucceed',
      name: 'pubcarsucceed',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/pubcarsucceed.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/pubgoodsucceed',
      name: 'pubgoodsucceed',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/pubgoodsucceed.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/goodsdetail',
      name: 'goodsdetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/goodsdetail.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/cardetail',
      name: 'cardetail',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/cardetail.vue"));
        });
      },
      meta: {
        metaInfo: {
          title: '危化镖局交易大厅-找车找货,每日数万危险品货源更新',
          description: '化工运输危险品运输发布货源发布车源',
          keywords: '危险品货源,危险品车源,找车,找货,发布货源,发布车源,化工运输,危险品物流'
        }
      }
    }, {
      path: '/batchpub',
      name: 'batchpub',
      component: function component() {
        return Promise.resolve().then(function () {
          return _interopRequireWildcard(require("./views/trade/batchpub.vue"));
        });
      }
    }]
  }, {
    path: '/landingpage',
    name: 'landingpage',
    component: _landingpage.default,
    meta: {
      hiddenZooS: true
    }
  }, {
    path: '/landingpageRegFirst',
    name: 'reg_first',
    component: _reg_first.default,
    meta: {
      hiddenZooS: true
    }
  }, {
    path: '/landingpageRegSecond',
    name: 'reg_second',
    component: _reg_second.default,
    meta: {
      hiddenZooS: true
    }
  }, {
    path: '/landingpageRegThird',
    name: 'reg_third',
    component: _reg_third.default,
    meta: {
      hiddenZooS: true
    }
  }, {
    path: '/regpage',
    name: 'regpage',
    component: _regpage.default,
    meta: {
      hiddenZooS: true
    }
  }, {
    path: '/machinehaltpage',
    name: 'machinehaltpage',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/machinehaltpage/machinehaltpage.vue"));
      });
    }
  }, {
    path: '/downguid',
    name: 'downguid',
    component: _downguid.default
  }, {
    path: '/aboutus',
    query: {
      type: 'toCompany'
    },
    redirect: '/aboutus?to=introduce' // 关于我们路由重定向
  },
  //去掉头部 底部的车辆交易
  {
    path: '/newCooperationMall',
    name: 'newCooperationMall',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/newCooperationMall.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '车辆买卖'
      },
      hiddenZooS: true
    }
  }, {
    path: '/sellDetail',
    name: 'sellDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/sellDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '卖车详情'
      },
      hiddenZooS: true
    }
  }, {
    path: '/boughtDetail',
    name: 'boughtDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/boughtDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '买车详情'
      },
      hiddenZooS: true
    }
  }, {
    path: '/qzDetail',
    name: 'qzDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/qzDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '求职详情'
      },
      hiddenZooS: true
    }
  }, {
    path: '/zpDetail',
    name: 'zpDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/zpDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '招聘详情'
      },
      hiddenZooS: true
    }
  }, {
    path: '/dealWasteDetail',
    name: 'dealWasteDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/dealWasteDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '我可处废'
      },
      hiddenZooS: true
    }
  }, {
    path: '/needWasteDetail',
    name: 'needWasteDetail',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/cooperationMall/newCooperationMall/detail/needWasteDetail.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '我需处废'
      },
      hiddenZooS: true
    }
  },
  //去掉头部 底部的msds
  {
    path: '/newMsdsList',
    name: 'newMsdsList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/msds/newMsds/newMsdsList.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: 'MSDS'
      },
      hiddenZooS: true
    }
  },
  //去掉头部 底部的newsList  需要在路径后拼接 ?isSearch=false&&isDetail=false
  {
    path: '/newNewsList',
    name: 'newNewsList',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/news/newNews/newNewslist.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '危化头条'
      },
      hiddenZooS: true
    }
  },
  //去掉头部 底部的waybill
  {
    path: '/newWaybill',
    name: 'newWaybill',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/waybill/newWaybill/newWaybill.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '电子运单'
      },
      hiddenZooS: true
    }
  },
  // 去掉头部 底部的search
  {
    path: '/newSearch',
    name: 'newSearch',
    component: function component() {
      return Promise.resolve().then(function () {
        return _interopRequireWildcard(require("./views/news/newNews/newNewslist.vue"));
      });
    },
    meta: {
      metaInfo: {
        title: '危化镖局·危化头条-一手政策解读,行业资讯先览',
        keywords: '化工政策,危运政策,危险品政策,危险品车辆限行,化工资讯,危运资讯,MSDS,道路限行,化工生产',
        description: '提供化工及物流行业的资讯政策消息'
      }
    }
  }]
});