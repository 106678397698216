"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-container"
  }, [_c("div", {
    staticClass: "width-full"
  }, [_c("el-table", {
    attrs: {
      data: _vm.listData,
      stripe: "",
      "cell-style": _vm.tableRowStyle,
      "default-sort": {
        prop: "arrivalTime",
        order: "descending"
      },
      "header-cell-style": {
        "background-color": "#eaf0fe",
        color: "#5A6991",
        "font-size": "16px"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "出发城市",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.startProvince) + _vm._s(scope.row.joinedStart ? "/" + scope.row.joinedStart : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "到达城市"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.endProvince) + _vm._s(scope.row.joinedEnd ? "/" + scope.row.joinedEnd : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "货物信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.goodsName ? scope.row.goodsName : "") + _vm._s(scope.row.goodsBigTypeName ? "-" + scope.row.goodsBigTypeName : "") + _vm._s(scope.row.goodsWeigth ? "-" + scope.row.goodsWeigth + "吨" : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "装车时间",
      prop: "arrivalTime",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.arrivalTime ? scope.row.arrivalTime : "电话联系"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "参考价格"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.minPrice > 0 ? scope.row.minPrice + "-" + scope.row.topPrice + "元" : scope.row.sinPrice ? scope.row.sinPrice + "元" : "暂无价格"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "releaseTime",
      label: "发布时间",
      sortable: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          directives: [{
            name: "track-event",
            rawName: "v-track-event",
            value: {
              category: "货源列表查看详情",
              action: "点击",
              opt_label: scope.row.joinedStart + "-" + scope.row.joinedEnd,
              opt_value: scope.row.gsid
            },
            expression: "{category:'货源列表查看详情', action:'点击', opt_label:scope.row.joinedStart+'-'+scope.row.joinedEnd, opt_value:scope.row.gsid}"
          }],
          staticClass: "detail-btn",
          on: {
            click: function click($event) {
              return _vm.clickToDetail(scope.row.gsid);
            }
          }
        }, [_vm._v(" 查看详情")])];
      }
    }])
  })], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;