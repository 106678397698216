"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    attrs: {
      id: "batchpub"
    }
  }, [_c("div", {
    staticClass: "batchpub_back flex-column-justc"
  }, [_c("div", {
    staticClass: "main text-left"
  }, [_c("div", {
    staticClass: "main_l bg-white"
  }, [_c("div", {
    staticClass: "pub_header text-left"
  }, [_vm._v("发布货源")]), _c("div", {
    staticClass: "pub_body"
  }, [_c("el-form", {
    ref: "batchpubForm"
  }, [_c("div", {
    staticClass: "pub_title text-left flex-row-alignc"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("编辑内容")])], 1), _c("div", {
    staticClass: "batchpub_con"
  }, [_c("el-input", {
    staticClass: "batchpub_txtarea",
    attrs: {
      type: "textarea",
      rows: 5,
      placeholder: _vm.textareaPlaceholder
    },
    model: {
      value: _vm.textarea,
      callback: function callback($$v) {
        _vm.textarea = $$v;
      },
      expression: "textarea"
    }
  }), _c("el-button", {
    staticClass: "identify_btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.toGetResolving
    }
  }, [_vm._v("智能识别")])], 1), _c("el-button", {
    staticClass: "batchpub_link",
    attrs: {
      type: "text"
    },
    on: {
      click: function click($event) {
        _vm.batchpubDialogVisible = true;
      }
    }
  }, [_vm._v("查看发布规则>>")]), _c("div", {
    staticClass: "pub_title text-left flex-row-alignc"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("识别结果")])], 1), _c("div", {
    staticClass: "batchpub_con"
  }, [_c("el-table", {
    staticStyle: {
      width: "430px"
    },
    attrs: {
      data: _vm.batchpubData,
      "show-header": _vm.showTableHeader
    }
  }, [_c("el-table-column", {
    attrs: {
      prop: "batchpubTxt",
      width: "300"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("ul", {
          staticClass: "address"
        }, [_c("li", {
          staticClass: "start"
        }, [_vm._v(_vm._s(scope.row.startLocation))]), _c("li", {
          staticClass: "path"
        }, [_c("img", {
          attrs: {
            src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/hall/jiantou.png",
            alt: ""
          }
        })]), _c("li", {
          staticClass: "end"
        }, [_vm._v(_vm._s(scope.row.endLocation))])]), _c("ul", {
          staticClass: "goods-info"
        }, [_vm._v(_vm._s(scope.row.goodsName ? scope.row.goodsName : "") + _vm._s(scope.row.productCategory ? "-" + scope.row.productCategory : "") + _vm._s(scope.row.weight ? "-" + scope.row.weight + "吨" : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      fixed: "right",
      width: "100"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("el-button", {
          attrs: {
            type: "text",
            size: "small"
          },
          nativeOn: {
            click: function click($event) {
              $event.preventDefault();
              return _vm.removeData(scope.$index, _vm.batchpubData);
            }
          }
        }, [_vm._v("删除")])];
      }
    }])
  })], 1)], 1), _c("div", {
    staticClass: "batchpub_con"
  }, [_c("el-form-item", {
    staticClass: "batchpub_btn pub_btn"
  }, [_c("el-button", {
    directives: [{
      name: "track-event",
      rawName: "v-track-event",
      value: {
        category: "批量发布货源",
        action: "点击",
        opt_label: ""
      },
      expression: "{category:'批量发布货源', action:'点击', opt_label: ''}"
    }],
    attrs: {
      type: "info"
    },
    on: {
      click: _vm.toRapidRelease
    }
  }, [_vm._v("立即发布")])], 1)], 1)], 1)], 1)])])]), _c("footNav"), _c("el-dialog", {
    staticClass: "rulesDialog",
    attrs: {
      title: "发布规则",
      visible: _vm.batchpubDialogVisible,
      width: "30%",
      center: ""
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.batchpubDialogVisible = $event;
      }
    }
  }, [_c("p", [_vm._v("第一步，在输入框输入路线信息，")]), _c("p", [_vm._v("例如：")]), _c("p", [_vm._v("北京—上海，不锈钢")]), _c("p", [_vm._v("北京—广州，乙二醇")]), _c("p", [_vm._v("15900000000")]), _c("p", [_vm._v("第二步，点击“智能识别”，可在识别结果栏查看识别的信息，确认无误后点击“确认无误，立即发布”就发布成功了。")])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;