"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516"
  }, [_c("el-carousel-item", {
    staticClass: "height-516"
  }, [_c("img", {
    staticClass: "mouse-pointer height-516 width-full",
    attrs: {
      src: require("../../assets/static/img/wllm/banner-01.jpg")
    }
  })])], 1), _c("div", {
    ref: "cont-01",
    staticClass: "cont-01"
  }, [_vm._m(0), _c("img", {
    attrs: {
      src: require("../../assets/static/img/wllm/02.png"),
      alt: ""
    }
  })]), _c("div", {
    ref: "cont-02",
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("加盟条件")]), _vm._m(1)]), _c("div", {
    ref: "cont-03-01",
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("产品赋能")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20"
  }, [_vm._v("给物流企业提供人、车、货、资金数字化管理，降低企业成本，并依托于平台智能配载系统，高效匹配车、货源，降低车辆空载率")]), _c("div", {
    staticClass: "main-item"
  }, _vm._l(_vm.productList, function (item, index) {
    return _c("div", {
      staticClass: "flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "product-img",
      attrs: {
        src: item.imgUrl
      }
    }), _c("div", {
      staticClass: "font-22 color-21212 margin-top-50"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 color-555555 margin-top-15"
    }, [_vm._v(_vm._s(item.content))])]);
  }), 0)]), _c("div", {
    ref: "cont-03-02",
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#F6F7F8"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("商务赋能")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20"
  }, [_vm._v("商务组合服务，有效提高企业竞争力")]), _c("div", {
    staticClass: "main-item"
  }, _vm._l(_vm.bussineseList, function (item, index) {
    return _c("div", {
      staticClass: "flex-col-center align-item-center width-210"
    }, [_c("img", {
      staticClass: "bussinese-img",
      attrs: {
        src: item.imgUrl
      }
    }), _c("div", {
      staticClass: "font-22 color-21212 margin-top-50"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 color-555555 margin-top-15"
    }, [_vm._v(_vm._s(item.content))])]);
  }), 0)]), _c("div", {
    ref: "cont-04",
    staticClass: "cont-04 flex-row-sb"
  }, [_c("div", {
    staticClass: "main-l"
  }), _c("div", {
    staticClass: "main-item flex-row-sb"
  }, _vm._l(_vm.myAdvantage, function (item, index) {
    return _c("div", {
      staticClass: "box text-left"
    }, [_c("div", {
      staticClass: "font-36 font-weight-500 color-white",
      style: {
        opacity: index == 0 ? "1" : "0"
      }
    }, [_vm._v("我们的优势")]), _c("div", {
      staticClass: "font-18 color-white",
      style: {
        opacity: index == 0 ? "1" : "0"
      }
    }, [_vm._v("Our advantage")]), _c("div", {
      staticClass: "box-item flex-1"
    }, [_c("div", {
      staticClass: "font-36 color-white font-weight-500"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c("div", {
      staticClass: "font-26 color-white font-weight-500 margin-top-80"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 color-white margin-top-20"
    }, [_vm._v(_vm._s(item.content))]), _c("div", {
      staticClass: "box-item-line"
    })])]);
  }), 0), _c("div", {
    staticClass: "main-r"
  })]), _c("div", {
    ref: "cont-05",
    staticClass: "cont-05"
  }, [_vm._m(2), _c("img", {
    attrs: {
      src: require("../../assets/static/img/wllm/12.jpg")
    }
  })]), _c("div", {
    ref: "cont-06",
    staticClass: "cont-06",
    staticStyle: {
      "background-color": "#E9F1FF"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("我们不玩虚的，只做最会省钱的大联盟")]), _c("div", {
    staticClass: "color-555555 font-18 margin-top-20"
  }, [_vm._v("更好的管车、用车、配车，用好车就是省钱，日常管理都是钱，云端统一处理，省下来的就是利润")]), _c("div", {
    staticClass: "main-item"
  }, _vm._l(_vm.serviceList, function (item, index) {
    return _c("div", {
      staticClass: "box flex-col-center align-item-center color-444444 font-18"
    }, [_c("img", {
      staticClass: "width-44",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "margin-top-25"
    }, [_vm._v(_vm._s(item.desc1))]), _c("span", [_vm._v(_vm._s(item.desc2))])]);
  }), 0)]), _c("div", {
    ref: "cont-07",
    staticClass: "cont-07",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("危化镖局是行业内真正靠得住的伙伴")]), _c("div", {
    staticClass: "color-555555 font-18 margin-top-20"
  }, [_vm._v("六年时间，专注于化工物流行业的科技发展，看到了行业同行的心酸及金融市场的虎视眈眈，真正的联合，一起打败竞争")]), _c("img", {
    staticClass: "margin-top-60 width-1280",
    attrs: {
      src: require("../../assets/static/img/wllm/21.png")
    }
  })]), _c("div", {
    ref: "cont-08",
    staticClass: "cont-08",
    staticStyle: {
      "background-color": "#F0F0F1"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("加入千城联盟")]), _c("div", {
    staticClass: "box"
  }, _vm._l(_vm.joinList1, function (item, index) {
    return _c("div", {
      staticClass: "flex-row-start align-item-center padding-left-30 padding-right-30"
    }, [_c("img", {
      staticClass: "box-img",
      attrs: {
        src: item.imgUrl
      }
    }), _c("div", {
      staticClass: "text-left margin-left-25 width-230"
    }, [_c("div", {
      staticClass: "font-22 font-weight-500 color-white"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 margin-top-10",
      staticStyle: {
        color: "#EEEEEE"
      }
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0), _c("div", {
    staticClass: "main-item"
  }, _vm._l(_vm.joinList2, function (item, index) {
    return _c("div", {
      staticClass: "flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "product-img",
      attrs: {
        src: item.imgUrl
      }
    }), _c("div", {
      staticClass: "font-22 color-21212 margin-top-40"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 color-555555 margin-top-15 width-190"
    }, [_vm._v(_vm._s(item.content))])]);
  }), 0)]), _c("appointment-thr"), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_c("img", {
    staticClass: "width-54",
    attrs: {
      src: require("../../assets/static/img/wllm/01.png")
    }
  }), _c("span", {
    staticClass: "margin-left-25"
  }, [_vm._v("合作愿景")])]), _c("div", {
    staticClass: "main-item"
  }, [_c("p", [_vm._v("危化镖局，同危运行业各物流企业一样，致力于为行业物流信息做整合，并更好的向化工企业及行业供应链做服务。平台期望通过更多的物流资源整合服务，为行业带来更高效、更标准、更便捷的供应链服务理念，为行业贡献力量。")]), _c("p", [_vm._v("危化镖局筹建物流联盟的目的，是为了通过联合行业物流企业的资源，形成企业级的战略联盟，打破企业之间的信赖问题，实现行业物流企业之间的资源共享，通过相关合作协议、发展协议缔结而成，巩固大家的利益关系，并通过一套标准化、服务化、智能化的现代物流流程，结成优势互补、资源共享、风险共担、信息共享、利益共享的联合合作组织，并在金融合作、互联网赋能、品牌化运作、商务综合服务配套等资源介入下，让合作组织成为行业的龙头。 ")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-row-center margin-top-80"
  }, [_c("div", {
    staticClass: "flex-col-center margin-right-100"
  }, [_c("div", {
    staticClass: "font-24 color-333333 font-weight-500"
  }, [_vm._v("基础条件")]), _c("div", {
    staticClass: "main-item bgc-FFFFFF radius-32 flex-row-start margin-top-45"
  }, [_c("span", {
    staticClass: "color-3269F6 font-20"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "color-555555 font-20 margin-left-40"
  }, [_vm._v("具备危险品运输资质")])]), _c("div", {
    staticClass: "main-item bgc-FFFFFF radius-32 flex-row-start margin-top-25"
  }, [_c("span", {
    staticClass: "color-3269F6 font-20"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "color-555555 font-20 margin-left-40"
  }, [_vm._v("1500万年度营业额")])]), _c("div", {
    staticClass: "main-item bgc-FFFFFF radius-32 flex-row-start margin-top-25"
  }, [_c("span", {
    staticClass: "color-3269F6 font-20"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "color-555555 font-20 margin-left-40"
  }, [_vm._v("10部自有危险品车辆")])])]), _c("div", {
    staticClass: "flex-col-center color-white"
  }, [_c("div", {
    staticClass: "font-24 color-3269F6 font-weight-500"
  }, [_vm._v("优先加入")]), _c("div", {
    staticClass: "main-item bgc-linear-346BF6 radius-32 flex-row-start margin-top-45"
  }, [_c("span", {
    staticClass: "font-20"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "font-20 margin-left-40"
  }, [_vm._v("具备区域仓储或周转能力")])]), _c("div", {
    staticClass: "main-item bgc-linear-346BF6 radius-32 flex-row-start margin-top-25"
  }, [_c("span", {
    staticClass: "font-20"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "font-20 margin-left-40"
  }, [_vm._v("具备落地配短驳能力")])]), _c("div", {
    staticClass: "main-item bgc-linear-346BF6 radius-32 flex-row-start margin-top-25"
  }, [_c("span", {
    staticClass: "font-20"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "font-20 margin-left-40"
  }, [_vm._v("具备零担专线能力")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "color-212121 font-36 font-weight-500"
  }, [_vm._v("我们不玩虚的，做最会赚钱的大联盟")]), _c("span", {
    staticClass: "color-555555 font-18 margin-top-20"
  }, [_vm._v("危化镖局倾其所有，为兄弟联盟助力，用最实在的资源提供最大的赚钱机会")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-50"
  }, [_c("span", {
    staticClass: "color-white font-16 radius-circle circle flex-row-center"
  }, [_vm._v("01")]), _c("span", {
    staticClass: "color-365ACD font-18 margin-left-15"
  }, [_vm._v("25万单/月平台整车业务")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("span", {
    staticClass: "color-white font-16 radius-circle circle flex-row-center"
  }, [_vm._v("02")]), _c("span", {
    staticClass: "color-365ACD font-18 margin-left-15"
  }, [_vm._v("9万单/月零担业务")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("span", {
    staticClass: "color-white font-16 radius-circle circle flex-row-center"
  }, [_vm._v("03")]), _c("span", {
    staticClass: "color-365ACD font-18 margin-left-15"
  }, [_vm._v("6万家化工货主用户资源")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("span", {
    staticClass: "color-white font-16 radius-circle circle flex-row-center"
  }, [_vm._v("04")]), _c("span", {
    staticClass: "color-365ACD font-18 margin-left-15"
  }, [_vm._v("13个区域业务中心配套")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("span", {
    staticClass: "color-white font-16 radius-circle circle flex-row-center"
  }, [_vm._v("05")]), _c("span", {
    staticClass: "color-365ACD font-18 margin-left-15"
  }, [_vm._v("24小时智能客服中心")])])]);
}];
render._withStripped = true;