"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pt_content"
  }, [_c("div", {
    staticClass: "pt_card"
  }, [_c("el-card", {
    staticClass: "pt_card_box"
  }, [_c("div", {
    staticClass: "pt_card_left text-left"
  }, [_c("div", {
    staticClass: "pt_card_title"
  }, [_vm._v("产品名称：综合服务平台")]), _c("p", [_vm._v("以化工行业从业企业/个人为服务目标，为广大用户提供各种免费功能。通过化工物流撮合平台，为广大物流/司机提供快速配货服务；建设化工资讯服务，汇聚全国各种化工最新资讯、政策；用户更可搭建个人/企业官网，免费实现产品推广的同时，更可与认识更多同行、企业，快速实现产品、物流、仓储的询报价服务。")]), _c("div", {
    staticClass: "pt_app_download"
  }, [_c("div", {
    staticClass: "ios_box box"
  }, [_c("div", {
    staticClass: "box-front mouse-pointer"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/products_ios_ico.png"
    }
  }), _c("span", {
    staticClass: "span-block"
  }, [_vm._v("IOS下载")])]), _c("div", {
    staticClass: "box-back"
  })]), _c("div", {
    staticClass: "android_box box"
  }, [_c("div", {
    staticClass: "box-front mouse-pointer"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/products_android_ico.png"
    }
  }), _c("span", {
    staticClass: "span-block"
  }, [_vm._v("安卓下载")])]), _c("div", {
    staticClass: "box-back"
  }, [_c("qriously", {
    attrs: {
      value: _vm.initQCode
    }
  })], 1)])])]), _c("div", {
    staticClass: "pt_card_right"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/pingtai_phone_img.png"
    }
  })])])], 1), _c("div", {
    staticClass: "pt_matchinghall"
  }, [_c("div", {
    staticClass: "pt_matchinghall_title text-white font-bold border-bottom"
  }, [_vm._v("撮合大厅")]), _c("div", {
    staticClass: "pt_matchinghall_txt"
  }, [_vm._v("智能配载系统全流程自动匹配及推荐，帮您找到合适的货源和车源。")]), _c("div", {
    staticClass: "hover_animation"
  }, _vm._l(_vm.halist, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "ha_box car_box",
      class: item.haClassName
    }, [_c("div", {
      staticClass: "box_con",
      staticStyle: {
        position: "relative"
      }
    }, [_c("img", {
      attrs: {
        src: item.haImgSrc
      }
    }), _c("span", [_vm._v(_vm._s(item.haTitle))])]), _c("div", {
      staticClass: "ha_content"
    }, [_c("div", {
      staticClass: "ha_con"
    }, [_c("p", {
      staticClass: "ha_title"
    }, [_vm._v(_vm._s(item.haTitle))]), _vm._l(item.haPostcon, function (pitem, pindex) {
      return _c("span", {
        key: pindex,
        staticClass: "ha_post"
      }, [_vm._v(_vm._s(pitem))]);
    })], 2), _c("ul", {
      staticClass: "ha_icon"
    }, [_c("li", [_c("a", {
      attrs: {
        href: "javascript:void(0);"
      }
    }, [_c("img", {
      attrs: {
        src: item.haImgSrc
      }
    })])])])])]);
  }), 0)]), _vm._m(0), _c("div", {
    staticClass: "zx_section"
  }, [_c("div", {
    staticClass: "zx_header text-white font-bold"
  }, [_vm._v("化工资讯专栏")]), _c("div", {
    staticClass: "zx_txt text-white"
  }, [_vm._v("免费提供全国各地的化工行业资讯、政策、商机，更实现个性化推送，新鲜资讯一手掌握。")]), _c("div", {
    staticClass: "zx_carouselbox"
  }, [_c("div", {
    staticClass: "swiper-button-prev",
    attrs: {
      slot: "button-prev"
    },
    slot: "button-prev"
  }), _c("div", {
    staticClass: "zx_carousel"
  }, [_c("swiper", {
    ref: "mySwiper",
    attrs: {
      options: _vm.swiperOption
    }
  }, _vm._l(_vm.zxlist, function (zxitem, zxindex) {
    return _c("swiper-slide", {
      key: zxindex,
      staticStyle: {
        display: "inline-block"
      }
    }, [_c("div", {
      staticClass: "zx_swiper"
    }, [_c("a", {
      staticClass: "mouse-pointer",
      attrs: {
        href: "javascript:void(0)"
      },
      on: {
        click: function click($event) {
          return _vm.toNews(zxitem.lmTitle);
        }
      }
    }, [_c("div", {
      staticClass: "zxsImg"
    }, [_c("img", {
      attrs: {
        src: zxitem.imgSrc,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "zxsCon text-white ellipsis"
    }, [_vm._v(_vm._s(zxitem.lmTitle))])])])]);
  }), 1)], 1), _c("div", {
    staticClass: "swiper-button-next",
    attrs: {
      slot: "button-next"
    },
    slot: "button-next"
  })])]), _vm._m(1), _c("div", {
    staticClass: "rj_section"
  })]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pt_website"
  }, [_c("div", {
    staticClass: "pt_website_title text-black font-bold border-bottom"
  }, [_vm._v("企业 | 个人官网")]), _c("div", {
    staticClass: "pt_website_txt"
  }, [_vm._v("提供仓储、物流、化工、个人多样化的产品、路线、企业介绍窗口，成为行业营收新入口。")]), _c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/official_website_img.png",
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "ldp_section"
  }, [_c("div", {
    staticClass: "ldp_section_bg"
  }, [_c("div", {
    staticClass: "ldp_header font-bold"
  }, [_vm._v("落地配联盟")]), _c("div", {
    staticClass: "ldp_txt"
  }, [_vm._v("全国各地的开放式分拨网点联盟，全国各地快速分拨、快速配货，并提供地方实体的综合服务。")])])]);
}];
render._withStripped = true;