"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "waybill",
    style: {
      height: _vm.mainHeight
    },
    attrs: {
      id: "waybill"
    }
  }, [_c("div", {
    staticClass: "waybill-content"
  }, [_vm._m(0), _c("div", {
    staticClass: "flex-column-center"
  }, [_c("div", {
    staticClass: "waybill-main flex-column-center n-content"
  }, [_vm._m(1), _vm._m(2), _c("a", {
    staticClass: "create mainThemeColor mouse-pointer",
    on: {
      click: _vm.clickToCreateWaybill
    }
  }, [_vm._v("创建运单")]), _c("el-dialog", {
    staticClass: "download-dialog call-dialog",
    attrs: {
      visible: _vm.showAppDownload,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAppDownload = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/static/img/waybill/appdownload.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showAppDownload = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])])], 1)])])]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top-banner"
  }, [_c("img", {
    attrs: {
      src: require("../../../assets/static/img/waybill/waybill_banner.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "introduction"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("电子运单简介")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("危险货物道路运输管理一直以来都存在安全隐患和漏洞，风险防控及应急处置能力不足，托运和承运单位责任不明、非法托运等问题。")]), _c("p", [_vm._v("2018年，交通运输部已将危化品运输电子运单作为强制要求列入国家交通运输行业标准《危险货物道路运输规则》。危险货物运输配备“电子运单”后，相关运输过程中的动态和流向信息将被实时监控，为运输安全多提供一份保障。今后通过自动生成的电子运单，从生产、销售、储存、运输、使用、处置环节，再到从装货地点、目的地、预计到达时间、运输路线到驾驶员、押运员的相关信息都将被详细记录。通过GPS定位，后台可以直接监控到车辆的实时位置、车辆详情和车辆轨迹。同时，一旦车辆发生事故，可在第一时间按照应急处置预案及时响应。")])]), _c("img", {
    attrs: {
      src: require("../../../assets/static/img/waybill/waybill_intrduction.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "advantage"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("电子运单的好处")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("1、落实企业安全生产的主体责任，培育良好运输市场环境的一种好的管理办法。")]), _c("p", [_vm._v("2、强化运输管理部门安全监管职能，提高应急处置能力及行业决策、管理水平。强化危险货物道路运输源头管控，事前、事中、事后监管的一项重要举措。")]), _c("p", [_vm._v("3、一旦车辆发生事故，可在第一时间按照应急处置预案及时响应。监管部门及企业通过“电子运单”就可以及时获悉运输危化品种类、数量、生产企业等信息，及时针对性地抢险，避免产生更大影响。运单还可以与交通运输部危险货物监管系统实现数据实时互联，为实现危险化学品跨地区协调联动和应急处置提供保障。")])]), _c("img", {
    attrs: {
      src: require("../../../assets/static/img/waybill/waybil_advantage.jpg"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;