// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/news/news.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/padding.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/color.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/fontSize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/margin.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/radius.css");
var ___CSS_LOADER_AT_RULE_IMPORT_7___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/pcMall/flex.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_7___);
// Module
exports.push([module.id, ".el-menu--popup {\n  min-width: 0;\n}\n.divider::before {\n  content: \"\";\n  display: inline-block;\n  width: 0.015625rem;\n  height: 0.083333rem;\n  background: #3269F6;\n  margin-right: 0.052083rem;\n}\n.rl-white {\n  color: #fff;\n  display: inline-block;\n  height: 0.260417rem;\n  line-height: 0.260417rem;\n  font-size: 0.09375rem;\n}\n.flex-row-center a:hover {\n  color: #fff;\n}\n.flex-row-center a:focus {\n  color: #fff;\n}\n.flex-row-center i {\n  vertical-align: middle;\n}\n.menu {\n  width: 4.677083rem;\n  height: 0.3125rem;\n  border-bottom: 0.005208rem solid #F0F0F1;\n  background-color: #fff;\n  position: sticky;\n  top: 0.625rem;\n  z-index: 1000;\n  overflow-x: auto;\n  white-space: nowrap;\n  flex-wrap: nowrap;\n}\n.menu .menu-item {\n  height: 100%;\n  position: relative;\n  cursor: pointer;\n}\n.menu .active-menu-item {\n  color: #3269F6;\n}\n.menu .active-menu-item::after {\n  content: \"\";\n  display: block;\n  width: 100%;\n  height: 0.015625rem;\n  background: #3269F6;\n  position: absolute;\n  bottom: 0;\n}\n.isDetail .el-card__header {\n  padding: 0;\n}\n.isDetail .el-card__header .header {\n  height: 0.260417rem;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  font-size: 0.09375rem;\n  color: #212121;\n}\n.isDetail .el-card__header .header .more {\n  font-size: 0.083333rem;\n  color: #b2b2b2;\n  padding-right: 0.052083rem;\n  cursor: pointer;\n}\n.isDetail .reco-item {\n  cursor: pointer;\n  text-decoration: none;\n}", ""]);
// Exports
module.exports = exports;
