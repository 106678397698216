"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.split");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.rowData ? _c("div", {
    staticClass: "noImgListRow"
  }, [_c("el-row", {
    staticClass: "title"
  }, [_c("el-col", {
    attrs: {
      span: 16
    }
  }, [_vm.rowData.post ? _c("span", {
    staticClass: "font-20 font-bold color-222222 vertical-middle margin-right-10"
  }, [_vm._v(_vm._s(_vm.rowData.post == "其他" ? _vm.rowData.postName : _vm.rowData.post))]) : _c("div", {
    staticClass: "width-full inline-block font-20 color-222222 vertical-middle font-bold ellipsis margin-right-10"
  }, [_vm._v(_vm._s(_vm.rowData.title))]), _vm.rowData.merchantStatus == "1" ? _c("span", {
    staticClass: "inline-block padding-top-1 padding-bottom-1 padding-left-5 padding-right-5 vertical-middle color-white font-12 bgc-linear-FF332D-FF8450 margin-right-10 radius-2"
  }, [_vm._v("精选")]) : _vm._e(), _vm.rowData.helpWantedFlay == "1" ? _c("span", {
    staticClass: "inline-block padding-top-1 padding-bottom-1 padding-left-5 padding-right-5 vertical-middle color-white font-12 bgc-F5950B radius-2"
  }, [_vm._v("急聘")]) : _vm._e()]), _c("el-col", {
    staticClass: "text-align-right font-20 color-F75923 vertical-middle",
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm.rowData.monthlySalary || "面议"))])], 1), _c("el-row", {
    staticClass: "tags margin-top-10"
  }, [_vm.rowData.postNature ? _c("span", {
    staticClass: "inline-block padding-left-15 padding-right-15 margin-right-10 vertical-middle ellipsis font-16 bgc-transparent color-0E6CCB border-solid radius-4"
  }, [_vm._v(_vm._s(_vm.rowData.postNature))]) : _vm._e(), _vm.rowData.workTime ? _c("span", {
    staticClass: "inline-block padding-left-15 padding-right-15 margin-right-10 vertical-middle ellipsis font-16 bgc-transparent color-0E6CCB border-solid radius-4"
  }, [_vm._v(_vm._s(_vm.rowData.workTime))]) : _vm._e(), _vm.rowData.certificate ? _c("span", {
    staticClass: "inline-block padding-left-15 padding-right-15 margin-right-10 vertical-middle ellipsis font-16 bgc-transparent color-0E6CCB border-solid radius-4"
  }, [_vm._v(" " + _vm._s(_vm.rowData.certificate.split(",")[0]))]) : _vm._e()]), _c("el-row", {
    staticClass: "desc margin-top-5"
  }, [_c("el-col", {
    staticClass: "font-16 color-666666 ellipsis",
    attrs: {
      span: 16
    }
  }, [_vm.rowData.oftenRunRoute ? _c("span", [_vm._v("路线是" + _vm._s(_vm.rowData.oftenRunRoute))]) : _c("span", [_vm._v(_vm._s(_vm.rowData.content))])]), !_vm.avatarRow && (_vm.rowData.city || _vm.rowData.province) ? _c("el-col", {
    staticClass: "text-align-right font-16 color-666666 ellipsis",
    attrs: {
      span: 8
    }
  }, [_vm._v(" " + _vm._s(_vm.city))]) : _vm._e()], 1), _c("el-row", {
    staticClass: "user margin-top-15"
  }, [_c("el-col", {
    staticClass: "font-16 color-222222 ellipsis",
    attrs: {
      span: 16
    }
  }, [_c("el-avatar", {
    key: _vm.rowData.ossHeadUrl,
    staticClass: "vertical-middle margin-right-10",
    attrs: {
      src: _vm.rowData.ossHeadUrl ? _vm.rowData.ossHeadUrl : _vm.avatarNormalIco,
      fit: "cover",
      size: 30,
      alt: "头像"
    },
    on: {
      error: true
    }
  }, [_c("img", {
    attrs: {
      src: "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
    }
  })]), _c("span", {
    staticClass: "inline-block font-16 color-222222 vertical-middle ellipsis"
  }, [_vm._v(" " + _vm._s(_vm.userName && "".concat(_vm.userName, "\xB7")) + _vm._s(_vm.time) + " ")])], 1), _vm.rowData.city || _vm.rowData.province ? _c("el-col", {
    staticClass: "text-align-right font-16 color-999999 ellipsis",
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm.city))]) : _vm._e()], 1)], 1) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;