"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "chemical",
    attrs: {
      id: "chemicalMall"
    }
  }, [_c("el-carousel", {
    staticClass: "height-516"
  }, [_c("el-carousel-item", {
    staticClass: "height-516"
  }, [_c("img", {
    staticClass: "mouse-pointer height-516 width-full",
    attrs: {
      src: require("../../assets/static/img/chemicalMall/banner-01.jpg")
    }
  })])], 1), _vm._m(0), _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "font-36 color212121 font-weight-500"
  }, [_vm._v("热门产品")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-60"
  }, _vm._l(_vm.products, function (item, index) {
    return _c("div", {
      staticClass: "flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "width-140",
      attrs: {
        src: item.icon
      }
    }), _c("span", {
      staticClass: "font-18 color-333333 margin-top-30"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_vm._m(1), _c("el-form", {
    ref: "svForm",
    attrs: {
      model: _vm.mallForm,
      rules: _vm.mallRules,
      "show-message": false
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "category"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.getClassifyData
    },
    model: {
      value: _vm.mallForm.category,
      callback: function callback($$v) {
        _vm.$set(_vm.mallForm, "category", $$v);
      },
      expression: "mallForm.category"
    }
  }, [_c("el-option", {
    attrs: {
      label: "卖货信息",
      value: "卖货信息"
    }
  }), _c("el-option", {
    attrs: {
      label: "买货信息",
      value: "买货信息"
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "cityValue"
    }
  }, [_c("el-cascader", {
    ref: "city",
    attrs: {
      model: _vm.mallForm.cityValue,
      options: _vm.citys,
      placeholder: "请选择所在城市",
      size: "medium",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.startCityChange
    }
  })], 1), _c("el-form-item", {
    attrs: {
      prop: "contactName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入联系人姓名",
      clearable: "",
      maxlength: 10
    },
    model: {
      value: _vm.mallForm.contactName,
      callback: function callback($$v) {
        _vm.$set(_vm.mallForm, "contactName", $$v);
      },
      expression: "mallForm.contactName"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "contactPhone"
    }
  }, [_c("el-input", {
    attrs: {
      type: "phone",
      autocomplete: "off",
      clearable: "",
      maxlength: 11,
      placeholder: "请输入联系电话"
    },
    model: {
      value: _vm.mallForm.contactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.mallForm, "contactPhone", $$v);
      },
      expression: "mallForm.contactPhone"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "title"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入标题名称",
      clearable: "",
      maxlength: 10
    },
    model: {
      value: _vm.mallForm.title,
      callback: function callback($$v) {
        _vm.$set(_vm.mallForm, "title", $$v);
      },
      expression: "mallForm.title"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "content"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入具体内容~",
      autosize: {
        minRows: 4,
        maxRows: 4
      },
      maxlength: "100"
    },
    model: {
      value: _vm.mallForm.content,
      callback: function callback($$v) {
        _vm.$set(_vm.mallForm, "content", $$v);
      },
      expression: "mallForm.content"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-button", {
    staticClass: "submit_btn text-align-center text-white font-20 mouse-pointer",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("svForm");
      }
    }
  }, [_vm._v("提交")])], 1)], 1)]), _c("div", {
    staticClass: "partners-list"
  }, [_c("div", {
    staticClass: "main-item flex-col-center align-item-center"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("合作伙伴展示")]), _c("div", {
    staticClass: "pl-items"
  }, [_c("vue-seamless-scroll", {
    staticClass: "roll",
    attrs: {
      data: _vm.partners,
      "class-option": _vm.classOption
    }
  }, [_c("ul", {
    staticClass: "box"
  }, _vm._l(_vm.partners, function (item, index) {
    return _c("li", {
      staticClass: "item flex-row-center"
    }, [_c("img", {
      attrs: {
        src: item.icon,
        alt: ""
      }
    }), _c("span", [_vm._v(_vm._s(item.name))])]);
  }), 0)]), _c("a", {
    staticClass: "more",
    attrs: {
      href: "https://www.b-chem.com/",
      target: "_blank"
    }
  }, [_vm._v("查看更多")])], 1)])]), _c("foot-nav", {
    ref: "foot"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("img", {
    staticClass: "width-440",
    attrs: {
      src: require("../../assets/static/img/chemicalMall/02.png")
    }
  }), _c("div", {
    staticClass: "flex-col-start width-670"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("span", {
    staticClass: "font-36 font-weight-500 color-21212"
  }, [_vm._v("化工宝化工商城介绍")])]), _c("ul", {
    staticClass: "font-18 color-555555 line-height-16 text-left margin-top-35",
    staticStyle: {
      "margin-left": "1.5em"
    }
  }, [_c("li", [_vm._v("上海化工宝电子商务有限公司为危化镖局在化工品交易领域"), _c("span", {
    staticClass: "color-F75923"
  }, [_vm._v("指定合作伙伴")]), _vm._v("。")]), _c("li", [_vm._v("上海化工宝电子商务有限公司是由上海宝钢化工有限公司、东方钢铁电子商务有限公司、武汉平煤武钢联合焦化有限责任公司三大化工央企于2011年共同投资创立，成立伊始便专注于煤化工产业链上下游企业、服务于广大煤化工企业，提供全流程一站式电子商务服务的垂直型电子交易平台。 ")]), _c("li", [_vm._v("化工宝是国内最先开展化工产品及其原料的电子交易中心机构之一，实现相关现货产品的在线购销、在线支付。交易发起方企业能够在化工宝以竞价、挂牌方式进行产品销售，以询报价、招标、竞买等方式进行原料采购。")]), _c("li", [_vm._v("目前化工宝累计成交金额逾 "), _c("span", {
    staticClass: "color-F75923"
  }, [_vm._v("492.92亿元")]), _c("span", [_vm._v("，累计成交量逾")]), _c("span", {
    staticClass: "color-F75923"
  }, [_vm._v("1696万吨")]), _vm._v(" 。 ")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-start align-item-start"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("填写卖货&买货信息")]), _c("div", {
    staticClass: "font-20 color-5A6991 margin-top-60"
  }, [_vm._v("30分钟内，将有物流专员来解决您的问题")]), _c("div", {
    staticClass: "font-20 color-5A6991 margin-top-30"
  }, [_vm._v("您也可主动给我们致电")]), _c("div", {
    staticClass: "font-36 color-3269F6 margin-top-30"
  }, [_vm._v("400-886-3156")])]);
}];
render._withStripped = true;