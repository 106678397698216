"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "routerContent",
    style: {
      height: _vm.mainHeight
    },
    attrs: {
      id: "WMS"
    }
  }, [_c("div", {
    staticClass: "businessjoin_content"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _c("div", {
    staticClass: "join_bg5",
    attrs: {
      id: "join_form"
    }
  }, [_c("join-form", {
    attrs: {
      "business-type": "20",
      "title-txt": "限时福利",
      "sub-title-txt": "现在提交试用申请，享免费咨询服务",
      "sub-title-color": "#4988fb",
      "submit-btn-txt": "点击预约试用",
      "has-phone-footer": false
    }
  })], 1), _c("foot-nav", {
    ref: "foot"
  })], 1), _c("downGuid")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg1"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/products/WMS_bg1_1.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg2"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/products/WMS_bg2.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg3"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/products/WMS_bg3.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;