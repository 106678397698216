// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/padding.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/color.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/fontSize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/margin.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/radius.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/pcMall/flex.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
// Module
exports.push([module.id, ".page[data-v-a018c36c] {\n  width: 100%;\n  min-width: 6.666667rem;\n}\n.more-btn[data-v-a018c36c] {\n  width: 0.5625rem;\n  height: 0.208333rem;\n  line-height: 0.208333rem;\n  margin-top: 0.3125rem;\n  background: transparent;\n  border: 0.005208rem solid #B2B2B2;\n  border-radius: 0.104167rem;\n  text-align: center;\n  cursor: pointer;\n  color: #212121;\n  font-size: 0.072917rem;\n}\n.cont-01[data-v-a018c36c] {\n  width: 100%;\n  height: 4.895833rem;\n  padding-top: 0.598958rem;\n  padding-bottom: 0.729167rem;\n}\n.cont-01 .main-item[data-v-a018c36c] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-01 .main-item .text[data-v-a018c36c] {\n  width: 1.5625rem;\n  height: 0.59375rem;\n  background: #FFFFFF;\n  box-shadow: 0 0.010417rem 0.104167rem 0 rgba(50, 105, 246, 0.1);\n  transform: translate(0.208333rem, -50%);\n}\n.cont-02[data-v-a018c36c] {\n  width: 100%;\n  padding-top: 0.598958rem;\n  padding-bottom: 0.729167rem;\n}\n.cont-02 .main-item[data-v-a018c36c] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-03[data-v-a018c36c] {\n  width: 100%;\n  padding-top: 0.598958rem;\n  padding-bottom: 0.729167rem;\n}\n.cont-03 .main-item[data-v-a018c36c] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-03 .main-item .item-r[data-v-a018c36c] {\n  width: 2.75rem;\n}\n.cont-03 .main-item .item-r > div[data-v-a018c36c] {\n  width: 100%;\n  height: 0.260417rem;\n  border-bottom: 0.010417rem solid #F0F0F1;\n}", ""]);
// Exports
module.exports = exports;
