"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home_container",
    attrs: {
      id: "home"
    }
  }, [_c("div", {
    ref: "cont-01",
    staticClass: "cont-01 relative"
  }, [_c("top-nav"), _c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: require("../../assets/static/img/home/new/banner-01.jpg")
    }
  })], 1), _c("div", {
    ref: "cont-02",
    staticClass: "cont-02 flex-col-start align-item-center"
  }, [_c("div", {
    staticClass: "flex-row-sb align-item-center width-1280"
  }, _vm._l(_vm.list1, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "widt-250 flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "list-icon",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-20 color-2A559D margin-top-35 font-weight-600"
    }, [_vm._v(_vm._s(item.name))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-25 padding-left-30 padding-right-30"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0), _c("div", {
    staticClass: "text-center color-212121 font-weight-500 font-36 margin-top-120"
  }, [_vm._v("智慧化工物流生态体系")]), _c("div", {
    staticClass: "text-center color-555555 font-18 width-1280 margin-top-20"
  }, [_vm._v(" 基于市场与业务发展需求，危化镖局以物流信息为基础，货运交易为核心，以金融、保险、仓储、贸易、软件等为拓展方向，已从公路运输服务扩展至综合性物流服务")]), _c("img", {
    staticClass: "margin-top-50 width-1280",
    attrs: {
      src: require("../../assets/static/img/home/new/01.png")
    }
  })]), _c("div", {
    ref: "cont-03",
    staticClass: "cont-03 flex-row-center",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_vm._m(0), _c("div", {
    staticStyle: {
      width: "93px",
      height: "100%"
    }
  }), _c("img", {
    attrs: {
      src: require("../../assets/static/img/home/new/02.jpg")
    }
  })]), _vm._m(1), _c("div", {
    ref: "cont-06",
    staticClass: "cont-06 flex-col-start align-item-center"
  }, [_c("div", {
    staticClass: "text-center color-212121 font-weight-500 font-36"
  }, [_vm._v("安全管理")]), _c("div", {
    staticClass: "width-1280 text-center color-555555 font-18 line-height-16 width-1280 margin-top-25"
  }, [_vm._v(" 为交易双方在平台线上交易过程中出现的货损、加价、延误、资金安全等提供双向保障，构建了全新的交易场景，为企业用户提供了包括税务管理、财务管理、运输管理、车辆管理、调度管理等各项交易管理服务，为企业用户降本提效，是危险品物流行业最值得用户信赖的平台。 ")]), _vm._m(2), _c("img", {
    staticClass: "width-1280 margin-top-70",
    attrs: {
      src: require("../../assets/static/img/home/new/09.png")
    }
  })]), _c("div", {
    ref: "cont-04",
    staticClass: "cont-04 flex-col-start align-item-center"
  }, [_c("div", {
    staticClass: "text-center color-212121 font-weight-500 font-36"
  }, [_vm._v("增值服务")]), _c("div", {
    staticClass: "text-center color-555555 font-18 margin-top-20"
  }, [_vm._v(" 与平台业务联动，依托平台大数据及场景，为司机和货主提供快速便捷的金融、保险、软件、财税等多种增值服务 ")]), _c("div", {
    staticClass: "flex-row-center flex-warp width-1280 margin-top-40"
  }, _vm._l(_vm.addServiceList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "box padding-bottom-30 padding-top-35 padding-left-30 padding-right-30"
    }, [_c("div", {
      staticClass: "flex-row-start align-item-center"
    }, [_c("img", {
      staticClass: "width-36",
      attrs: {
        src: item.icon
      }
    }), _c("span", {
      staticClass: "font-20 color-212121 margin-left-25"
    }, [_vm._v(_vm._s(item.title))])]), _c("div", {
      staticClass: "flex-row-start align-item-center padding-left-60"
    }, [_c("span", {
      staticClass: "font-16 color-6E6E6E margin-top-20 text-left"
    }, [_vm._v(_vm._s(item.content))])])]);
  }), 0)]), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("车货匹配")]), _c("div", {
    staticClass: "font-16 color-555555 margin-top-25 line-height-16 width-670"
  }, [_vm._v(" 危化镖局是危险品车货匹配信息平台，用精准的大数据帮助用户找车找货，危化镖局用互联网方式撬开了改造传统危险品物流的第一道关口，用大数据为用户创造价值，助力物流升级之路开启。承揽全国危化品运输订单，匹配最优运力。")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-50"
  }, [_c("div", {
    staticClass: "flex-col-center align-item-center width-100 margin-right-50"
  }, [_c("img", {
    staticClass: "width-100",
    attrs: {
      src: require("../../assets/static/img/home/new/03.png")
    }
  }), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("15万+合作司机")])]), _c("div", {
    staticClass: "flex-col-center align-item-center width-100 margin-right-50"
  }, [_c("img", {
    staticClass: "width-100",
    attrs: {
      src: require("../../assets/static/img/home/new/03.png")
    }
  }), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("200万+车源路线")])]), _c("div", {
    staticClass: "flex-col-center align-item-center width-100 margin-right-50"
  }, [_c("img", {
    staticClass: "width-100",
    attrs: {
      src: require("../../assets/static/img/home/new/05.png")
    }
  }), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("10万条/日货源信息")])]), _c("div", {
    staticClass: "flex-col-center align-item-center width-100 margin-right-50"
  }, [_c("img", {
    staticClass: "width-100",
    attrs: {
      src: require("../../assets/static/img/home/new/06.png")
    }
  }), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("智慧塔·智能配载系统")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-07",
    staticStyle: {
      "background-color": "#D5E3F2"
    }
  }, [_c("div", {
    staticClass: "main-item width-1280 margin-auto flex-row-sb align-item-center"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/home/new/07.png")
    }
  }), _c("div", {
    staticClass: "text-left width-670"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("合作网点")]), _c("div", {
    staticClass: "font-16 color-555555 margin-top-25 line-height-16"
  }, [_vm._v(" 合作共赢是危化镖局的核心理念，基于此愿景，危化镖局业务覆盖已然布设到全国482座城市，并与2000余家行业配套的地方仓储、蒸洗罐、汽修等综合服务企业建立联系，为行业用户带来最直接的贴身服务。除此之外，危化镖局深化服务能力，已然在全国13个重点省份布设区域服务中心，协同地方综合服务资源，为行业企业带来更多更好的服务质量。 ")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-50"
  }, [_c("div", {
    staticClass: "flex-col-center align-item-center bgc-FFFFFF width-130 height-100 margin-right-20 radius-6"
  }, [_c("span", {
    staticClass: "font-weight-500 font-24",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("13个")]), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("业务分公司")])]), _c("div", {
    staticClass: "flex-col-center align-item-center bgc-FFFFFF width-130 height-100 margin-right-20 radius-6"
  }, [_c("span", {
    staticClass: "font-weight-500 font-24",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("482座")]), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("业务城市")])]), _c("div", {
    staticClass: "flex-col-center align-item-center bgc-FFFFFF width-130 height-100 margin-right-20 radius-6"
  }, [_c("span", {
    staticClass: "font-weight-500 font-24",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("1000+")]), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("合作仓储")])]), _c("div", {
    staticClass: "flex-col-center align-item-center bgc-FFFFFF width-130 height-100 margin-right-20 radius-6"
  }, [_c("span", {
    staticClass: "font-weight-500 font-24",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("800+")]), _c("span", {
    staticClass: "margin-top-15 font-18 color-333333 text-center"
  }, [_vm._v("蒸洗网点")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-item flex-row-start"
  }, [_c("div", {
    staticClass: "item-l"
  }, [_c("div", {
    staticClass: "font-20 text-left",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("连接运输系统，助力货运经营者管好车、算好账")]), _c("div", {
    staticClass: "font-16 color-212121 text-left margin-top-30"
  }, [_c("span", {
    staticClass: "margin-right-10 font-weight-500",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("·")]), _vm._v("自有运力&外协运力一键调度&危险品自动适配 ")]), _c("div", {
    staticClass: "font-16 color-212121 text-left margin-top-20"
  }, [_c("span", {
    staticClass: "margin-right-10 font-weight-500",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("·")]), _vm._v("运输全程可视化管理，时效、安全尽在掌握 ")]), _c("div", {
    staticClass: "font-16 color-212121 text-left margin-top-20"
  }, [_c("span", {
    staticClass: "margin-right-10 font-weight-500",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("·")]), _vm._v("司机在途票据拍照上传，财务便捷审核、快捷支付 ")]), _c("div", {
    staticClass: "font-16 color-212121 text-left margin-top-20"
  }, [_c("span", {
    staticClass: "margin-right-10 font-weight-500",
    staticStyle: {
      color: "#2A559D"
    }
  }, [_vm._v("·")]), _vm._v("企业经营数据、收支利润一目了然，盈亏每日可知 ")])]), _c("img", {
    staticClass: "item-r",
    attrs: {
      src: require("../../assets/static/img/home/new/08.png")
    }
  })]);
}];
render._withStripped = true;