"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reg_content",
    staticStyle: {
      width: "100%",
      "overflow-y": "auto",
      "scroll-behavior": "smooth"
    }
  }, [_c("el-container", [_c("el-header", {
    attrs: {
      height: "100px"
    }
  }, [_c("regHeader")], 1), _c("el-main", [_c("div", {
    staticClass: "reg_bg"
  }), _c("div", {
    staticClass: "reg_main"
  }, [_c("div", {
    staticClass: "reg_main_content"
  }, [_c("regCarousel", {
    attrs: {
      indicatorPosition: "",
      carouselWidth: _vm.carouselWidth,
      carouselHeight: "672px",
      carouselData: _vm.carouselData
    }
  }), _c("regLogin", {
    attrs: {
      loginType: "MARKETING_PAGE",
      landingPageJumpLink: _vm.landingPageJumpLink,
      pageId: _vm.pageId,
      userId: _vm.userId,
      template: _vm.template,
      uuid: _vm.uuid
    }
  })], 1)])]), _c("el-footer", {
    attrs: {
      height: "165px"
    }
  }, [_c("regFooter")], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;