"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("p", {
    ref: "htmlContent",
    attrs: {
      id: "htmlContent"
    },
    domProps: {
      innerHTML: _vm._s(_vm.html)
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;