"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sideNav = _interopRequireDefault(require("../../components/side-nav.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _join_form = _interopRequireDefault(require("../../components/join_form.vue"));
var _downguid = _interopRequireDefault(require("../../components/downguid.vue"));
var _default = exports.default = {
  name: 'WMS',
  components: {
    footNav: _footNav.default,
    sideNav: _sideNav.default,
    joinForm: _join_form.default,
    downGuid: _downguid.default
  },
  data: function data() {
    return {};
  },
  methods: {}
};