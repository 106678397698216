"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sideNav = _interopRequireDefault(require("@/components/side-nav.vue"));
var _footNav = _interopRequireDefault(require("@/components/foot-nav"));
var _downguid = _interopRequireDefault(require("@/components/downguid.vue"));
var _swiperImg = _interopRequireDefault(require("@/components/swiper-img.vue"));
var _default = exports.default = {
  name: 'aboutus',
  components: {
    footNav: _footNav.default,
    sideNav: _sideNav.default,
    downGuid: _downguid.default,
    swiper: _swiperImg.default
  },
  data: function data() {
    return {
      scroll: null,
      honorList: [{
        imgUrl: require("../../assets/static/img/aboutus/03.jpg"),
        title: '高新技术企业'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/04.jpg"),
        title: '4A物流企业'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/05.jpg"),
        title: '理事单位'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/06.jpg"),
        title: '会员单位'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/07.jpg"),
        title: '综合服务企业'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/08.jpg"),
        title: '副会长单位'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/09.jpg"),
        title: '标杆企业'
      }, {
        imgUrl: require("../../assets/static/img/aboutus/10.jpg"),
        title: '道路运输服务企业2'
      }],
      companyData: [{
        name: '化工企业用户',
        num: '40万+'
      }, {
        name: '合作危运物流公司',
        num: '3500+'
      }, {
        name: '业务覆盖城市',
        num: '482座'
      }, {
        name: '合作危运司机',
        num: '15万+'
      }, {
        name: '货源信息',
        num: '10万条/日'
      }, {
        name: '资讯阅读量',
        num: '2亿+'
      }]
    };
  },
  watch: {
    $route: function $route(to, from) {
      if (this.$route.path.indexOf('aboutus') >= 0) {
        if (this.$route.query.to) {
          this.goAnchor(this.getSelector());
        }
      }
    }
  },
  mounted: function mounted() {
    if (this.$route.query.to) {
      this.goAnchor(this.getSelector());
    }
  },
  methods: {
    // 获取锚点
    getSelector: function getSelector() {
      var toIdName = this.$route.query.to;
      return toIdName;
    },
    // 锚点滚动
    goAnchor: function goAnchor(selector) {
      var _this = this;
      setTimeout(function () {
        _this.$refs["".concat(selector)].scrollIntoView({
          behavior: "smooth",
          // 平滑过渡
          block: "start" // start,center,end
        });
        console.log('selector', _this.$refs["".concat(selector)].offsetTop);
      }, 100);
    },
    toPage: function toPage() {
      window.location.href = 'https://www.xmrc.com.cn/net/info/showcojob.aspx?CompanyID=934738';
    }
  }
};