"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _shaderWave = _interopRequireDefault(require("../../components/shader/shader-wave.vue"));
var _shader_mouse = _interopRequireDefault(require("../../components/shader/shader_mouse.vue"));
var _default = exports.default = {
  name: 'welcome',
  components: {
    shaderWave: _shaderWave.default,
    mouseLine: _shader_mouse.default
  },
  data: function data() {
    return {
      mouselinenb: 230,
      wavespeed: 7,
      wavewidth: 600,
      wavedepth: 600,
      countTime: 100,
      showbalone: false,
      showbaltwo: false,
      showbalthree: false,
      showbalfour: false,
      showbalfive: false,
      baldata: []
    };
  },
  created: function created() {
    var that = this;
    // 获取车货源数据
    that.getData();
    // 控制气球动画
    that.baloneInterval = setInterval(function () {
      that.showbalone = true;
    }, 500);
    that.baltwoInterval = setInterval(function () {
      that.showbaltwo = true;
    }, 1000);
    that.balthreeInterval = setInterval(function () {
      that.showbalthree = true;
    }, 1500);
    that.balfourInterval = setInterval(function () {
      that.showbalfour = true;
    }, 2000);
    that.balfiveInterval = setInterval(function () {
      that.showbalfive = true;
    }, 2500);
  },
  mounted: function mounted() {
    // 处理非PC端
    if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
      window.location.href = process.env.VUE_APP_BASE_API + "/h5/wap/#/";
    }
  },
  methods: {
    // 请求获取车货源数据
    getData: function getData() {
      var that = this;
      that.$http.post('/vescort/oc/ocshareaction/findocsharetotal', {}, {
        emulateJSON: true
      }).then(function (res) {
        //  console.log(res.body);
        that.baldata = res.body.proData;
        //  console.log(that.baldata)
      });
    }
  }
}; //shaderWave.init();