"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516"
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _c("div", {
    staticClass: "cont-01",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("img", {
    staticClass: "width-430 margin-left-50",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/software/01.jpg")
    }
  }), _c("div", {
    staticClass: "width-640"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("TMS-专业危险品运输管理系统")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20 line-height-23"
  }, [_vm._v(" 想科学降低运费？想快速提升物流服务水平？选择危化镖局TMS！智能优化引擎，强大运力池，结合专业运输控制塔团队，实现可预期、可量化、可持续的成本节省和服务水平改善。已有多家物流企业实现20—30%运输费用节省。 ")]), _c("div", {
    staticClass: "more-btn",
    on: {
      click: function click($event) {
        return _vm.toPage("TMS");
      }
    }
  }, [_vm._v("了解更多")])])])]), _c("div", {
    staticClass: "cont-01",
    staticStyle: {
      "background-color": "#f8f9fa"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("div", {
    staticClass: "width-640"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("WMS-专业危险品仓储管理系统")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20 line-height-23"
  }, [_vm._v(" 大中型化工企业都在使用的WMS仓储管理系统，能有效提升30%仓库空间流转率，全程无纸化作业，实现多品多库，单品单库，多库存同步共享。管理者便捷可视化管理化工园区，高效协同方案，降低企业成本，提高客户的满意度，从而提升企业的核心竞争力。 ")]), _c("div", {
    staticClass: "more-btn",
    on: {
      click: function click($event) {
        return _vm.toPage("WMS");
      }
    }
  }, [_vm._v("了解更多")])]), _c("img", {
    staticClass: "width-430",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/software/02.jpg")
    }
  })])]), _c("div", {
    staticClass: "cont-01",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("img", {
    staticClass: "width-430",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/software/03.jpg")
    }
  }), _c("div", {
    staticClass: "width-640"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("5G智慧园区综合管控平台")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20 line-height-23"
  }, [_vm._v(" 危化镖局携手中国联通共同推出的一站式智慧园区解决方案，融合新一代信息与通信技术，具备信息采集、高速信息传输、高度集中计算、智能事务处理的园区综合服务，协助危化企业建立安全生产风险监测预警系统，构建安全生产管控和隐患排查治理的双重预防机制。 ")]), _c("div", {
    staticClass: "more-btn",
    on: {
      click: function click($event) {
        return _vm.toPage("wisdom5G");
      }
    }
  }, [_vm._v("了解更多")])])])]), _c("div", {
    staticClass: "cont-01",
    staticStyle: {
      "background-color": "#f8f9fa"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("div", {
    staticClass: "width-640"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("数字供应链产品")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20 line-height-23"
  }, [_vm._v(" 危化镖局致力于为各企业结合各种实际场景，提供多样化的供应链数字化产品工具，助力于提高企业管理和生产制造效率，目前可以提供园区导航、扫码发货、运输GPS监控、无人值守地磅、人员定位、泄露检测等多种数字化产品。 ")]), _c("div", {
    staticClass: "more-btn",
    on: {
      click: function click($event) {
        return _vm.toPage("chain");
      }
    }
  }, [_vm._v("了解更多")])]), _c("img", {
    staticClass: "width-430",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/software/04.jpg")
    }
  })])]), _c("appointment", {
    attrs: {
      sourceType: "100"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;