"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointment = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment.vue"));
var _default = exports.default = {
  components: {
    appointment: _appointment.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/logisticsPark/banner-01.jpg")
      }],
      safeRules: ['行车“十严禁”', '行车“十必须”', '行车“十要求”', '行车“十提倡”'],
      bindRules: [],
      tranStep: [{
        title: '司机着装标准及证件准备',
        desc: '行驶证、营运证、驾驶证、身份证、危货上岗证',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/logisticsPark/05.png")
      }, {
        title: '出车前点检',
        desc: '物流车辆点检表（出厂前）',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/logisticsPark/06.png")
      }, {
        title: '车辆装货停靠',
        desc: '拉手刹 - 熄火 - 垫三角木 - 固定车门',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/logisticsPark/07.png")
      }, {
        title: '监督装货、核对数量、检查外观是否异常',
        desc: '装货中，监督仓管装货，防止漏货等其他异常',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/logisticsPark/08.png")
      }]
    };
  }
};