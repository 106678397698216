"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vueSeamlessScroll = _interopRequireDefault(require("vue-seamless-scroll"));
var _default = exports.default = {
  name: 'Example01Basic',
  components: {
    vueSeamlessScroll: _vueSeamlessScroll.default
  },
  props: {
    listData: {
      type: Array,
      default: []
    }
  },
  data: function data() {
    return {
      classOption: {
        direction: 2,
        limitMoveNum: 6
      }
    };
  }
};