"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy;
  return _c("div", {
    staticClass: "reg_header"
  }, [_c("router-link", {
    staticClass: "reg_logo",
    attrs: {
      to: {
        name: "home"
      },
      underline: false
    }
  }), _c("a", {
    staticClass: "reg_tel"
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;