"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tms_content"
  }, [_c("div", {
    staticClass: "tms_particles"
  }, [_c("div", {
    attrs: {
      id: "particles"
    }
  }), _c("div", {
    staticClass: "tms_particles_con"
  }, [_vm._m(0), _c("div", {
    staticClass: "tms_carousel"
  }, [_c("div", {
    staticClass: "tms_carousel_header"
  }, [_vm._v("TMS-六大核心功能，打破运输瓶颈")]), _c("div", {
    staticClass: "carousel_main"
  }, [_c("div", {
    staticClass: "carousel_content"
  }, [_c("el-carousel", {
    attrs: {
      interval: 4000,
      type: "card",
      height: "280px",
      "indicator-position": "none",
      autoplay: false
    },
    on: {
      change: _vm.tmsChange
    }
  }, _vm._l(_vm.tmsCarsouelData, function (titem, tindex) {
    return _c("el-carousel-item", {
      key: tindex
    }, [_c("img", {
      staticStyle: {
        width: "100%",
        height: "100%"
      },
      attrs: {
        src: titem.imageUrl,
        alt: ""
      }
    })]);
  }), 1)], 1), _c("div", {
    staticClass: "carousel_footer text-white"
  }, [_vm._v(" " + _vm._s(_vm.tmsCarsouelData[_vm.tmsCarouselActive].textTitle) + "：" + _vm._s(_vm.tmsCarsouelData[_vm.tmsCarouselActive].textContent) + " ")])])])])]), _c("div", {
    staticClass: "tms_service"
  }, [_c("div", {
    staticClass: "tms_service_main"
  }, [_c("div", {
    staticClass: "tms_service_title font-bold"
  }, [_vm._v("多种运输方案服务及合作")]), _c("el-row", {
    staticClass: "tms_service_con"
  }, _vm._l(_vm.tmsServices, function (titem, tindex) {
    return _c("div", {
      key: tindex,
      staticClass: "tms_service_submain"
    }, [_c("div", {
      staticClass: "tms_service_subcon"
    }, [_c("img", {
      attrs: {
        src: titem.simgSrc
      }
    }), _c("div", {
      staticClass: "tms_service_subtitle border-bottom flex-column-alignc"
    }, [_vm._v(_vm._s(titem.title))]), _c("p", {
      staticClass: "text-left"
    }, [_vm._v(_vm._s(titem.con))])])]);
  }), 0)], 1)]), _c("div", {
    staticClass: "tms_form"
  }, [_c("join-form", {
    attrs: {
      "business-type": "30",
      "title-txt": "限时福利",
      "sub-title-txt": "现在提交试用申请，享免费咨询服务",
      "sub-title-color": "#4988fb",
      "submit-btn-txt": "点击预约试用",
      "has-phone-footer": false
    }
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tms_particles_header"
  }, [_c("fieldset", {
    staticClass: "tms_particles_title"
  }, [_c("legend", {
    attrs: {
      align: "center"
    }
  }, [_vm._v("TMS")]), _c("div", {
    staticClass: "title_content text-center"
  }, [_vm._v("危险品物流调度管理系统")])]), _c("div", {
    staticClass: "tms_particles_txt"
  }, [_c("p", [_vm._v("运输配送管理系统专注于化工运输领域，成熟的运输网络优化解决方案。基于化工物流运输网络设计，")]), _c("p", [_vm._v("集成最佳行业实践业务模型，适应零担运输、干线运输、区域调配等多种业务模式，通过对运输作业的运力、路线、人员、")]), _c("p", [_vm._v("承运商的优化处理，实现运力资源的合理配置、动态跟踪和作业任务的全程监控。")])])]);
}];
render._withStripped = true;