"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _particles = _interopRequireDefault(require("../../assets/particles.json"));
var _join_form = _interopRequireDefault(require("../../components/join_form.vue"));
var _default = exports.default = {
  name: 'tms',
  components: {
    joinForm: _join_form.default
  },
  data: function data() {
    return {
      tmsCarouselActive: 0,
      tmsCarsouelData: [{
        imageUrl: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/tms_stowage_img.png',
        textTitle: '智能配置',
        textContent: '帮您解决混载、空载问题告别运力浪费，提示企业利润'
      }, {
        imageUrl: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/tms_load_img.png',
        textTitle: '在途管理',
        textContent: '有效针对车辆状况、司机状态、运输过程进行全程跟踪，掌控安全，降低事故率'
      }, {
        imageUrl: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/tms_driver_img.png',
        textTitle: '司机管理',
        textContent: '人车分离，司机动态更新，责任到位，加强贵公司运输效率'
      }],
      tmsServices: [{
        simgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/zulin_ico.png',
        title: '车货源云端共享',
        con: '无法处理的货源或多余的运力可以即时分享到交易大厅，通过云端大数据进行智能调度，帮您解决货源配载难题和货车空载运力浪费的问题。'
      }, {
        simgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/lianhe_ico.png',
        title: '代招投标服务',
        con: '企业提供路线运费报价，如有适合的化工企业进行招投标，企业会被优先推荐匹配，帮您获得一手货源订单。'
      }, {
        simgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/zixun_ico.png',
        title: '落地配联盟',
        con: '可加入危化镖局落地配联盟，落地配联盟成员享有更为专业的TMS，联盟内成员资源进行免费共享，借助系统后台可快速链接，实现降本增效!'
      }, {
        simgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/dingzhi_ico.png',
        title: '软件服务',
        con: '根据企业不同需求，有专业技术团队根据贵公司的业务流程开发定制化TMS，有效帮助贵公司提升运输效率和降低运营成本。'
      }]
    };
  },
  mounted: function mounted() {
    // 渲染粒子
    particlesJS("particles", _particles.default);
  },
  methods: {
    // 监听tms轮播，改变文字描述
    tmsChange: function tmsChange(val) {
      this.tmsCarouselActive = val;
    }
  }
};