import { render, staticRenderFns } from "./businessjoin.vue?vue&type=template&id=01146c76&scoped=true"
import script from "./businessjoin.vue?vue&type=script&lang=js"
export * from "./businessjoin.vue?vue&type=script&lang=js"
import style0 from "./businessjoin.vue?vue&type=style&index=0&id=01146c76&lang=scss&scoped=scoped"
import style1 from "./businessjoin.vue?vue&type=style&index=1&id=01146c76&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01146c76",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\vescort-platform-pc-website\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('01146c76')) {
      api.createRecord('01146c76', component.options)
    } else {
      api.reload('01146c76', component.options)
    }
    module.hot.accept("./businessjoin.vue?vue&type=template&id=01146c76&scoped=true", function () {
      api.rerender('01146c76', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/businessjoin/businessjoin.vue"
export default component.exports