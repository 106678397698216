// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/padding.css");
var ___CSS_LOADER_AT_RULE_IMPORT_2___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/color.css");
var ___CSS_LOADER_AT_RULE_IMPORT_3___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/fontSize.css");
var ___CSS_LOADER_AT_RULE_IMPORT_4___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/margin.css");
var ___CSS_LOADER_AT_RULE_IMPORT_5___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/common/radius.css");
var ___CSS_LOADER_AT_RULE_IMPORT_6___ = require("-!../../../node_modules/css-loader/dist/cjs.js??ref--9-oneOf-1-1!../../../node_modules/vue-loader/lib/loaders/stylePostLoader.js!../../../node_modules/postcss-loader/src/index.js??ref--9-oneOf-1-2!../../assets/static/css/pcMall/flex.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_4___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_5___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_6___);
// Module
exports.push([module.id, ".page[data-v-6033228d] {\n  width: 100%;\n  min-width: 6.666667rem;\n}\n.more-btn[data-v-6033228d] {\n  width: 0.5625rem;\n  height: 0.208333rem;\n  line-height: 0.208333rem;\n  margin-top: 0.3125rem;\n  background: transparent;\n  border: 0.005208rem solid #B2B2B2;\n  border-radius: 0.104167rem;\n  text-align: center;\n  cursor: pointer;\n  color: #212121;\n  font-size: 0.072917rem;\n}\n.cont-01[data-v-6033228d] {\n  width: 100%;\n  min-width: 6.666667rem;\n  background: #fff;\n  padding-top: 0.625rem;\n  padding-bottom: 0.78125rem;\n}\n.cont-01 .main-item[data-v-6033228d] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-02[data-v-6033228d] {\n  width: 100%;\n  min-width: 6.666667rem;\n  background: #f6f7f8;\n  padding-top: 0.625rem;\n  padding-bottom: 0.833333rem;\n}\n.cont-02 .main-item[data-v-6033228d] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-02 .main-item .safe-item[data-v-6033228d] {\n  width: 1.5625rem;\n  height: 0.291667rem;\n  line-height: 0.291667rem;\n  text-align: center;\n  background: #e2e9f8;\n  border-radius: 0.145833rem;\n}\n.cont-02 .main-item .bind-item[data-v-6033228d] {\n  width: 6.666667rem;\n  height: 0.640625rem;\n  background: #ECECEC;\n  border: 0.020833rem solid #FFFFFF;\n}\n.cont-03[data-v-6033228d] {\n  width: 100%;\n  min-width: 6.666667rem;\n  background: #fff;\n  padding-top: 0.625rem;\n  padding-bottom: 0.885417rem;\n}\n.cont-03 .main-item[data-v-6033228d] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-04[data-v-6033228d] {\n  width: 100%;\n  min-width: 6.666667rem;\n  background: #f6f7f8;\n  padding-top: 0.625rem;\n  padding-bottom: 0.885417rem;\n}\n.cont-04 .main-item[data-v-6033228d] {\n  width: 6.666667rem;\n  margin: 0 auto;\n}\n.cont-04 .main-item .tran-step[data-v-6033228d] {\n  width: 3.125rem;\n}\n.cont-04 .main-item .tran-step[data-v-6033228d]:nth-child(n+3) {\n  margin-top: 0.3125rem;\n}", ""]);
// Exports
module.exports = exports;
