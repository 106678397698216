"use strict";

var _typeof = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = _interopRequireWildcard(require("../../assets/static/js/utils/util"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  name: "noImgListRow",
  props: {
    rowData: {
      type: Object,
      require: true,
      default: Object
    },
    avatarRow: {
      type: Boolean,
      default: true
    } // 头像那行的展示/隐藏ault: false
  },
  data: function data() {
    return {
      avatarNormalIco: require("../../assets/static/img/commonAvatar/avatar_\u9556\u5E08.jpg")
    };
  },
  computed: {
    city: function city() {
      if (this.rowData) {
        if (this.rowData.city && this.rowData.city.indexOf('全省') < 0) {
          return this.rowData.city;
        } else {
          return this.rowData.province;
        }
      }
    },
    userName: function userName() {
      if (this.rowData) {
        // enterpriseName 公司名称
        if (this.rowData.enterpriseName) {
          return (0, _util.txtEllipsis)(this.rowData.enterpriseName, 6);
        } else if (this.rowData.contactPerson) {
          return (0, _util.txtEllipsis)(this.rowData.contactPerson, 6);
        }
      }
    },
    time: function time() {
      if (this.rowData && this.rowData.releaseTime) return _util.default.timeToString(this.rowData.releaseTime);
    }
  },
  created: function created() {},
  methods: {
    // 超出显示省略号
    txtEllipsis: _util.txtEllipsis
  }
};