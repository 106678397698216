"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatDate = void 0;
exports.formatTime = formatTime;
exports.getDate = getDate;
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.replace");
var minute = 1000 * 60;
var hour = minute * 60;
var day = hour * 24;
var halfamonth = day * 15;
var month = day * 30;
var timeStr = '';
function getDate(dateTimeStamp) {
  if (dateTimeStamp == undefined) {
    return false;
  } else {
    dateTimeStamp = dateTimeStamp.replace(/\-/g, "/");
    var sTime = new Date(dateTimeStamp).getTime(); //把时间pretime的值转为时间戳

    var now = new Date().getTime(); //获取当前时间的时间戳

    var diffValue = now - sTime;
    if (diffValue < 0) {
      //  console.log("结束日期不能小于开始日期！");
    }
    var monthC = diffValue / month;
    var weekC = diffValue / (7 * day);
    var dayC = diffValue / day;
    var hourC = diffValue / hour;
    var minC = diffValue / minute;
    if (monthC >= 12) {
      timeStr = parseInt(monthC / 12) + "年前";
    } else if (monthC >= 1) {
      timeStr = parseInt(monthC) + "个月前";
    } else if (weekC >= 1) {
      timeStr = parseInt(weekC) + "周前";
    } else if (dayC >= 1) {
      timeStr = parseInt(dayC) + "天前";
    } else if (hourC >= 1) {
      timeStr = parseInt(hourC) + "个小时前";
    } else if (minC >= 1) {
      timeStr = parseInt(minC) + "分钟前";
    } else {
      timeStr = "刚刚";
    }
  }
  return timeStr;
}

//格式化时间
function formatTime(timeStamp) {
  var date1 = new Date(timeStamp);
  var year = date1.getFullYear();
  var month = date1.getMonth() + 1;
  var day = date1.getDate();
  var hours = date1.getHours();
  var minutes = date1.getMinutes();
  var seconds = date1.getSeconds();
  return year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds;
}

// 日期格式化
var formatDate = exports.formatDate = function formatDate(timestamp) {
  var template = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'yyyy-MM-dd';
  var str = '' + timestamp;
  if (str.length < 10) return null;
  if (str.length === 10) timestamp *= 1000;
  return new Date(timestamp).format(template);
};
Date.prototype.format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1,
    //月份
    'd+': this.getDate(),
    //日
    'h+': this.getHours(),
    //小时
    'm+': this.getMinutes(),
    //分
    's+': this.getSeconds(),
    //秒
    'q+': Math.floor((this.getMonth() + 3) / 3),
    //季度
    'S': this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp('(' + k + ')').test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length === 1 ? o[k] : ('00' + o[k]).substr(('' + o[k]).length));
  return fmt;
};