"use strict";

var _typeof = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _util = _interopRequireWildcard(require("../../assets/static/js/utils/util"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  name: "avatarListRow",
  props: {
    rowData: {
      type: Object,
      require: true,
      default: Object
    }
  },
  data: function data() {
    return {
      avatarNormalIco: require("../../assets/static/img/commonAvatar/avatar_\u9556\u5E08.jpg"),
      boyIcon: require("../../assets/static/img/cooperationMall/detail_boy_ico.png"),
      girlIcon: require("../../assets/static/img/cooperationMall/detail_girl_ico.png"),
      boyAvatar: require("../../assets/static/img/commonAvatar/avatar_ico8.jpg"),
      girlAvatar: require("../../assets/static/img/commonAvatar/avatar_ico14.jpg")
    };
  },
  computed: {
    time: function time() {
      if (this.rowData && this.rowData.releaseTime) return _util.default.timeToString(this.rowData.releaseTime);
    },
    img: function img() {
      if (this.rowData) {
        var imgData = null;
        var data = this.rowData;
        if (data.picUrl) {
          imgData = (0, _util.subPicUrl)(data.picUrl);
        } else if (data.ossHeadUrl) {
          // sex:1男、0女
          if (data.ossHeadUrl.indexOf('/default_logo/broker.png') >= 0 && data.sex == '1') {
            imgData = new Array(this.boyAvatar);
          } else if (data.ossHeadUrl.indexOf('/default_logo/broker.png') >= 0 && data.sex == '0') {
            imgData = new Array(this.girlAvatar);
          } else {
            imgData = (0, _util.subPicUrl)(data.ossHeadUrl);
          }
        }
        return imgData;
      }
    },
    desc: function desc() {
      if (this.rowData) {
        var postNature = this.rowData.postNature ? "".concat(this.rowData.postNature, "\xA0\xA0") : '';
        var workTime = this.rowData.workTime ? "\xA0\xA0".concat(this.rowData.workTime, "\xA0\xA0") : '';
        var age = this.rowData.birthDate ? "\xA0\xA0".concat((0, _util.getAge)(this.rowData.birthDate) - 1, "\u5C81\xA0\xA0") : '';
        var certificate = this.rowData.certificate ? "\xA0\xA0".concat(this.rowData.certificate.split(',')[0], "\xA0\xA0") : '';
        var arr = new Array(postNature, workTime, age, certificate);
        return arr.notEmpty().join('\xa0\xa0|\xa0\xa0');
      }
    },
    city: function city() {
      if (this.rowData) {
        if (this.rowData.city && this.rowData.city.indexOf('全省') < 0) {
          return this.rowData.city;
        } else {
          return this.rowData.province;
        }
      }
    }
  },
  created: function created() {},
  methods: {
    getAge: _util.getAge,
    // 去详情页
    toDetail: function toDetail() {
      console.log('去求职详情页');
      this.$emit('toDetail');
    }
  }
};