"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _vm._m(0), _vm._m(1), _c("div", {
    staticClass: "cont-03 flex-col-start align-item-center",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("一网通办")]), _vm._m(2), _c("div", {
    staticClass: "width-1280 font-18 color-555555 margin-top-35 text-left"
  }, [_vm._v(" 利用大数据技术和云服务，实现各类企业申办事项的智能推送和线上受理，破除企业申办信息流的壁垒，让信息多跑路，企业少跑路，为企业提供种类齐全、高效便捷的一网通办服务。 ")]), _c("div", {
    staticClass: "width-1280 color-444444 margin-top-50 flex-row-sb align-item-center"
  }, _vm._l(_vm.list1, function (item, index) {
    return _c("div", {
      staticClass: "conduct"
    }, [_c("div", {
      staticClass: "conduct-title font-22 color-212121"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "font-16 margin-top-50"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0), _vm._m(3)]), _c("div", {
    staticClass: "cont-04",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("img", {
    staticClass: "width-560",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/wisdom5G/05.png")
    }
  }), _c("div", {
    staticClass: "width-640"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("合作案例——鑫展旺化工物流园区")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20",
    staticStyle: {
      "line-height": "26px"
    }
  }, [_vm._v(" 危化镖局通过“智能化、信息化、标准化”三位一体，为鑫展旺化工物流园区提供了一款集“安全管控、订单作业、财务管理以及成本分析等为一体化”的综合管理软件，为企业提供高效、安全的全流程管控，杜绝安全隐患，实现经济效益和生命财产安全双保障的效果。 ")]), _c("div", {
    staticClass: "more-btn",
    on: {
      click: function click($event) {
        return _vm.toPage("logisticsPark");
      }
    }
  }, [_vm._v("了解更多")])])])]), _c("appointment", {
    attrs: {
      sourceType: "10"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-01 flex-col-start align-item-center",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "font-18 color-444444 text-left width-1280 line-height-16"
  }, [_vm._v(" 传统园区安全管理存在系统分散、人员管理复杂、运营效率低下等问题，危化镖局携手中国联通推出了一站式智慧园区解决方案，提供算力算法，帮助园区管理人员对园区内人员行为、环境风险等因素进行智能化监管，链接人、车、物、场景，构建聚群化智慧园区管理平台，大幅提高园区安全管理水平，有效增强管理人员的整体信息掌控力并降低运营成本。 ")]), _c("div", {
    staticClass: "font-36 font-weight-500 color-212121 margin-top-80"
  }, [_vm._v("平台整体架构")]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/wisdom5G/01.png")
    }
  }), _c("div", {
    staticClass: "font-36 font-weight-500 color-212121",
    staticStyle: {
      "margin-top": "115px"
    }
  }, [_vm._v("园区指挥控制中心")]), _c("div", {
    staticClass: "font-18 color-444444 text-left width-1280 margin-top-50 line-height-16"
  }, [_vm._v(" 园区指挥控制中心以智慧园区可视化管理平台为基础，采集园区的安全、消防、智能建筑设备运行等数据信息，通过3D建模 实现园区场景还原与重建，在大屏幕显示， 让园区能实时掌控 能源能耗，运营状况，安全防范等信息，并可通过视频通信、无线、有线、数据等多重通信手段实现应急指挥调度，确保园区安全生产环境，打造“安全、智慧、高效、绿色” 的园区。")]), _c("div", {
    staticClass: "flex-row-center align-item-center margin-top-50"
  }, [_c("div", {
    staticClass: "command-item color-3269F6 font-18"
  }, [_vm._v("环境监测一张图")]), _c("div", {
    staticClass: "command-item color-3269F6 font-18"
  }, [_vm._v("指挥调度一张图")]), _c("div", {
    staticClass: "command-item color-3269F6 font-18"
  }, [_vm._v("企业服务一张图")]), _c("div", {
    staticClass: "command-item color-3269F6 font-18"
  }, [_vm._v("园区运营一张图")])]), _c("img", {
    staticClass: "margin-top-60 width-1280",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/wisdom5G/02.jpg")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-02",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("高效的应急处置")]), _c("img", {
    staticClass: "width-1280 margin-top-90",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/wisdom5G/03.png")
    }
  }), _c("div", {
    staticClass: "warm font-16 color-white radius-6 text-left padding-top-25 padding-bottom-25 padding-left-30 padding-right-30"
  }, [_vm._v(" 危险源实时监测，及时发出告警；鹰眼/监控探头/无人机采集现场实况，准确确认情况；通过移动端能更快地将现场实况和扑救措施通知到相关人员，每一步都为事故处理争取一分一秒的先机。 ")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "command flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("涉企政策")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("项目指南")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("企业入驻")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("企业信息管理")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("企业帮办")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("供需服务")]), _c("div", {
    staticClass: "command-item color-white font-16"
  }, [_vm._v("企业诉求")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "width-1280 margin-top-50"
  }, [_c("img", {
    staticClass: "width-1280",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/wisdom5G/04.png")
    }
  })]);
}];
render._withStripped = true;