import { render, staticRenderFns } from "./htmlpanel.vue?vue&type=template&id=225498d9&scoped=true"
import script from "./htmlpanel.vue?vue&type=script&lang=js"
export * from "./htmlpanel.vue?vue&type=script&lang=js"
import style0 from "./htmlpanel.vue?vue&type=style&index=0&id=225498d9&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "225498d9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\vescort-platform-pc-website\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('225498d9')) {
      api.createRecord('225498d9', component.options)
    } else {
      api.reload('225498d9', component.options)
    }
    module.hot.accept("./htmlpanel.vue?vue&type=template&id=225498d9&scoped=true", function () {
      api.rerender('225498d9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/htmlpanel.vue"
export default component.exports