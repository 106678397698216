"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("财税统筹")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25"
  }, [_vm._v("解决企业外调运力无法获取进项难题，用合法、合规、合理的方式大幅度降低企业税负成本")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-90"
  }, _vm._l(_vm.serviceList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-center"
    }, [_c("img", {
      staticClass: "width-80",
      attrs: {
        src: item.url
      }
    }), _c("span", {
      staticClass: "font-26 color-212121 margin-top-50"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-6E6E6E margin-top-20"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _vm._m(0), _c("appointment-five", {
    attrs: {
      sourceType: "40"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("开票服务合作流程")]), _c("img", {
    staticClass: "width-1280 margin-top-110",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/finance/05.png")
    }
  }), _c("div", {
    staticClass: "font-16 color-B2B2B2 line-height-23 margin-top-90"
  }, [_vm._v(" 根据国家税务总局2019年发布的405号文件，允许网络货运平台代司机开具增值税专用发票用于进项抵扣，旨在规范纳税，有效解决个体司机及车队运输开票难题。为更好服务用户，特调研了解需求。若您有纳税需求请按要求提交申请，客服1-3个工作日会与您联系（注：网络货运平台开票必须是真实运输业务，感谢您的配合） ")])])]);
}];
render._withStripped = true;