"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "n-content",
    attrs: {
      id: "newslist"
    }
  }, [_c("div", {
    staticClass: "head",
    style: _vm.hasHeaderShadow ? "box-shadow:0 0 5px #888;" : ""
  }, [_c("div", {
    staticClass: "head-content",
    class: _vm.isSearch == "false" && _vm.hasHeaderShadow ? "" : ""
  }, [_c("div", {
    class: _vm.hasHeaderShadow ? "scroll_left" : "left"
  }, [!_vm.hasHeaderShadow ? _c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/news/news_logo.png"
    }
  }) : _c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/news/news_scroll_logo.png"
    }
  })]), _c("div", {
    staticClass: "center flex-row-center"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "请输入你想要搜索的内容"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.tosearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }, [_c("div", {
    staticClass: "flex-row-center",
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    slot: "append"
  }, [_c("a", {
    staticClass: "rl-white mouse-pointer",
    on: {
      click: _vm.tosearch
    }
  }, [_c("i", {
    staticClass: "el-icon-search"
  }), _vm._v(" 搜索一下 ")])])])], 1), !(_vm.isSearch == "false" && _vm.hasHeaderShadow) ? _c("div", {
    staticClass: "right flex-row-center"
  }) : _vm._e()])]), _c("div", {
    staticClass: "body",
    style: _vm.isSearch == "true" ? "padding-top:84.33px;" : "padding-top:93.7px;"
  }, [_c("div", {
    staticClass: "body-content flex-row-justc"
  }, [_c("div", {
    staticClass: "n-left"
  }, [_vm.menuData && _vm.isSearch == "false" ? _c("el-menu", {
    staticClass: "list-unstyled ellipsis",
    style: {
      "margin-top": _vm.topHeight
    },
    attrs: {
      "default-active": "1-4-1",
      collapse: _vm.isCollapse,
      id: "side_menu_tit"
    }
  }, _vm._l(_vm.menuData, function (item, index) {
    return _c("el-submenu", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "资讯栏目",
          action: "点击",
          opt_label: item.colunmName
        },
        expression: "{category:'资讯栏目', action:'点击', opt_label: item.colunmName}"
      }],
      key: index,
      class: _vm.menuActive == index ? "active mouse-pointer" : "mouse-pointer",
      attrs: {
        index: index + 1 + ""
      }
    }, [_c("div", {
      attrs: {
        slot: "title"
      },
      on: {
        click: function click($event) {
          return _vm.changeActive(index);
        }
      },
      slot: "title"
    }, [_vm._v(" " + _vm._s(item.colunmName) + " ")]), item.children ? _c("el-menu-item-group", {
      staticClass: "subcolumn"
    }, _vm._l(item.children, function (cditem, cdindex) {
      return _c("el-row", {
        key: cdindex
      }, [_c("el-col", {
        attrs: {
          span: 24
        }
      }, [_c("el-menu-item", {
        attrs: {
          index: "1-" + cdindex + 1
        },
        on: {
          click: function click($event) {
            return _vm.tosubColumn(cditem.cmmId, index);
          }
        }
      }, [_vm._v(" " + _vm._s(cditem.colunmName) + " ")])], 1)], 1);
    }), 1) : _vm._e()], 1);
  }), 1) : _vm._e()], 1), _c("div", {
    staticClass: "n-center"
  }, [_vm.carouselData && _vm.menuData && _vm.isDetail != "true" ? _c("list", {
    attrs: {
      colunm: _vm.menuData[_vm.menuActive],
      carouselData: _vm.carouselData,
      isNodata: _vm.isNodata,
      isNoMoredata: _vm.isNoMoredata,
      listData: _vm.listData,
      menuActive: _vm.menuActive
    }
  }) : _vm._e(), _vm.isDetail == "true" && _vm.menuData ? _c("detail", {
    attrs: {
      colunmName: _vm.menuData[_vm.menuActive].colunmName,
      side_bind: "newslist"
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "n-right"
  }, [_c("div", {
    staticClass: "n-right_con",
    staticStyle: {
      width: "100%",
      margin: "0 auto"
    }
  }, [_vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "one-" + index
    }, [item.showPosition == "RIGHT_ONE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan",
      staticStyle: {
        "margin-bottom": "34px"
      }
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "two-" + index
    }, [item.showPosition == "RIGHT_TWO" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "three-" + index
    }, [item.showPosition == "RIGHT_THREE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _c("div", {
    staticClass: "news_right_box news_right_list"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "clearfix",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "minortint_before left"
  }, [_vm._v("最新资讯")]), _c("router-link", {
    staticClass: "hotspot_more left flex-row-center mouse-pointer",
    attrs: {
      target: "_blank",
      to: {
        path: "/newNewsList",
        query: {
          isSearch: false,
          searchVal: "",
          isDetail: false
        }
      }
    }
  }, [_vm._v(" 更多>> ")])], 1), _vm._l(_vm.hotspotData, function (hitem, hindex) {
    return _c("div", {
      key: hindex,
      staticClass: "item minortint flex-row-alignc"
    }, [_c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "最新资讯列表",
          action: "点击",
          opt_label: hitem.articleTitle
        },
        expression: "{category:'最新资讯列表', action:'点击', opt_label:hitem.articleTitle}"
      }],
      staticClass: "hotspot_link flex-row-alignc",
      attrs: {
        target: "_blank",
        to: {
          path: "/newNewsList",
          query: {
            menuActive: _vm.menuActive,
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: hitem.ammId
          }
        }
      }
    }, [_c("span", {
      staticClass: "hotspot_title ellipsis mouse-pointer"
    }, [_vm._v(_vm._s(hitem.articleTitle))]), _c("span", {
      staticClass: "hotspot_date mouse-pointer"
    }, [_vm._v(_vm._s(hitem.articleReleaseTime))])])], 1);
  })], 2)], 1), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "four-" + index
    }, [item.showPosition == "RIGHT_FOUR" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  })], 2)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;