"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'landingpage',
  data: function data() {
    return {
      pageId: '',
      template: '',
      userId: ''
    };
  },
  created: function created() {
    var that = this;
    that.pageId = that.$route.query.pageId ? that.$route.query.pageId : '';
    that.template = that.$route.query.template ? that.$route.query.template : '';
    that.userId = that.$route.query.userId ? typeof that.$route.query.userId == 'string' ? that.$route.query.userId : that.$route.query.userId[0] : '';
    if (that.$route.query.template && that.$route.query.template == '1') {
      that.$router.push({
        name: 'reg_first',
        query: {
          pageId: that.pageId,
          template: that.template,
          userId: that.userId
        }
      });
    } else if (that.$route.query.template && that.$route.query.template == '2') {
      that.$router.push({
        name: 'reg_second',
        query: {
          pageId: that.pageId,
          template: that.template,
          userId: that.userId
        }
      });
    } else if (that.$route.query.template && that.$route.query.template == '3') {
      that.$router.push({
        name: 'reg_third',
        query: {
          pageId: that.pageId,
          template: that.template,
          userId: that.userId
        }
      });
    } else {
      console.log('1233123123');
      return;
      that.$router.push({
        name: 'regpage',
        query: {
          pageId: that.pageId,
          template: that.template,
          userId: that.userId
        }
      });
    }
  }
};