"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "mainlayout_content",
    staticStyle: {
      "scroll-behavior": "smooth"
    },
    attrs: {
      id: "mainlayout_content"
    }
  }, [_vm.$route.name != "home" ? _c("top-nav") : _vm._e(), this.$root.mShow ? _c("div", {
    staticClass: "router-body"
  }, [_c("router-view")], 1) : _vm._e(), _c("side-nav", {
    attrs: {
      side_bind: _vm.routerViewId
    }
  }), _vm.$root.showAcitivity ? _c("div", {
    staticClass: "footer-activity"
  }, [_c("img", {
    staticStyle: {
      width: "100%",
      "min-width": "1200px",
      height: "auto"
    },
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/activity_float.png",
      alt: ""
    }
  }), _c("div", {
    staticClass: "activity_closebtn mouse-pointer",
    on: {
      click: function click($event) {
        _vm.$root.showAcitivity = false;
      }
    }
  })]) : _vm._e(), _c("el-dialog", {
    staticClass: "pop_login",
    attrs: {
      visible: _vm.$root.dialogFormVisible
    },
    on: {
      "update:visible": function updateVisible($event) {
        return _vm.$set(_vm.$root, "dialogFormVisible", $event);
      }
    }
  }, [_vm.$root.dialogFormVisible ? _c("login", {
    attrs: {
      name: _vm.tabName
    },
    on: {
      loginStatus: _vm.loginStatus
    }
  }) : _vm._e()], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;