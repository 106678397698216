"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  props: {
    advertiseData: Array
  },
  created: function created() {
    //  console.log('advertiseData', this.advertiseData);
  }
};