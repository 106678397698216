"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav.vue"));
var _sideNav = _interopRequireDefault(require("../../components/side-nav.vue"));
var _downguid = _interopRequireDefault(require("../../components/downguid.vue"));
var _default = exports.default = {
  name: 'products',
  components: {
    sideNav: _sideNav.default,
    footNav: _footNav.default,
    downGuid: _downguid.default
  }
};