"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
var _relativeTime = require("../../assets/static/js/utils/relativeTime.js");
var _default = exports.default = {
  name: 'list',
  props: ['colunm', 'carouselData', 'listData', 'menuActive', 'isNodata', 'isNoMoredata'],
  data: function data() {
    return {
      page: {
        pageNo: 2,
        pageSize: 10
      },
      searchVal: '',
      testStr: "<span>这是一个新闻<span style='color: red'>刚刚</span></span>"
    };
  },
  computed: {
    isSearch: function isSearch() {
      return this.$route.query.isSearch + '';
    }
  },
  watch: {
    colunm: function colunm() {
      this.page.pageNo = 2;
    }
  },
  created: function created() {
    this.searchVal = this.$route.query.searchVal;
  },
  methods: {
    // 获取更多资讯
    getMoreList: function getMoreList() {
      var that = this;
      // //  console.log('isSearch', this.$route.query.isSearch);
      if (this.$route.query.isSearch == 'true') {
        this.$parent.getSearchData(this.page.pageNo);
      } else if (that.colunm.colunmName == '推荐') {
        that.getPopularRecommend(that.page.pageNo, that.page.pageSize);
      } else {
        // 其余栏目获取置顶列表
        that.getTopList(that.colunm.cmmId, that.page.pageNo, that.page.pageSize);
      }
      that.page.pageNo++;
    },
    // 获取热门推荐栏目列表
    getPopularRecommend: function getPopularRecommend(pageno, pagesize) {
      var that = this;
      var param = {
        "pageSize": pagesize,
        "pageNo": pageno
      };
      var paramStr = {
        "version": "1",
        "fun": "findpopularrecommend",
        "param": param
      };
      var reqParam = {
        jsonParamete: JSON.stringify(paramStr)
      };
      that.$http.post('/vescort/cms/articlemanagementmainaction/findpopularrecommend', reqParam, {
        emulateJSON: true
      }).then(function (res) {
        that.timeOption(res.body.proData);
      });
    },
    // 获取其他栏目列表
    getTopList: function getTopList(cmmid, pageno, pagesize) {
      var that = this;
      that.$http.post('/vescort/cms/articlemanagementmainaction/findpcbycmmid', {
        cmmId: cmmid,
        pageNo: pageno,
        pageSize: pagesize
      }, {
        emulateJSON: true
      }).then(function (res) {
        that.timeOption(res.body.proData.content);
      });
    },
    //处理列表数据
    timeOption: function timeOption(arrdata) {
      var that = this;
      // 循环处理发布时间
      if (arrdata.length > 0) {
        arrdata = arrdata.filter(function (item) {
          if (item.articleReleaseTime) {
            item.articleReleaseTime = (0, _relativeTime.getDate)(item.articleReleaseTime);
          } else {
            item.articleReleaseTime = '未知';
          }
          if (item.articleCoverStyle == 'ARTICLE_COVER_THREE') {
            item.pictureOssUrls = item.pictureOssUrls.split(',');
          }
          // //  console.log('time', getDate(item.articleReleaseTime));
          return item;
        });
        // 拼接列表
        that.listData.content = that.listData.content.concat(arrdata);
      } else {
        this.isNoMoredata = true;
        this.$message({
          message: '暂无更多数据!!',
          type: 'warm',
          duration: '1000'
        });
      }
    },
    bannerToDetail: function bannerToDetail(item) {
      // //  console.log('item', item)
      this.$router.push({
        name: 'detail',
        query: {
          isDetail: true,
          isSearch: false,
          cmmid: this.menuActive,
          searchVal: '',
          ammid: item.amId
        }
      });
    }
  }
};