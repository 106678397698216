"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm._m(0);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "home_container",
    attrs: {
      id: "home"
    }
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/groundPage/\u843D\u5730\u9875.jpg"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;