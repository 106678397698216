"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var data = [{
  classifyCode: 'MMC',
  classifyName: '买车',
  img: require("../../img/cooperationMall/commonListImgs/01\u4E70\u8F66.jpg")
}, {
  classifyCode: 'MC',
  classifyName: '卖车',
  img: require("../../img/cooperationMall/commonListImgs/02\u5356\u8F66.jpg")
}, {
  classifyCode: 'WYZB',
  classifyName: '招标',
  img: require("../../img/cooperationMall/commonListImgs/03\u62DB\u6807.jpg")
}, {
  classifyCode: 'ZP',
  classifyName: '招聘',
  img: require("../../img/cooperationMall/commonListImgs/04\u62DB\u8058.jpg")
}, {
  classifyCode: 'QZ',
  classifyName: '求职',
  img: require("../../img/cooperationMall/commonListImgs/05\u6C42\u804C.jpg")
}, {
  classifyCode: 'CCZZ',
  classifyName: '招租',
  img: require("../../img/cooperationMall/commonListImgs/06\u62DB\u79DF.jpg")
}, {
  classifyCode: 'CCQZ',
  classifyName: '求租',
  img: require("../../img/cooperationMall/commonListImgs/07\u6C42\u79DF.jpg")
}, {
  classifyCode: 'WYTB',
  classifyName: '投标',
  img: require("../../img/cooperationMall/commonListImgs/08\u6295\u6807.jpg")
}, {
  classifyCode: 'CF',
  classifyName: '产废',
  img: require("../../img/cooperationMall/commonListImgs/09\u4EA7\u5E9F.jpg")
}, {
  classifyCode: 'CCF',
  classifyName: '处废',
  img: require("../../img/cooperationMall/commonListImgs/10\u5904\u5E9F.jpg")
}, {
  classifyCode: 'ZHZX',
  classifyName: '展会资讯',
  img: require("../../img/cooperationMall/commonListImgs/11\u5C55\u4F1A.jpg")
}, {
  classifyCode: 'ZGXG',
  classifyName: '蒸洗罐',
  img: require("../../img/cooperationMall/commonListImgs/12\u84B8\u6D17\u7F50.jpg")
}];
var _default = exports.default = {
  data: data
};