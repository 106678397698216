"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _c("div", {
    staticClass: "cont-06"
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "color-212121 font-36 font-weight-500"
  }, [_vm._v("泄漏检测系统")]), _c("img", {
    staticClass: "margin-top-90",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/06.png")
    }
  }), _c("div", {
    staticClass: "color-6E6E6E font-18 font-weight-300"
  }, [_vm._v("自粘性氢气变色胶带检测表")]), _c("el-row", {
    staticClass: "margin-top-60"
  }, [_c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("性价比高")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("通过从米色到蓝色的可逆颜色变化判断是否发生泄漏，一目了然")])]), _c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("精准定位")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("直接定位氢气泄漏的具体点位，提高现场巡检、检修效率")])])], 1), _c("el-row", [_c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("灵敏度高")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("遇氢气3秒内即可发生变色，15秒内完全变色")])]), _c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("操作便捷")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("自粘性胶带，包裹、缠绕或粘贴在需检漏处即可，易于多点布置")])])], 1), _c("el-row", [_c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("安全环保")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("无电，无热，无腐蚀，耐候行强，不引入额外危险源")])]), _c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("风险预测")]), _c("div", {
    staticClass: "text"
  }, [_vm._v("实现对氢气微泄漏到大泄漏的广量程检测，提高安全管理效率")])])], 1), _c("el-row", [_c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }, [_vm._v("结果可靠")]), _c("div", {
    staticClass: "text",
    staticStyle: {
      "border-bottom": "1px solid #F0F0F1"
    }
  }, [_vm._v("无功耗设计，可有效规避因传感器失效导致的结果不准确的现象")])]), _c("el-col", {
    staticClass: "flex-row-start align-item-center",
    attrs: {
      span: 12
    }
  }, [_c("div", {
    staticClass: "title"
  }), _c("div", {
    staticClass: "text",
    staticStyle: {
      "border-bottom": "1px solid #F0F0F1"
    }
  })])], 1), _vm._m(5), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-60"
  }, _vm._l(_vm.deviceList, function (item, index) {
    return _c("div", {
      staticClass: "device-item flex-col-center align-item-center",
      style: {
        "background-color": item.bgColor
      }
    }, [_c("img", {
      staticClass: "width-44",
      attrs: {
        src: item.url
      }
    }), _c("span", {
      staticClass: "font-20 color-212121 margin-top-25"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "text-line margin-top-10",
      style: {
        background: item.textLineColor
      }
    }), _c("span", {
      staticClass: "font-16 color-555555 margin-top-35"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)], 1)]), _c("appointment", {
    attrs: {
      sourceType: "30"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-555555 font-18 line-height-16 text-left",
    staticStyle: {
      "text-indent": "1em"
    }
  }, [_vm._v("危化镖局致力于为各企业各场景提供多样化的供应链数字化产品工具，助力于提高企业管理和生产制造效率，目前可以提供园区导航、扫码发货、运输GPS监控、无人值守地磅、人员定位、泄露检测等多种数字化产品。")]), _c("div", {
    staticClass: "color-212121 font-36 font-weight-500 margin-top-110"
  }, [_vm._v("智慧导航小程序")]), _c("div", {
    staticClass: "content flex-row-sb align-item-center margin-top-90"
  }, [_c("img", {
    staticClass: "width-350",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/01.png")
    }
  }), _c("div", {
    staticClass: "content-r color-white text-align-left"
  }, [_c("div", {
    staticClass: "font-24"
  }, [_vm._v("可自定义配置的企业级导航软件")]), _c("div", {
    staticClass: "font-16 margin-top-25 line-height-16"
  }, [_vm._v(" 在大型工业园区内百度、高德等公有地图往往不能提供完整的路网，在偏远地区内也存在地图缺失的情况。为帮助大型工业园区及偏远地区的化工企业有效解决司机找路难、提货流程不熟悉等问题，化工宝数科推出了智慧导航产品“领航王”。")]), _c("div", {
    staticClass: "flex-row-start align-item-center font-16 margin-top-60"
  }, [_c("div", {
    staticClass: "lightspot"
  }, [_vm._v("上线快")]), _c("div", {
    staticClass: "font-weight-300 light-text"
  }, [_vm._v("七日内完成部署")])]), _c("div", {
    staticClass: "flex-row-start align-item-center font-16 margin-top-30"
  }, [_c("div", {
    staticClass: "lightspot"
  }, [_vm._v("性价比高")]), _c("div", {
    staticClass: "font-weight-300 light-text"
  }, [_vm._v("自研导航引擎、无需地图测绘，远低于传统定制导航开发价格")])]), _c("div", {
    staticClass: "flex-row-start font-16 margin-top-30"
  }, [_c("div", {
    staticClass: "lightspot"
  }, [_vm._v("功能亮点")]), _c("div", {
    staticClass: "font-weight-300 light-text"
  }, [_vm._v("自定义上传语言，自定义配置道路规则，自行定义仓库坐标、可单独使用也对接企业小程序APP等")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-02",
    staticStyle: {
      "background-color": "#eef2fc"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("div", [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("扫码发货软件")]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-45"
  }, [_c("div", {
    staticClass: "text-circle"
  }, [_vm._v("01")]), _c("div", {
    staticClass: "font-18 color-365ACD margin-left-20"
  }, [_vm._v("司机出示APP中的提货二维码，凭码提货")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("div", {
    staticClass: "text-circle"
  }, [_vm._v("02")]), _c("div", {
    staticClass: "font-18 color-365ACD margin-left-20"
  }, [_vm._v("仓库人员配备扫码设备/PDA，扫码装车")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-25"
  }, [_c("div", {
    staticClass: "text-circle"
  }, [_vm._v("03")]), _c("div", {
    staticClass: "font-18 color-365ACD margin-left-20"
  }, [_vm._v("提供标准ERP，也支持对接企业原有ERP")])])]), _c("img", {
    staticClass: "width-500",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/02.jpg")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-start align-item-center text-left"
  }, [_c("img", {
    staticClass: "radius-10 width-520",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/03.png")
    }
  }), _c("div", {
    staticClass: "text-content"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("运输GPS监控")]), _c("div", {
    staticClass: "margin-top-35 font-18 color-444444 line-height-20"
  }, [_c("p", [_vm._v("1、实现车辆位置、运单信息、运输节点的可视化")]), _c("p", [_vm._v("2、可对车辆异常情况实时监控，如超速、异常停留、送货延迟等")]), _c("p", [_vm._v("3、无需额外安装定位设备即可全程掌控车辆情况")]), _c("p", [_vm._v("4、对比政府监控数据，联网核验人、车的资质信息，拒绝假证")]), _c("p", [_vm._v("5、支持创建目的地电子围栏，自动判断车辆到达目的地")]), _c("p", [_vm._v("6、支持二维码分享车辆及运单的实时动态")]), _c("p", [_vm._v("7、有效防止串货行为，加强运输过程安全管理，提升物流运营效率")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("5G+无人值守地磅")]), _c("ul", {
    staticClass: "margin-top-40 font-18 color-444444 line-height-23"
  }, [_c("li", [_c("span", [_vm._v("无人值守门禁，有任务车辆可自动抬杆")])]), _c("li", [_c("span", [_vm._v("无人值守地磅，自动车牌识别，自动打印磅单并发送电子磅单")])]), _c("li", [_c("span", [_vm._v("称重后才允许出门，防止门卫随意放行")])]), _c("li", [_c("span", [_vm._v("地磅可直接升级，无需更换原地磅")])])])]), _c("img", {
    staticClass: "width-666",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/04.jpg")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-05"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("img", {
    staticClass: "width-610",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/05.jpg")
    }
  }), _c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("人员定位系统")]), _c("ul", {
    staticClass: "margin-top-30 font-18 color-444444 line-height-20"
  }, [_c("li", {
    staticClass: "flex-row-start",
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c("span", {
    staticClass: "color-3269F6 flex-shrink-0"
  }, [_vm._v("精准定位：")]), _c("span", [_vm._v("复杂多径环境中定位精度可达10CM")])]), _c("li", {
    staticClass: "flex-row-start flex-nowarp",
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c("span", {
    staticClass: "color-3269F6 flex-shrink-0"
  }, [_vm._v("事前警告：")]), _c("span", [_vm._v("发生位置异常时，手环立刻震动并有文字提示，同时后台报警")])]), _c("li", {
    staticClass: "flex-row-start",
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c("span", {
    staticClass: "color-3269F6 flex-shrink-0"
  }, [_vm._v("活动侦测：")]), _c("span", [_vm._v("出现任何活动的人员、动物、设备，后台报警")])]), _c("li", {
    staticClass: "flex-row-start",
    staticStyle: {
      "flex-wrap": "nowrap"
    }
  }, [_c("span", {
    staticClass: "color-3269F6 flex-shrink-0"
  }, [_vm._v("电子围栏：")]), _c("span", [_vm._v("可以分组、分人设置临时或者永久电子围栏")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-center align-item-center margin-top-90"
  }, [_c("img", {
    staticClass: "width-186",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/chain/07.jpg")
    }
  }), _c("span", {
    staticClass: "font-18 color-6E6E6E margin-top-25"
  }, [_vm._v("VOCs气体泄漏监测器")])]);
}];
render._withStripped = true;