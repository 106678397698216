"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "aboutus-con",
    attrs: {
      id: "aboutus"
    }
  }, [_c("div", {
    staticClass: "aboutus-banner"
  }, [_c("img", {
    staticClass: "height-516",
    attrs: {
      src: require("../../assets/static/img/aboutus/banner-01.jpg")
    }
  }), _c("div", {
    ref: "datum",
    staticClass: "datum flex-row-center"
  }, [_c("div", {
    staticClass: "felx-row-between width-1280"
  }, _vm._l(_vm.companyData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "text-center flex-col-center"
    }, [_c("div", {
      staticClass: "font-36 color-2A559D"
    }, [_vm._v(_vm._s(item.num))]), _c("div", {
      staticClass: "font-16 color-555555"
    }, [_vm._v(_vm._s(item.name))])]);
  }), 0)])]), _c("div", {
    staticClass: "aboutus-main"
  }, [_c("div", {
    ref: "introduce",
    staticClass: "introduce flex-row-center"
  }, [_c("img", {
    staticClass: "introduce-img",
    attrs: {
      src: require("../../assets/static/img/aboutus/01.jpg"),
      alt: ""
    }
  }), _vm._m(0)]), _c("div", {
    ref: "culture",
    staticClass: "culture flex-row-center align-item-start"
  }, [_vm._m(1), _vm._m(2)]), _c("div", {
    ref: "honor",
    staticClass: "honor"
  }, [_vm._m(3), _c("div", {
    staticClass: "main-item"
  }, [_c("swiper", {
    attrs: {
      listData: _vm.honorList
    }
  })], 1)]), _c("div", {
    ref: "company",
    staticClass: "cooperative"
  }, [_vm._m(4), _vm._m(5)]), _c("div", {
    staticClass: "recruit"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-white"
  }, [_vm._v("招贤纳士")]), _c("div", {
    staticClass: "color-white font-16 margin-top-30 line-height-20"
  }, [_vm._v(" 我们有运营、有产品、有技术、有市场推广等部门，你在其他互联网公司所掌握的技能在这里不用担心没有用武之地，你想学习的互联网知识技能也都有前辈指导！至于行业知识？不用担心上手难度，人生难免会有第一次，你要做的是和公司一起成长！ ")]), _c("div", {
    staticClass: "more-btn font-14",
    on: {
      click: _vm.toPage
    }
  }, [_vm._v("了解更多")])]), _c("img", {
    staticClass: "recruit-img",
    attrs: {
      src: require("../../assets/static/img/aboutus/14.jpg"),
      alt: ""
    }
  })])]), _c("div", {
    ref: "contact",
    staticClass: "contact"
  }, [_vm._m(6)])]), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-item text-left"
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("公司简介")]), _c("div", {
    staticClass: "color-555555 font-16 margin-top-30 line-height-16"
  }, [_c("p", [_vm._v("漳州市万物通信息科技有限公司是福建快悠悠信息科技有限公司的品牌公司，致力研究运输管理系统及仓储管理系统，为危化镖局的信息化、流程化、规范化提供有力的技术支持。")]), _c("p", [_vm._v("福建快悠悠信息科技有限公司是一家专注化工物流行业的交易、物流、仓储以及线上线下延伸服务的专业解决方案供应商。")]), _c("p", [_vm._v("“危化镖局”作为化工供应链一站式综合信息服务平台，以化工行业独有的物流管理系统、仓储管理系统，一站式交易＆信息平台三大主体功能，专业为化工企业、货运司机以及下游工厂提供一站式综合供应链服务。致力于建立辐射全国的危化配送网络，搭建信息共享平台，形成全国统一服务标准，为有资质用户提供撮合等信息合作。 ")]), _c("p", [_vm._v("福建快悠悠信息科技有限公司专注解决化工供应链中的痛点问题，推出各项行业产品以提供信息化、数据化、流程化、规范化等服务，着手打破三大市场的信息孤岛，以提供整体化供应链解决方案。")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "text-left"
  }, [_c("div", {
    staticClass: "color-212121 font-36"
  }, [_vm._v("公司理念")]), _c("div", {
    staticClass: "font-18 color-666666"
  }, [_vm._v("Company philosophy")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "flex-row-start text-left align-item-start"
  }, [_c("span", {
    staticClass: "font-20 color-777777 width-120"
  }, [_vm._v("使命")]), _c("span", {
    staticClass: "font-20 color-3269F6 width-640"
  }, [_vm._v("化工供应链一站式综合服务平台")])]), _c("div", {
    staticClass: "flex-row-start text-left align-item-start"
  }, [_c("span", {
    staticClass: "font-20 color-777777 width-120"
  }, [_vm._v("愿景")]), _c("span", {
    staticClass: "font-20 color-212121 width-640"
  }, [_vm._v("致力于为化工行业提供专业、安全、便捷、高效、先进的全方位增值服务")])]), _c("div", {
    staticClass: "flex-row-start text-left align-item-start"
  }, [_c("span", {
    staticClass: "font-20 color-777777 width-120"
  }, [_vm._v("价值观")]), _c("span", {
    staticClass: "font-20 color-212121 width-640"
  }, [_c("p", [_vm._v("客户：成就客户，创造价值；卓越：追求极致，不断超越；")]), _c("p", [_vm._v("诚信：实事求是，说到做到；责任：勤勉敬业，对结果导向；")]), _c("p", [_vm._v("尊重：平等以待，包容差异。")])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "color-212121 font-36 text-align-center"
  }, [_c("div", [_vm._v("公司荣誉")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "color-212121 font-36 text-align-center"
  }, [_c("div", [_vm._v("合作企业")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "content flex-row-center"
  }, [_c("img", {
    staticClass: "company-img mouse-pointer width-1280",
    attrs: {
      src: require("../../assets/static/img/aboutus/11.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "contact-main"
  }, [_c("div", {
    staticClass: "contact-txt text-align-left"
  }, [_c("div", {
    staticClass: "contact-title"
  }, [_c("p", {
    staticClass: "font-30 text-white font-weight-500"
  }, [_vm._v("联系我们")]), _c("p", {
    staticClass: "font-14 margin-top-10",
    staticStyle: {
      color: "#909399"
    }
  }, [_vm._v("CONTACT US")])]), _c("div", {
    staticClass: "contact-company"
  }, [_c("p", {
    staticClass: "font-16"
  }, [_vm._v("漳州市万物通信息科技有限公司")]), _c("p", {
    staticClass: "font-16"
  }, [_vm._v("福建快悠悠信息科技有限公司")])]), _c("div", {
    staticClass: "contact-address"
  }, [_c("p", {
    staticClass: "font-12"
  }, [_vm._v("电话：400-886-3156")]), _c("p", {
    staticClass: "font-12"
  }, [_vm._v("邮箱：1436898740@qq.com")]), _c("p", {
    staticClass: "font-12"
  }, [_vm._v("QQ：1436898740")]), _c("p", {
    staticClass: "font-12"
  }, [_vm._v("地址(厦门)：厦门市湖里区华美创意空间C1楼5N")]), _c("p", {
    staticClass: "font-12"
  }, [_vm._v("地址(漳州)：芗城区新华北路33号嘉华大厦三楼")])])]), _c("img", {
    staticClass: "contact-img",
    attrs: {
      src: require("../../assets/static/img/aboutus/13.jpg"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;