"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.colunm.colunmName == "推荐" && _vm.isSearch == "false" && _vm.carouselData.length > 0 ? _c("div", {
    staticClass: "news_carousel"
  }, [_c("el-carousel", {
    attrs: {
      height: "303px",
      interval: 5000,
      arrow: "never",
      trigger: "click"
    }
  }, _vm._l(_vm.carouselData, function (item, index) {
    return _c("el-carousel-item", {
      key: index
    }, [_c("a", {
      attrs: {
        target: "_blank",
        href: item.ordinaryJumpUrl
      }
    }, [_c("div", {
      staticStyle: {
        width: "100%",
        height: "100%",
        position: "relative"
      }
    }, [_c("img", {
      attrs: {
        width: "100%",
        height: "100%",
        src: item.advertOssUrl,
        alt: ""
      }
    }), _c("div", {
      staticStyle: {
        width: "100%",
        height: "55px",
        position: "absolute",
        bottom: "0",
        "background-color": "rgba(0, 0, 0, 0.5)",
        "font-size": "20px",
        color: "#fff",
        "text-align": "left",
        padding: "6px 23px"
      }
    }, [_vm._v(" " + _vm._s(item.advertWords) + " ")])])])]);
  }), 1)], 1) : _vm._e(), _c("div", {
    staticClass: "news_list"
  }, [_c("div", {
    staticClass: "list_con"
  }, [_c("ul", [_vm._t("listLi", function () {
    return [_vm._l(_vm.listData.content, function (item, index) {
      return _c("li", {
        key: "list-" + index,
        staticClass: "list_noimg"
      }, [item.articleCoverStyle == "ARTICLE_COVER_NONE" ? _c("router-link", {
        directives: [{
          name: "track-event",
          rawName: "v-track-event",
          value: {
            category: "资讯列表",
            action: "点击",
            opt_label: item.articleTitle
          },
          expression: "{category:'资讯列表', action:'点击', opt_label:item.articleTitle}"
        }],
        staticClass: "text-left flex-row-start",
        staticStyle: {
          width: "100%"
        },
        attrs: {
          target: "_blank",
          to: {
            name: "newslist",
            query: {
              isDetail: true,
              isSearch: false,
              cmmid: _vm.colunm.cmmId,
              searchVal: "",
              ammid: item.ammId,
              menuActive: _vm.menuActive
            }
          }
        }
      }, [_c("div", {
        staticClass: "list_oneimg_img"
      }, [_c("img", {
        attrs: {
          src: require("../../assets/static/img/news/02.jpg")
        }
      })]), _c("div", {
        staticClass: "list_oneimg_con"
      }, [_c("span", {
        staticClass: "list_heading text-left",
        domProps: {
          innerHTML: _vm._s(item.articleTitle)
        }
      }), _c("span", {
        staticClass: "list_desc text-left"
      }, [_vm._v(_vm._s(item.articleAbstract))]), _c("span", {
        staticClass: "list_subheading"
      }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读"), _c("span", [_vm._v(_vm._s(item.articleReleaseTime))])])])]) : _vm._e(), item.articleCoverStyle == "ARTICLE_COVER_ONE" ? _c("router-link", {
        directives: [{
          name: "track-event",
          rawName: "v-track-event",
          value: {
            category: "资讯列表",
            action: "点击",
            opt_label: item.articleTitle
          },
          expression: "{category:'资讯列表', action:'点击', opt_label:item.articleTitle}"
        }],
        staticClass: "text-left flex-row-alignc",
        staticStyle: {
          width: "100%"
        },
        attrs: {
          target: "_blank",
          to: {
            name: "newslist",
            query: {
              isDetail: true,
              isSearch: false,
              cmmid: _vm.colunm.cmmId,
              searchVal: "",
              ammid: item.ammId,
              menuActive: _vm.menuActive
            }
          }
        }
      }, [_c("div", {
        staticClass: "list_oneimg_img"
      }, [_c("img", {
        attrs: {
          src: item.pictureOssUrls
        }
      })]), _c("div", {
        staticClass: "list_oneimg_con"
      }, [_c("span", {
        staticClass: "list_heading text-left",
        domProps: {
          innerHTML: _vm._s(item.articleTitle)
        }
      }), _c("span", {
        staticClass: "list_desc text-left"
      }, [_vm._v(_vm._s(item.articleAbstract))]), _c("span", {
        staticClass: "list_subheading"
      }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读"), _c("span", [_vm._v(_vm._s(item.articleReleaseTime))])])])]) : _vm._e(), item.articleCoverStyle == "ARTICLE_COVER_THREE" ? _c("router-link", {
        directives: [{
          name: "track-event",
          rawName: "v-track-event",
          value: {
            category: "资讯列表",
            action: "点击",
            opt_label: item.articleTitle
          },
          expression: "{category:'资讯列表', action:'点击', opt_label:item.articleTitle}"
        }],
        staticClass: "text-left flex-row-alignc",
        staticStyle: {
          width: "100%"
        },
        attrs: {
          target: "_blank",
          to: {
            name: "newslist",
            query: {
              isDetail: true,
              isSearch: false,
              cmmid: _vm.colunm.cmmId,
              searchVal: "",
              ammid: item.ammId,
              menuActive: _vm.menuActive
            }
          }
        }
      }, [_c("div", {
        staticClass: "list_oneimg_img"
      }, [_c("img", {
        attrs: {
          src: item.pictureOssUrls[0]
        }
      })]), _c("div", {
        staticClass: "list_oneimg_con"
      }, [_c("span", {
        staticClass: "list_heading text-left",
        domProps: {
          innerHTML: _vm._s(item.articleTitle)
        }
      }), _c("span", {
        staticClass: "list_desc text-left"
      }, [_vm._v(_vm._s(item.articleAbstract))]), _c("span", {
        staticClass: "list_subheading"
      }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读"), _c("span", [_vm._v(_vm._s(item.articleReleaseTime))])])])]) : _vm._e()], 1);
    })];
  }), _c("div", {
    staticClass: "news_more",
    staticStyle: {
      width: "100%"
    }
  }, [_vm.isNodata == true ? _c("div", {
    staticClass: "nodata"
  }, [_c("img", {
    staticClass: "width-300",
    attrs: {
      src: require("../../assets/static/img/no_data.png"),
      alt: ""
    }
  }), _c("div", [_vm._v("暂无数据~")])]) : _vm.isNoMoredata == true ? _c("div", {
    staticClass: "nodata"
  }, [_c("div", [_vm._v("没有更多数据了~")])]) : _vm._t("listFooterBtn", function () {
    return [[_c("el-button", {
      staticClass: "hasdata",
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.getMoreList();
        }
      }
    }, [_vm._v("阅读更多资讯")])]];
  })], 2)], 2)])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;