"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = _interopRequireDefault(require("../../assets/static/js/utils/util.js"));
var _cookie = require("@/assets/static/js/utils/cookie.js");
var _relativeTime = require("../../assets/static/js/utils/relativeTime");
var _default = exports.default = {
  props: {
    sourceData: Array,
    advertiseData: Object,
    sortConfig: Object
  },
  data: function data() {
    return {
      inter: ''
    };
  },
  inject: ['reload'],
  computed: {
    listData: function listData() {
      this.sourceData.filter(function (item) {
        item.vsSetOutTime = _util.default.timeToDate(item.vsSetOutTime, 'YY-MM-dd');
        item.releaseTime = _util.default.timeToString(item.releaseTime);
        item.joinedStart = _util.default.joinCityAndDistrict(item.orginCity, item.orginDistrict, '-');
        item.joinedEnd = _util.default.joinCityAndDistrict(item.destinationCity, item.destinationDistrict, '-');
        item.joinedCarInfo = _util.default.joinCarTypeAndWeight(item.vehicleType, item.vehicleLoadWeight);
      });
      return this.sourceData;
    }
  },
  created: function created() {
    //  console.log('sourceData', this.sourceData);
  },
  methods: {
    formatDate: _relativeTime.formatDate,
    tableRowStyle: function tableRowStyle(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 4) {
        //第3列字体颜色
        return 'color: #FD9E31;!important;';
      }
      if (columnIndex === 6) {
        return 'border-right:1px solid #DDEAFA !important';
      }
      if (columnIndex === 0) {
        return 'border-left: 1px solid #DDEAFA !important;';
      }
    },
    // 跳转详情
    clickToDetail: function clickToDetail(vsid) {
      var _this = this;
      // 暂且禁止访问详情
      // return
      var that = this;
      var userStorage = (0, _cookie.getCookie)('user');
      if (!userStorage) {
        that.$root.loginscene = 'VS_DETAIL'; //注册登录场景
        that.$root.loginsceneid = "".concat(vsid);
        that.$root.dialogFormVisible = true;
        this.inter = setInterval(function () {
          userStorage = (0, _cookie.getCookie)('user');
          if (userStorage) {
            document.getElementById("".concat(vsid)).click();
            clearInterval(_this.inter);
          }
        }, 500);
      } else {
        // 使用vue路由机制,将前缀保留
        var routeUrl = this.$router.resolve({
          path: "/cardetail",
          query: {
            vsid: vsid
          }
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    //排序
    clickToSortByVsSetOutTime: function clickToSortByVsSetOutTime() {
      this.$emit("sortvtime");
    },
    clickToSortByReleaseTime: function clickToSortByReleaseTime() {
      this.$emit("sortrtime");
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.inter) {
      clearInterval(this.inter);
    }
  }
};