"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _vm._m(0), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("罐区槽车分装作业")]), _c("ul", [_vm._m(1), _c("li", {
    staticClass: "flex-row-sb align-item-center margin-top-30"
  }, _vm._l(_vm.safeRules, function (item, index) {
    return _c("span", {
      staticClass: "safe-item color-3269F6 font-18"
    }, [_vm._v(_vm._s(item))]);
  }), 0), _vm._m(2), _vm._m(3)])])]), _vm._m(4), _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("车辆运输作业")]), _c("div", {
    staticClass: "flex-row-sb align-item-center flex-warp margin-top-110"
  }, _vm._l(_vm.tranStep, function (item, index) {
    return _c("div", {
      staticClass: "tran-step text-left"
    }, [_c("div", {
      staticClass: "flex-row-start align-item-center"
    }, [_c("div", {
      staticClass: "margin-right-20 font-50 color-3269F6"
    }, [_vm._v("0" + _vm._s(index + 1))]), _c("div", [_c("div", {
      staticClass: "color-212121 font-20"
    }, [_vm._v(_vm._s(item.title))]), _c("div", {
      staticClass: "color-6E6E6E font-16"
    }, [_vm._v(_vm._s(item.desc))])])]), _c("img", {
      staticClass: "radius-10 width-600",
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    })]);
  }), 0)])]), _c("appointment", {
    attrs: {
      sourceType: "20"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-555555 font-18 line-height-16"
  }, [_vm._v(" 危化镖局通过“智能化、信息化、标准化”三位一体，为鑫展旺化工物流园区提供了一款集“安全管控、订单作业、财务管理以及成本分析等为一体化”的综合管理软件，为企业提供高效、安全的全流程管控，杜绝安全隐患，实现经济效益和生命财产安全双保障的效果。")]), _c("div", {
    staticClass: "font-36 color-212121 margin-top-110 font-weight-500"
  }, [_vm._v("危化品入库流程")]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/logisticsPark/01.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "font-36 color-212121 margin-top-110 font-weight-500"
  }, [_vm._v("危化品出库流程")]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/logisticsPark/02.png"),
      alt: ""
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "font-20 color-3269F6 text-align-left margin-top-110",
    staticStyle: {
      "list-style-type": "square",
      "margin-left": "1em"
    }
  }, [_c("span", {
    staticClass: "color-212121"
  }, [_vm._v("司机安全驾驶严格遵守行车守则")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "font-20 color-3269F6 text-align-left margin-top-110",
    staticStyle: {
      "list-style-type": "square",
      "margin-left": "1em"
    }
  }, [_c("span", {
    staticClass: "color-212121"
  }, [_vm._v("行车前货物绑扎和加固标准")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("li", {
    staticClass: "flex-row-sb align-item-center margin-top-30"
  }, [_c("img", {
    staticClass: "width-1280 bind-item",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/logisticsPark/03.png")
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("罐区桶装分装作业")]), _vm._v(" "), _c("img", {
    staticClass: "width-1280 margin-top-70",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/logisticsPark/04.png")
    }
  })])]);
}];
render._withStripped = true;