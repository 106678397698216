"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.vm = exports.default = void 0;
require("E:\\vescort-platform-pc-website\\node_modules\\core-js\\modules\\es6.array.iterator.js");
require("E:\\vescort-platform-pc-website\\node_modules\\core-js\\modules\\es6.promise.js");
require("E:\\vescort-platform-pc-website\\node_modules\\core-js\\modules\\es6.object.assign.js");
require("E:\\vescort-platform-pc-website\\node_modules\\core-js\\modules\\es7.promise.finally.js");
var _vue = _interopRequireDefault(require("vue"));
var _App = _interopRequireDefault(require("./App.vue"));
var _router = _interopRequireDefault(require("./router"));
var _store = _interopRequireDefault(require("./store"));
var _vueResource = _interopRequireDefault(require("vue-resource"));
var _jquery = _interopRequireDefault(require("jquery"));
var _elementUi = _interopRequireDefault(require("element-ui"));
var _vueAwesomeSwiper = _interopRequireDefault(require("vue-awesome-swiper"));
require("element-ui/lib/theme-chalk/index.css");
require("bootstrap");
require("bootstrap/dist/css/bootstrap.css");
require("bootstrap/dist/js/bootstrap.min.js");
require("./assets/static/css/common.css");
require("./assets/static/css/public.css");
require("swiper/dist/css/swiper.min.css");
require("./assets/static/font_icon/iconfont");
var _vueQriously = _interopRequireDefault(require("vue-qriously"));
var _particles = _interopRequireDefault(require("particles.js"));
var _vueClipboard = _interopRequireDefault(require("vue-clipboard2"));
var _vshare = _interopRequireDefault(require("vshare"));
var _vueSocialShare = _interopRequireDefault(require("vue-social-share"));
var _vueMeta = _interopRequireDefault(require("vue-meta"));
var _vueBa = _interopRequireDefault(require("vue-ba"));
require("./assets/static/js/utils/flexible.js");
// 请求插件

//通用样式
//公共样式

// import 'vue-social-share/dist/client.css'; // socialShare.min.css在index.html中有引入

_vue.default.use(_vueQriously.default);
//粒子动画插件
_vue.default.use(_particles.default);
//复制粘贴插件
_vue.default.use(_vueClipboard.default);
_vue.default.use(_vshare.default);
_vue.default.use(_vueSocialShare.default);
_vue.default.use(_vueMeta.default);
_vue.default.use(_vueBa.default, '685d3c9c4861a470b8068a28e4e20051');
_vue.default.config.productionTip = false;
_vue.default.prototype.$getRouter = function (index) {
  this.$router.push(index);
};
var _hmt = window._hmt || [];
_router.default.beforeEach(function (to, from, next) {
  if (to.meta.metaInfo) {
    _store.default.commit("CAHNGE_META_INFO", to.meta.metaInfo);
  }
  if (to.path) {
    _hmt.push(['_trackPageview', '/#' + to.fullPath]);
  }
  if (document.getElementById('mainlayout_content') && !to.query.to) {
    document.getElementById('mainlayout_content').scrollTop = 0;
  }
  next();
});
_router.default.afterEach(function (to) {
  if (to.meta.hiddenZooS) {
    if (document.getElementById('LRdiv0')) {
      document.getElementById('LRdiv0').style.display = 'none';
    } else {
      setTimeout(function () {
        document.getElementById('LRdiv0').style.display = 'none';
      }, 1000);
    }
    if (document.getElementById('LRdiv1')) {
      document.getElementById('LRdiv1').style.display = 'none';
    } else {
      setTimeout(function () {
        document.getElementById('LRdiv1').style.display = 'none';
      }, 1000);
    }
    if (document.getElementById('LRdiv2')) {
      document.getElementById('LRdiv2').style.display = 'none';
    } else {
      setInterval(function () {
        if (document.getElementById('LRdiv2')) {
          document.getElementById('LRdiv2').style.display = 'none';
        }
      }, 1000);
      // setTimeout(() => {document.getElementById('LRdiv2').style.display = 'none'},1500)
    }
    if (document.getElementById('LRdiv3')) {
      document.getElementById('LRdiv3').style.display = 'none';
    } else {
      setTimeout(function () {
        document.getElementById('LRdiv3').style.display = 'none';
      }, 1000);
    }
  }
});
_vue.default.prototype.mainHeight = '100vh';
_vue.default.prototype.resetSetItem = function (key, newVal) {
  if (key === 'user') {
    // 创建一个StorageEvent事件
    var newStorageEvent = document.createEvent('StorageEvent');
    var storage = {
      setItem: function setItem(k, val) {
        sessionStorage.setItem(k, val);

        // 初始化创建的事件
        newStorageEvent.initStorageEvent('setItem', false, false, k, null, val, null, null);

        // 派发对象
        window.dispatchEvent(newStorageEvent);
      }
    };
    return storage.setItem(key, newVal);
  }
};

// Vue.prototype.$ = $;
_vue.default.use(_elementUi.default);
_vue.default.use(_vueResource.default);
_vue.default.use(_vueAwesomeSwiper.default);
_vue.default.http.options.xhr = {
  withCredentials: true
};
var vm = exports.vm = new _vue.default({
  router: _router.default,
  store: _store.default,
  VueResource: _vueResource.default,
  metaInfo: function metaInfo() {
    return {
      title: this.$store.state.metaInfo.title,
      meta: [{
        name: 'keywords',
        content: this.$store.state.metaInfo.keywords
      }, {
        name: 'description',
        content: this.$store.state.metaInfo.description
      }]
    };
  },
  render: function render(h) {
    return h(_App.default);
  },
  mounted: function mounted() {
    document.dispatchEvent(new Event('render-active'));
  },
  data: function data() {
    return {
      h5BaseUrl: process.env.VUE_APP_H5_BASE_API,
      baseUrl: process.env.VUE_APP_BASE_API,
      imgUrl: process.env.VUE_APP_IMG_URL,
      recordTxt: window.location.href.indexOf('fjkuu') != -1 ? '闽ICP备17024842号-1' : process.env.VUE_APP_RECORD_TXT,
      // recordTxt:  process.env.VUE_APP_RECORD_TXT,
      token: '',
      showAcitivity: false,
      adHeight: true,
      //广告栏高度加到导航栏高度
      mShow: true,
      pageSize: 15,
      dialogFormVisible: false,
      //登录弹窗标记符
      dialogRegVisible: false,
      //注册弹窗标记符
      innerVisible: false,
      //用户服务协议弹窗标记符
      showPush: false,
      //弹窗标记符
      isLogin: false,
      loginscene: '',
      //注册登录场景
      loginsceneid: '',
      //注册登录场景id
      comAvatarArr: [require('@/assets/static/img/commonAvatar/avatar_ico1.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico2.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico3.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico4.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico5.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico6.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico7.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico8.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico9.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico10.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico11.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico12.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico13.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico14.jpg'), require('@/assets/static/img/commonAvatar/avatar_ico15.jpg')] // 通用头像
    };
  }
}).$mount('#app');
var _default = exports.default = vm;