"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isDown,
      expression: "isDown"
    }],
    staticClass: "down_guide"
  }, [_vm._m(0)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "guide_con"
  }, [_c("div", {
    staticClass: "chevron"
  }), _c("div", {
    staticClass: "chevron"
  }), _c("div", {
    staticClass: "chevron"
  })]);
}];
render._withStripped = true;