"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "n-content",
    attrs: {
      id: "newslist"
    }
  }, [_c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "head-content"
  }, [_vm._m(0), _c("div", {
    staticClass: "center flex-row-center"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "请输入你想要搜索的内容"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.tosearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }, [_c("div", {
    staticClass: "flex-row-center",
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    slot: "append"
  }, [_c("a", {
    staticClass: "rl-white mouse-pointer",
    on: {
      click: _vm.tosearch
    }
  }, [_vm._v(" 搜索一下 ")])])])], 1)])]), _c("div", {
    staticClass: "body",
    style: _vm.isSearch == "true" ? "padding-top:84.33px;" : ""
  }, [_c("div", {
    staticClass: "body-content flex-row-justc"
  }, [_c("div", {
    staticClass: "n-center"
  }, [_vm.menuData && _vm.isDetail != "true" ? _c("div", {
    staticClass: "menu flex-row-start align-item-center flex-warp font-18 color-333333 padding-left-30 padding-right-30"
  }, _vm._l(_vm.menuData, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "menu-item flex-row-center margin-right-35",
      class: [index == _vm.menuActive ? "active-menu-item" : ""],
      on: {
        click: function click($event) {
          return _vm.tabChange(index);
        }
      }
    }, [_vm._v(_vm._s(item.colunmName))]);
  }), 0) : _vm._e(), _vm.carouselData && _vm.menuData && _vm.isDetail != "true" ? _c("list", {
    attrs: {
      colunm: _vm.menuData[_vm.menuActive],
      carouselData: _vm.carouselData,
      isNodata: _vm.isNodata,
      isNoMoredata: _vm.isNoMoredata,
      listData: _vm.listData,
      menuActive: _vm.menuActive
    }
  }) : _vm._e(), _vm.isDetail == "true" && _vm.menuData ? _c("detail", {
    ref: "isDetail",
    attrs: {
      colunmName: _vm.menuData[_vm.menuActive].colunmName,
      side_bind: "newslist"
    },
    on: {
      relatedData: function relatedData(res) {
        _vm.relatedData = res;
      }
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "n-right"
  }, [_vm.isDetail != "true" ? _c("div", {
    staticClass: "n-right_con",
    staticStyle: {
      width: "100%",
      margin: "0 auto"
    }
  }, [_vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "one-" + index
    }, [item.showPosition == "RIGHT_ONE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan",
      staticStyle: {
        "margin-bottom": "34px"
      }
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "two-" + index
    }, [item.showPosition == "RIGHT_TWO" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "three-" + index
    }, [item.showPosition == "RIGHT_THREE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _c("div", {
    staticClass: "news_right_box news_right_list"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "flex-row-sb align-item-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "padding-left-20 divider flex-row-start align-item-center"
  }, [_vm._v("热门资讯")]), _c("router-link", {
    staticClass: "hotspot_more flex-row-center align-item-center mouse-pointer",
    attrs: {
      target: "_blank",
      to: {
        path: "/newslist",
        query: {
          isSearch: false,
          searchVal: "",
          isDetail: false
        }
      }
    }
  }, [_c("img", {
    staticClass: "width-14 margin-right-5",
    attrs: {
      src: require("../../assets/static/img/news/03.png")
    }
  }), _vm._v(" 换一批 ")])], 1), _vm._l(_vm.hotspotData, function (hitem, hindex) {
    return _c("div", {
      key: hindex,
      staticClass: "item minortint flex-row-alignc"
    }, [_c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "最新资讯列表",
          action: "点击",
          opt_label: hitem.articleTitle
        },
        expression: "{category:'最新资讯列表', action:'点击', opt_label:hitem.articleTitle}"
      }],
      staticClass: "hotspot_link flex-row-alignc",
      attrs: {
        target: "_blank",
        to: {
          path: "/newslist",
          query: {
            menuActive: _vm.menuActive,
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: hitem.ammId
          }
        }
      }
    }, [_c("span", {
      staticClass: "hotspot_title ellipsis mouse-pointer"
    }, [_vm._v(_vm._s(hitem.articleTitle))])])], 1);
  })], 2)], 1), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "four-" + index
    }, [item.showPosition == "RIGHT_FOUR" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: item.ordinaryJumpUrl ? "_blank" : "",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  })], 2) : _c("div", {
    staticClass: "isDetail n-right_con",
    staticStyle: {
      width: "100%",
      margin: "0 auto"
    }
  }, [_c("el-card", {
    staticClass: "box-card width-370 padding-bottom-40"
  }, [_c("div", {
    staticClass: "header",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "padding-left-20 divider flex-row-start align-item-center"
  }, [_vm._v("热门资讯")]), _c("div", {
    staticClass: "more",
    on: {
      click: _vm.refreshReco
    }
  }, [_c("img", {
    staticClass: "width-14 margin-right-5",
    attrs: {
      src: require("../../assets/static/img/news/03.png")
    }
  }), _vm._v("换一批")])]), _vm._l(_vm.relatedData, function (hitem, hindex) {
    return _c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "资讯推荐列表",
          action: "点击",
          opt_label: hitem.articleTitle
        },
        expression: "{category:'资讯推荐列表', action:'点击', opt_label:hitem.articleTitle}"
      }],
      key: hindex,
      staticClass: "reco-item flex-row-sb margin-top-30 padding-left-20 padding-right-20",
      attrs: {
        target: "_blank",
        to: {
          name: "newslist",
          query: {
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: hitem.ammId
          }
        }
      }
    }, [hitem.articleCoverStyle == "ARTICLE_COVER_NONE" ? _c("img", {
      staticClass: "radius-6 width-150 height-100",
      attrs: {
        src: require("../../assets/static/img/news/02.jpg")
      }
    }) : hitem.articleCoverStyle == "ARTICLE_COVER_ONE" ? _c("img", {
      staticClass: "radius-6 width-150 height-100",
      attrs: {
        src: hitem.pictureOssUrls
      }
    }) : hitem.articleCoverStyle == "ARTICLE_COVER_THREE" ? _c("img", {
      staticClass: "radius-6 width-150 height-100",
      attrs: {
        src: hitem.pictureOssUrls[0]
      }
    }) : _vm._e(), _c("div", {
      staticClass: "flex-col-sb flex-1 text-left margin-left-15"
    }, [_c("div", {
      staticClass: "font-18 color-444444"
    }, [_vm._v(_vm._s(hitem.articleTitle))]), _c("div", {
      staticClass: "flex-row-sb align-item-center font-12 color-B2B2B2"
    }, [_c("span", [_vm._v(_vm._s(hitem.chickTotalNum == null ? "0" : hitem.chickTotalNum) + "人阅读")]), _c("span", [_vm._v(_vm._s(hitem.articleReleaseTime))])])])]);
  })], 2)], 1)])])]), _c("footNav"), _c("sideNav", {
    attrs: {
      side_bind: "newslist"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "scroll_left"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/news/01.png"),
      alt: ""
    }
  })]);
}];
render._withStripped = true;