"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("六大核心功能介绍")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25 line-height-16"
  }, [_vm._v(" 想科学降低运费？想快速提升物流服务水平？选择危化镖局TMS！智能优化引擎，强大运力池，结合专业运输控制塔团队，实现可预期、可量化、可持续的成本节省和服务水平改善。已有多家物流企业实现20—30%运输费用节省。 ")]), _c("div", {
    staticClass: "flex-row-sb margin-top-50 flex-warp"
  }, _vm._l(_vm.functionList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-center margin-top-25"
    }, [_c("img", {
      staticClass: "width-72",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-22 color-212121 margin-top-35"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-25"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("多种运输方案服务及合作")]), _c("div", {
    staticClass: "flex-row-sb margin-top-110"
  }, _vm._l(_vm.projectList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-start align-item-center"
    }, [_c("img", {
      staticClass: "width-177",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-20 color-2A559D margin-top-40"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-35 text-align-left"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _c("appointment-two", {
    attrs: {
      sourceType: 80
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;