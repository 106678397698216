import { render, staticRenderFns } from "./shader-wave.vue?vue&type=template&id=3e34a972"
import script from "./shader-wave.vue?vue&type=script&lang=js"
export * from "./shader-wave.vue?vue&type=script&lang=js"
import style0 from "./shader-wave.vue?vue&type=style&index=0&id=3e34a972&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\vescort-platform-pc-website\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3e34a972')) {
      api.createRecord('3e34a972', component.options)
    } else {
      api.reload('3e34a972', component.options)
    }
    module.hot.accept("./shader-wave.vue?vue&type=template&id=3e34a972", function () {
      api.rerender('3e34a972', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/shader/shader-wave.vue"
export default component.exports