"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _vm._m(0), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("六大特色功能介绍")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25 line-height-23"
  }, [_vm._v(" 大中型化工企业都在使用的WMS仓储管理系统，能有效提升30%仓库空间流转率，全程无纸化作业，实现多品多库，单品单库，多库存同步共享。管理者便捷可视化管理化工园区，高效协同方案，降低企业成本，提高客户的满意度，从而提升企业的核心竞争力。")]), _c("div", {
    staticClass: "flex-row-sb margin-top-50 flex-warp"
  }, _vm._l(_vm.functionList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-center align-item-center margin-top-25"
    }, [_c("img", {
      staticClass: "width-72",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-22 color-212121 margin-top-35"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-25"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("多种仓储服务及合作")]), _c("div", {
    staticClass: "flex-row-sb margin-top-110"
  }, _vm._l(_vm.projectList, function (item, index) {
    return _c("div", {
      staticClass: "item flex-col-start align-item-center"
    }, [_c("img", {
      staticClass: "width-180",
      attrs: {
        src: item.imgUrl
      }
    }), _c("span", {
      staticClass: "font-22 color-2A559D margin-top-40"
    }, [_vm._v(_vm._s(item.title))]), _c("span", {
      staticClass: "font-16 color-555555 margin-top-35 text-align-left"
    }, [_vm._v(_vm._s(item.desc))])]);
  }), 0)])]), _vm._m(1), _vm._m(2), _c("appointment-two", {
    attrs: {
      sourceType: "90"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-01"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("标准化流程")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25"
  }, [_vm._v("危化镖局提供化工仓储园区从立项到落地标准化全流程辅导服务")]), _c("img", {
    staticClass: "width-1280 margin-top-110",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/WMS/01.png")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "font-36 color-212121"
  }, [_vm._v("WMS运作流程")]), _c("img", {
    staticClass: "width-1280 margin-top-70",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/WMS/12.png")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-05",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center text-left"
  }, [_c("img", {
    staticClass: "width-600 radius-10",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/WMS/13.png")
    }
  }), _c("div", {
    staticClass: "width-560"
  }, [_c("div", {
    staticClass: "font-weight-500 font-36 color-212121"
  }, [_vm._v("项目应用案例")]), _c("div", {
    staticClass: "font-16 color-6E6E6E margin-top-20 line-height-16"
  }, [_vm._v(" 鑫展旺仓储物流元占地面积220亩，总投资额4.35亿。交通便捷，是漳州唯一可以储存危化品的专业物流园，通过使用危化镖局WMS，顺利的达成闽南地区危化品经营实现集中仓储管理、专业运输、统一调度等标准化管理的目的。 同时， "), _c("span", {
    staticClass: "color-2A559D"
  }, [_vm._v("在服务鑫展旺仓储物流园的过程中，我们形成了一整套的化工园区投资建设方案，配合WMS系统的使用，通过预估和实际投入对比，有效降低了50~55%的人力成本和20~30%的管理成本。")])])])])]);
}];
render._withStripped = true;