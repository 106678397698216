"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "n-center",
    staticStyle: {
      padding: "52px 30px"
    }
  }, [_c("div", {
    staticClass: "newdetail_title font-weight-500 color-212121 text-left"
  }, [_vm._v(" " + _vm._s(_vm.detailData.articleTitle) + " ")]), _c("div", {
    staticClass: "newdetail_subtitle clearfix"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm.detailData.articleAuthor ? _c("small", [_vm._v(_vm._s(_vm.detailData.articleAuthor))]) : _vm._e(), _c("small", {
    staticStyle: {
      color: "#b2b2b2"
    }
  }, [_vm._v(_vm._s(_vm.detailData.chickTotalNum) + "人阅读")]), _c("small", {
    staticStyle: {
      color: "#b2b2b2"
    }
  }, [_vm._v(_vm._s(_vm.detailData.articleReleaseTime))])]), _c("div", {
    staticClass: "share_con",
    staticStyle: {
      float: "right"
    }
  }, [_c("small", [_vm._v("分享到:")]), _c("share", {
    staticStyle: {
      height: "auto"
    },
    attrs: {
      config: _vm.shareConfig
    }
  })], 1)]), _c("div", {
    ref: "element",
    staticClass: "nd_content flex-colmun-center",
    style: {
      height: _vm.lookAll ? "auto" : "990px"
    }
  }, [_c("html-panel", {
    staticClass: "nd_article",
    attrs: {
      url: _vm.pageUrlpc,
      ammId: _vm.ammid,
      sourceType: _vm.detailData.aticleSourceType,
      releaseTime: _vm.releaseTime,
      side_bind: "newslist"
    },
    on: {
      "update:url": function updateUrl($event) {
        _vm.pageUrlpc = $event;
      },
      "update:ammId": function updateAmmId($event) {
        _vm.ammid = $event;
      },
      "update:amm-id": function updateAmmId($event) {
        _vm.ammid = $event;
      },
      "update:sourceType": function updateSourceType($event) {
        return _vm.$set(_vm.detailData, "aticleSourceType", $event);
      },
      "update:source-type": function updateSourceType($event) {
        return _vm.$set(_vm.detailData, "aticleSourceType", $event);
      },
      "update:releaseTime": function updateReleaseTime($event) {
        _vm.releaseTime = $event;
      },
      "update:release-time": function updateReleaseTime($event) {
        _vm.releaseTime = $event;
      },
      setLookAll: _vm.setLookAll
    }
  }), !_vm.lookAll ? _c("div", {
    staticClass: "look_all flex-row-center",
    on: {
      click: function click($event) {
        return _vm.toLookAll();
      }
    }
  }, [_c("span", {
    staticClass: "look_all_btn mouse-pointer"
  }, [_vm._v("阅读全文")])]) : _vm._e()], 1), _vm.detailData.relationList && _vm.detailData.relationList.length > 0 ? _c("div", {
    staticClass: "nd_download"
  }, [_c("i", {
    staticClass: "el-icon-download"
  }), _vm.user ? _c("a", {
    staticClass: "download mouse-pointer",
    attrs: {
      href: _vm.detailData.relationList[0].enclosureUrl,
      download: "",
      title: "下载"
    }
  }, [_vm._v("点击下载：" + _vm._s(_vm.detailData.relationList[0].enclosureName))]) : _c("a", {
    staticClass: "download mouse-pointer",
    attrs: {
      title: "下载"
    },
    on: {
      click: function click($event) {
        _vm.$root.dialogFormVisible = true;
      }
    }
  }, [_vm._v("点击下载：" + _vm._s(_vm.detailData.relationList[0].enclosureName))])]) : _vm._e(), _vm.detailData.articleTagNames ? _c("div", {
    staticClass: "nd_keyword"
  }, _vm._l(_vm.detailData.articleTagNames, function (item, index) {
    return _c("el-link", {
      key: "tag-" + index,
      staticClass: "nd_keyword_name",
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.toSearch(item);
        }
      }
    }, [_vm._v(_vm._s(item))]);
  }), 1) : _vm._e(), _c("div", {
    staticClass: "nd_like flex-row-center uplous"
  }, [_c("el-button", {
    staticClass: "button nd_link_btn",
    attrs: {
      disabled: _vm.uplousDisabled,
      type: "text"
    },
    on: {
      click: function click($event) {
        return _vm.toUplous();
      }
    }
  }, [_c("div", {
    staticClass: "flex-row-center font-18",
    staticStyle: {
      "align-items": "flex-end"
    }
  }, [!_vm.uplousDisabled ? _c("img", {
    staticClass: "margin-right-10",
    attrs: {
      src: require("../../assets/static/img/common/reputation-empty.png"),
      width: "28px",
      height: "28px"
    }
  }) : _c("img", {
    staticClass: "margin-right-10",
    attrs: {
      src: require("../../assets/static/img/common/reputation.png"),
      width: "28px",
      height: "28px"
    }
  }), _vm._v(" 点赞 ")])])], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;