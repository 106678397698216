"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointmentTwo = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-two.vue"));
var _default = exports.default = {
  components: {
    appointmentTwo: _appointmentTwo.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/WMS/banner-01.jpg")
      }],
      functionList: [{
        title: '智能配置',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/02.png"),
        desc: '提供货物安全混合存放、混合配送的智能策略，安全合理的利用仓储空间资源'
      }, {
        title: '入仓管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/03.png"),
        desc: '通过系统对入仓全流程进行管控，保证入库的规格质量，确保仓储安全第一步'
      }, {
        title: '仓库管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/04.png"),
        desc: '系统准备了库存货物数据管理、仓库安全警报、空仓提醒、满仓提醒等多项服务，确保仓库安全有序工作'
      }, {
        title: '出仓管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/05.png"),
        desc: '针对货物出仓全流程进行货物跟踪，保障货物安全、顺利、快速出仓，提升仓储周转率'
      }, {
        title: '应急措施',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/06.png"),
        desc: '针对入库货物分门别类，制定专业的应急措施，最大程度保障货物和仓库安全'
      }, {
        title: '统计报表',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/07.png"),
        desc: '根据货物及仓库类型，提供不同的统计方式，包括汇总及明细，让各岗位人员都能及时查询精准数据'
      }],
      projectList: [{
        title: '仓储租赁服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/08.jpg"),
        desc: '专业的仓储招商服务团队，帮企业面向全国化工、物流公司进行仓储入驻招商，可根据园区特性，找到合适满意的入驻公司。'
      }, {
        title: '联合建仓服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/09.jpg"),
        desc: '借助丰富的化工仓储园区系统研发经验，通过合作建仓的方式，可以为园区提供软硬件、资金与人力等资源，致力于与合作方一同建设合规盈利的化工仓储园区。'
      }, {
        title: '建仓咨询服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/10.jpg"),
        desc: '形成规范化的行业仓储解决方案，从选址、建设、政府对接、政策补贴、资金补助、招商引资等多方面提供咨询策划服务，让园区开发建设少走弯路，快速盈利。'
      }, {
        title: '仓储软件服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/WMS/11.jpg"),
        desc: '拥有专业的研发团队成员，熟悉行业软件开发规则，能为园区定制开发相应的软件，无论是大到整个园区的管理系统，还是小到车辆软硬件开发，都能快速满足。'
      }]
    };
  }
};