"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "side-nav"
  }, [_c("nav", {
    staticClass: "side navbar"
  }, [_c("div", {
    staticClass: "mouse-pointer",
    attrs: {
      id: "side_new_image"
    }
  }, [_c("div", {
    staticClass: "img-box-container"
  }, [_c("img", {
    staticClass: "img-box",
    attrs: {
      src: require("../../src/assets/static/img/sidenav/OnlineGg.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "side_img_text",
    on: {
      click: _vm.jumpAdd
    }
  }, [_vm._v("去添加")])])]), _c("el-backtop", {
    attrs: {
      target: _vm.bindId,
      "visibility-height": 10
    }
  }, [_c("div", {
    staticClass: "mouse-pointer",
    attrs: {
      id: "side_zhiding"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.changeImageback(true);
      },
      mouseout: function mouseout($event) {
        return _vm.changeImageback(false);
      }
    }
  }, [_c("img", {
    staticClass: "img-box",
    attrs: {
      src: _vm.imageback,
      alt: ""
    }
  }), _vm._v(" 返回顶部 ")])]), _c("el-tooltip", {
    attrs: {
      placement: "left-start",
      effect: "light",
      transition: "initial",
      offset: 0,
      "popper-class": "slide-nav-tool",
      disabled: _vm.disabled
    }
  }, [_c("div", {
    staticClass: "mouse-pointer",
    attrs: {
      id: "side_kefus"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.changeImageOnline(true);
      },
      mouseout: function mouseout($event) {
        return _vm.changeImageOnline(false);
      },
      click: _vm.jumpOnline
    }
  }, [_c("img", {
    staticClass: "img-box",
    attrs: {
      src: _vm.imageSrcOnline,
      alt: ""
    }
  }), _vm._v(" 在线咨询 ")])]), _c("el-tooltip", {
    attrs: {
      placement: "left-start",
      effect: "light",
      transition: "initial",
      offset: 0,
      "popper-class": "slide-nav-tool"
    }
  }, [_c("div", {
    staticClass: "tooltip-box",
    attrs: {
      slot: "content"
    },
    slot: "content"
  }, [_c("div", {
    staticClass: "kefu_con"
  }, [_c("img", {
    attrs: {
      src: require("../../src/assets/static/img/sidenav/06.png"),
      alt: ""
    }
  }), _c("a", {
    attrs: {
      href: "javascript:void(0);"
    }
  }, [_vm._v("400-886-3156")])])]), _c("div", {
    staticClass: "mouse-pointer",
    attrs: {
      id: "side_kefu"
    },
    on: {
      mouseover: function mouseover($event) {
        return _vm.changeImage(true);
      },
      mouseout: function mouseout($event) {
        return _vm.changeImage(false);
      }
    }
  }, [_c("img", {
    staticClass: "img-box",
    attrs: {
      src: _vm.imageSrc,
      alt: ""
    }
  }), _vm._v(" 客服电话 ")])])], 1), _vm.overlayVisible ? _c("div", {
    staticClass: "overlay"
  }, [_c("div", {
    staticClass: "overlay-content"
  }, [_c("img", {
    attrs: {
      src: require("../../src/assets/static/img/sidenav/OnlineBG.png"),
      alt: ""
    }
  }), _c("i", {
    staticClass: "el-icon-close",
    on: {
      click: _vm.closeOverlay
    }
  }), _c("div", {
    staticClass: "overlay-bottom"
  }, [_c("div", {
    staticClass: "overlay-bottom_head"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    on: {
      change: _vm.typeChange
    },
    model: {
      value: _vm.value,
      callback: function callback($$v) {
        _vm.value = $$v;
      },
      expression: "value"
    }
  }, _vm._l(_vm.options, function (item) {
    return _c("el-option", {
      key: item.value,
      attrs: {
        label: item.label,
        value: item.value
      }
    });
  }), 1), _c("el-cascader", {
    staticClass: "city",
    attrs: {
      options: _vm.citys,
      placeholder: "请选择所在城市",
      size: "medium",
      props: {
        expandTrigger: "hover",
        value: "label"
      }
    },
    on: {
      change: _vm.cityChange
    },
    model: {
      value: _vm.city,
      callback: function callback($$v) {
        _vm.city = $$v;
      },
      expression: "city"
    }
  })], 1), _c("div", {
    staticClass: "bottom_right"
  }, [_c("img", {
    attrs: {
      src: this.QRcodeObj.wechatQrCode,
      alt: ""
    }
  }), _vm._m(0)])])])]) : _vm._e()]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "right_text"
  }, [_c("img", {
    attrs: {
      src: require("../../src/assets/static/img/sidenav/kefutext.png"),
      alt: ""
    }
  }), _c("div", {
    staticClass: "one"
  }, [_vm._v("提供一对一专属服务")]), _c("div", {
    staticClass: "qrcode"
  }, [_vm._v("微信扫一扫左侧二维码")]), _c("div", {
    staticClass: "qrcodes"
  }, [_vm._v("更多资源优先对接")])]);
}];
render._withStripped = true;