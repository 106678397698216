"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.txtEllipsis = exports.subPicUrl = exports.setTimeLocal = exports.ossImgZip = exports.ossImgUnZip = exports.getType = exports.getTimeLocal = exports.getRandomColor = exports.getParams = exports.getJobDictItemName = exports.getDictItem = exports.getCarDictItemName = exports.getAge = exports.default = void 0;
require("core-js/modules/es6.object.keys");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.regexp.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.to-string");
var _typeof2 = _interopRequireDefault(require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/typeof.js"));
var _cookie = require("@/assets/static/js/utils/cookie.js");
//设置定时sessionStorage
var setTimeLocal = exports.setTimeLocal = function setTimeLocal(key, value, expire) {
  var obj = {
    data: value,
    time: Date.now(),
    expire: expire
  };
  //sessionStorage 设置的值不能为对象,转为json字符串
  // session替换成cookie
  (0, _cookie.setCookie)(key, JSON.stringify(obj), 24 * 60 * 60);
};
//获取定时的sessionStorage
var getTimeLocal = exports.getTimeLocal = function getTimeLocal(key) {
  // session替换成cookie
  var val = (0, _cookie.getCookie)(key);
  if (!val) {
    return val;
  }
  // 判断val是否为JSON格式
  if (isJSON(val)) {
    val = JSON.parse(val);
  }
  return val.data;
};

// 判断对象类型
var getType = exports.getType = function getType(a) {
  if (a === null) {
    // null
    return 'null';
  }
  if ((0, _typeof2.default)(a) !== 'object') {
    return (0, _typeof2.default)(a); // 简单数据类型、函数
  }
  var b = Object.prototype.toString.apply(a);
  var c = b.substring(8, b.length - 1);
  if (c !== 'Object') {
    return c; // 内建对象Array、Date...等
  }
  if (a.constructor === Object) {
    return c; // Object的实例
  }
  if ('classname' in a.constructor.prototype && typeof a.constructor.prototype.classname === 'string') {
    return a.constructor.prototype.classname; //自己定义的类
  }
  return '<unknown type>';
};

// 判断对象是否为JSON格式
function isJSON(str) {
  if (typeof str == 'string') {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
  console.log('It is not a string!');
}

//判断对象是否为空
function isEmpty(obj) {
  if (typeof obj === "undefined" || obj === null || obj === "") {
    return true;
  } else {
    return false;
  }
}

//不足10补0
function Appendzero(obj) {
  if (obj < 10) return "0" + "" + obj;else return obj;
}

//车辆类型和载重
function joinCarTypeAndWeight(carType, weight) {
  //先把全部去掉，再判断是否为空，然后拼接
  var joinedStr = "";
  if (!isEmpty(carType) && weight > 0) {
    joinedStr = carType + '-' + weight + '吨';
  }
  if (!isEmpty(carType) && weight == 0) {
    joinedStr = carType;
  }
  if (isEmpty(carType) && weight > 0) {
    joinedStr = weight + '吨';
  }
  return joinedStr;
}

//拼接省和市
function joinCityAndDistrict(city, district, seprater) {
  //先把全部去掉，再判断是否为空，然后拼接
  if (!isEmpty(city)) {
    city = city.replace(/全省/g, '');
    city = city.replace(/全市/g, '');
  }
  if (!isEmpty(district)) {
    district = district.replace(/全省/g, '');
    district = district.replace(/全市/g, '');
  }
  if (!isEmpty(city) && !isEmpty(district)) {
    return city + seprater + district;
  } else if (!isEmpty(city) && isEmpty(district)) {
    return city;
  } else {
    return "";
  }
}

// 分割地址
function subAddress(address) {
  address = address.replace(/\/全省/g, '');
  address = address.replace(/\/全市/g, '');
  var city = '';
  var addressArr = address.split("/");
  if (addressArr.length > 0 && !this.isEmpty(addressArr[0])) {
    city = addressArr[0];
  }
  if (addressArr.length > 1 && !this.isEmpty(addressArr[1])) {
    city = addressArr[1];
  }
  return city;
}

//时间戳转日期: time为	日期字符串
function timeToDate(timeStr, format) {
  if (isEmpty(timeStr)) {
    return '';
  }
  var time = new Date(timeStr.replace(/-/g, '/'));
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hour = time.getHours();
  var minute = time.getMinutes();
  var second = time.getSeconds();
  if (format == 'MM-dd') {
    return month + "-" + Appendzero(date);
  } else if (format == 'YY-MM-dd') {
    return year + "-" + Appendzero(month) + "-" + Appendzero(date);
  } else if (format == 'YY-MM-dd HH') {
    return year + "-" + Appendzero(month) + "-" + Appendzero(date) + " " + Appendzero(hour);
  } else if (format == 'YY-MM-dd HH:mm') {
    return year + "-" + Appendzero(month) + "-" + Appendzero(date) + " " + Appendzero(hour) + ":" + Appendzero(minute);
  } else if (format == 'HH:mm') {
    return Appendzero(hour) + ":" + Appendzero(minute);
  } else {
    return year + "-" + Appendzero(month) + "-" + Appendzero(date) + " " + Appendzero(hour) + ":" + Appendzero(minute) + ":" + Appendzero(second);
  }
}

//时间转中文字符: time为Date类型
function timeToString(time) {
  var separator = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '-';
  if (isEmpty(time)) {
    return '';
  }
  var timeStr = '';
  var currentTsp = Date.parse(new Date());
  var acceptTsp = Date.parse(time.replace(/-/g, '/'));
  var timeDifference = currentTsp / 1000 - acceptTsp / 1000; //计算时间差
  if (timeDifference >= 0 && timeDifference < 60) {
    //一分钟之内
    timeStr = "刚刚";
  } else if (timeDifference >= 60 && timeDifference < 60 * 60) {
    //一小时内
    timeStr = parseInt(timeDifference / 60) + '分钟前';
  } else if (timeDifference >= 60 * 60 && timeDifference < 60 * 60 * 24) {
    //一天内
    timeStr = parseInt(timeDifference / (60 * 60)) + '小时前';
  } else if (timeDifference >= 60 * 60 * 24 && timeDifference < 60 * 60 * 24 * 2) {
    //两天内
    var temp = this.timeToDate(time, 'HH:mm');
    timeStr = '昨天' + temp;
  } else if (timeDifference >= 60 * 60 * 24 * 24 && timeDifference < 60 * 60 * 24 * 3) {
    //三天内
    var temp = this.timeToDate(time, 'HH:mm');
    timeStr = '前天' + temp;
  } else if (timeDifference < 60 * 60 * 24 * 4) {
    //三天前
    timeStr = parseInt(timeDifference / (60 * 60 * 24)) + '天前';
  } else if (new Date(time).getFullYear() == new Date().getFullYear()) {
    timeStr = new Date(acceptTsp).Format("MM".concat(separator == '!' ? '月' : separator, "dd").concat(separator == '!' ? '日' : ''));
  } else {
    timeStr = new Date(acceptTsp).Format("yyyy".concat(separator == '!' ? '年' : separator, "MM").concat(separator == '!' ? '月' : separator, "dd").concat(separator == '!' ? '日' : ''));
  }
  return timeStr;
}

//省市区截取
function getArea(str) {
  var area = {};
  var index11 = 0;
  var index1 = str.indexOf("省");
  if (index1 == -1) {
    index11 = str.indexOf("自治区");
    if (index11 != -1) {
      area.Province = str.substring(0, index11 + 3);
    } else {
      area.Province = str.substring(0, 0);
    }
  } else {
    area.Province = str.substring(0, index1 + 1);
  }
  var index2 = str.indexOf("市");
  if (index11 == -1) {
    area.City = str.substring(index11 + 1, index2 + 1);
  } else {
    if (index11 == 0) {
      area.City = str.substring(index1 + 1, index2 + 1);
    } else {
      area.City = str.substring(index11 + 3, index2 + 1);
    }
  }
  var index3 = str.lastIndexOf("区");
  if (index3 == -1) {
    index3 = str.indexOf("县");
    area.Country = str.substring(index2 + 1, index3 + 1);
  } else {
    area.Country = str.substring(index2 + 1, index3 + 1);
  }
  return area;
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd hh:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d h:m:s.S")      ==> 2006-7-2 8:9:4.18
Date.prototype.Format = function (fmt) {
  //author: meizz
  var o = {
    "M+": this.getMonth() + 1,
    //月份
    "d+": this.getDate(),
    //日
    "h+": this.getHours(),
    //小时
    "m+": this.getMinutes(),
    //分
    "s+": this.getSeconds(),
    //秒
    "q+": Math.floor((this.getMonth() + 3) / 3),
    //季度
    "S": this.getMilliseconds() //毫秒
  };
  if (/(y+)/.test(fmt)) fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
  for (var k in o) if (new RegExp("(" + k + ")").test(fmt)) fmt = fmt.replace(RegExp.$1, RegExp.$1.length == 1 ? o[k] : ("00" + o[k]).substr(("" + o[k]).length));
  return fmt;
};

// 根据数据字典的键取出对应的键值
var getDictItem = exports.getDictItem = function getDictItem(dictsData, code, keyName, keyValue) {
  var valueKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : '';
  if (!dictsData) dictsData = JSON.parse(window.localStorage.getItem('dics'));
  if (!dictsData[code]) return '';
  var result = '';
  if (valueKey === '') result = [];
  keyValue += '';
  if (dictsData) {
    dictsData[code].filter(function (item) {
      if (keyValue.split(',').indexOf(item[keyName] + '') >= 0) {
        if (valueKey === '') result.push(item);else result += (result ? ',' : '') + item[valueKey];
      }
    });
  }
  return result;
};

// 招聘求职所需字段取出键值
var getJobDictItemName = exports.getJobDictItemName = function getJobDictItemName(item) {
  var dictsData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  item['workTime'] = getDictItem(dictsData, 'MERCHANT_WORK_TIME', 'defineValue', item['workTime'], 'defineCode'); // 工作年限
  item['certificate'] = getDictItem(dictsData, 'MERCHANT_CERTIFICATE', 'defineValue', item['certificate'], 'defineCode'); // 证书
  item['monthlySalary'] = getDictItem(dictsData, 'MERCHANT_JOB_SALARY', 'defineValue', item['monthlySalary'], 'defineCode'); // 月薪
  item['post'] = getDictItem(dictsData, 'MERCHANT_POST_TYPE', 'defineValue', item['post'], 'defineCode'); // 岗位
  item['postNature'] = item.postNature ? item.postNature == '1' ? '全职' : '兼职' : ''; // 岗位性质
  item['jobStatus'] = getDictItem(dictsData, 'MERCHANT_JOB_STATUS', 'defineValue', item['jobStatus'], 'defineCode'); // 求职状态
  item['welfare'] = getDictItem(dictsData, 'MERCHANT_WELFARE', 'defineValue', item['welfare'], 'defineCode'); // 福利待遇
};

// 买车卖车所需字段取出键值
var getCarDictItemName = exports.getCarDictItemName = function getCarDictItemName(item) {
  var dictsData = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
  item['purchaseTimeType'] = getDictItem(dictsData, 'MERCHANT_PURCHASE_TIME', 'defineValue', item['purchaseTimeType'], 'defineCode'); // 购买时长
  item['cargoType'] = getDictItem(dictsData, 'GOODS_TYPE', 'gtid', item['cargoType'], 'goodsTypeCode'); // 货物类型类型
  item['carType'] = getDictItem(dictsData, 'MERCHANT_CAR_TYPE', 'defineValue', item['carType'], 'defineCode'); // 车辆类型
  item['vehicleCondition'] = item.vehicleCondition == '1' ? '新车' : '二手车'; // 车况
  /*item['price'] = getDictItem(dictsData,'MERCHANT_PRICE','defineValue',item['price'],'defineCode')*/
};

// 格式化请求参数
var getParams = exports.getParams = function getParams(fun, params) {
  var page = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
  var result = {
    version: 1,
    fun: fun,
    param: Object.assign(params, page)
  };
  return {
    'jsonParamete': JSON.stringify(result)
  };
};

// 超出显示省略号
var txtEllipsis = exports.txtEllipsis = function txtEllipsis(val, len) {
  var startIndex = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  var seprater = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : '...';
  if (!val) {
    return '';
  }
  if (val.length > len) {
    return val.slice(startIndex, len) + seprater;
  } else {
    return val;
  }
};

// OSS图片压缩
var ossImgZip = exports.ossImgZip = function ossImgZip(imgUrl) {
  var zipImgUrl = imgUrl;
  imgUrl = imgUrl.split('?')[0];
  if (imgUrl.indexOf('oss-cn-shenzhen.aliyuncs.com') > 0) {
    zipImgUrl = imgUrl + '?x-oss-process=image/auto-orient,1/resize,p_60/quality,q_100/sharpen,50';
  }
  return zipImgUrl;
};

// 恢复OSS图片压缩
var ossImgUnZip = exports.ossImgUnZip = function ossImgUnZip(imgUrl) {
  var unZipImgUrl = '';
  if (imgUrl.indexOf('oss-cn-shenzhen.aliyuncs.com') > 0) {
    unZipImgUrl = imgUrl.replace('?x-oss-process=image/auto-orient,1/resize,p_60/quality,q_100/sharpen,50', '');
  }
  return unZipImgUrl;
};

// 切割图片数据picUrl
var subPicUrl = exports.subPicUrl = function subPicUrl(picUrl) {
  var haveUrl = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var haveZip = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
  var arr = [];
  var data = {};
  if (picUrl) {
    if (getType(picUrl) == 'string' && picUrl.indexOf('{') < 0) {
      data = picUrl.split(',');
    } else if (getType(picUrl) == 'string' && getType(JSON.parse(picUrl)) == 'Object') {
      data = JSON.parse(picUrl);
    }
    Object.keys(data).forEach(function (key) {
      if (data[key] && haveUrl && haveZip) arr.push({
        url: ossImgZip(data[key])
      });
      if (data[key] && haveUrl && !haveZip) arr.push({
        url: data[key]
      });
      if (data[key] && !haveUrl && haveZip) arr.push(ossImgZip(data[key]));
      if (data[key] && !haveUrl && !haveZip) arr.push(data[key]);
    });
  }
  return arr;
};

// 计算年龄(虚岁)
var getAge = exports.getAge = function getAge(birthday) {
  if (birthday) {
    //出生时间 毫秒
    var birthDayTime = new Date(birthday.replace(/-/g, '/')).getTime();
    //当前时间 毫秒
    var nowTime = new Date().getTime();
    //一年毫秒数(365 * 86400000 = 31536000000)
    return Math.ceil((nowTime - birthDayTime) / 31536000000);
  }
};

/* 随机获取颜色 */
var getRandomColor = exports.getRandomColor = function getRandomColor(val) {
  var gridNormalBgColor = ['#F3AA98', '#85C2EE', '#C2DB88', '#78DBD7', '#F4CC85'];
  var randIndex = val % gridNormalBgColor.length;
  return gridNormalBgColor[randIndex];
};

// 给Array挂在一个去除数组空元素的方法
Array.prototype.notEmpty = function () {
  var arr = [];
  this.map(function (val) {
    //过滤规则为，不为空串、不为null、不为undefined，也可自行修改
    if (val !== "" && val != undefined) {
      arr.push(val);
    }
  });
  return arr;
};
String.prototype.colorRgb = function (opacity) {
  opacity = opacity || 1;
  var color = this.toLowerCase();
  var pattern = /^#([0-9|a-f]{3}|[0-9|a-f]{6})$/;
  if (color && pattern.test(color)) {
    if (color.length == 4) {
      color = '#' + color[1] + color[1] + color[2] + color[2] + color[3] + color[3];
    }
    var colorNew = [];
    for (var i = 1; i < 7; i += 2) {
      colorNew.push(parseInt("0x" + color.slice(i, i + 2)));
    }
    return "RGBA(" + colorNew.join(",") + ", " + opacity + ")";
  }
  return color;
};
var _default = exports.default = {
  isEmpty: isEmpty,
  joinCarTypeAndWeight: joinCarTypeAndWeight,
  joinCityAndDistrict: joinCityAndDistrict,
  subAddress: subAddress,
  timeToString: timeToString,
  timeToDate: timeToDate,
  getArea: getArea
};