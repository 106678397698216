"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.delCookie = delCookie;
exports.getCookie = getCookie;
exports.setCookie = setCookie;
function setCookie(c_name, value, expire) {
  var date = new Date();
  date.setSeconds(date.getSeconds() + expire);
  document.cookie = c_name + "=" + escape(value) + "; expires=" + date.toGMTString();
  //console.log(document.cookie)
}
function getCookie(c_name) {
  if (document.cookie.length > 0) {
    var c_start = document.cookie.indexOf(c_name + "=");
    if (c_start != -1) {
      c_start = c_start + c_name.length + 1;
      var c_end = document.cookie.indexOf(";", c_start);
      if (c_end == -1) c_end = document.cookie.length;
      return unescape(document.cookie.substring(c_start, c_end));
    }
  }
  return "";
}
function delCookie(c_name) {
  setCookie(c_name, "", -1);
}