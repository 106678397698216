"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "n-center"
  }, [_c("div", {
    staticClass: "newdetail_title font-bold text-left"
  }, [_vm._v(" " + _vm._s(_vm.detailData.articleTitle) + " ")]), _c("div", {
    staticClass: "newdetail_subtitle clearfix"
  }, [_c("div", {
    staticClass: "left"
  }, [_vm.detailData.articleAuthor ? _c("small", [_vm._v(_vm._s(_vm.detailData.articleAuthor))]) : _vm._e(), _c("small", [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/news/look_ico.png"
    }
  }), _vm._v(_vm._s(_vm.detailData.chickTotalNum))]), _c("small", [_vm._v(_vm._s(_vm.detailData.articleReleaseTime))]), _vm.detailData.relationList && _vm.detailData.relationList.length > 0 ? _c("small", [_vm._v("下载次数: " + _vm._s(_vm.detailData.relationList[0].enclosureDownloadNum))]) : _vm._e()]), _c("div", {
    staticClass: "share_con",
    staticStyle: {
      float: "right"
    }
  }, [_c("small", [_vm._v("分享到:")]), _c("share", {
    staticStyle: {
      height: "auto"
    },
    attrs: {
      config: _vm.shareConfig
    }
  })], 1)]), _c("div", {
    ref: "element",
    staticClass: "nd_content flex-colmun-center",
    style: {
      height: _vm.lookAll ? "auto" : "990px"
    }
  }, [_c("html-panel", {
    staticClass: "nd_article",
    attrs: {
      url: _vm.pageUrlpc,
      ammId: _vm.ammid,
      sourceType: _vm.detailData.aticleSourceType,
      releaseTime: _vm.releaseTime,
      side_bind: "newslist"
    },
    on: {
      "update:url": function updateUrl($event) {
        _vm.pageUrlpc = $event;
      },
      "update:ammId": function updateAmmId($event) {
        _vm.ammid = $event;
      },
      "update:amm-id": function updateAmmId($event) {
        _vm.ammid = $event;
      },
      "update:sourceType": function updateSourceType($event) {
        return _vm.$set(_vm.detailData, "aticleSourceType", $event);
      },
      "update:source-type": function updateSourceType($event) {
        return _vm.$set(_vm.detailData, "aticleSourceType", $event);
      },
      "update:releaseTime": function updateReleaseTime($event) {
        _vm.releaseTime = $event;
      },
      "update:release-time": function updateReleaseTime($event) {
        _vm.releaseTime = $event;
      },
      setLookAll: _vm.setLookAll
    }
  }), !_vm.lookAll ? _c("div", {
    staticClass: "look_all flex-row-center font-bold",
    on: {
      click: function click($event) {
        return _vm.toLookAll();
      }
    }
  }, [_c("span", {
    staticClass: "mouse-pointer"
  }, [_vm._v("阅读全文")]), _c("img", {
    staticClass: "lookall_ico",
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/news/trigon_pink_ico.png"
    }
  })]) : _vm._e()], 1), _vm.detailData.relationList && _vm.detailData.relationList.length > 0 ? _c("div", {
    staticClass: "nd_download"
  }, [_c("i", {
    staticClass: "el-icon-download"
  }), _vm.user ? _c("a", {
    staticClass: "download mouse-pointer",
    attrs: {
      href: _vm.detailData.relationList[0].enclosureUrl,
      download: "",
      title: "下载"
    }
  }, [_vm._v("点击下载：" + _vm._s(_vm.detailData.relationList[0].enclosureName))]) : _c("a", {
    staticClass: "download mouse-pointer",
    attrs: {
      title: "下载"
    },
    on: {
      click: function click($event) {
        _vm.$root.dialogFormVisible = true;
      }
    }
  }, [_vm._v("点击下载：" + _vm._s(_vm.detailData.relationList[0].enclosureName))])]) : _vm._e(), _vm.detailData.articleTagNames ? _c("div", {
    staticClass: "nd_keyword"
  }, _vm._l(_vm.detailData.articleTagNames, function (item, index) {
    return _c("el-link", {
      key: "tag-" + index,
      staticClass: "nd_keyword_name",
      attrs: {
        type: "primary"
      },
      on: {
        click: function click($event) {
          return _vm.toSearch(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item))]);
  }), 1) : _vm._e(), _c("div", {
    staticClass: "nd_like flex-row-end uplous"
  }, [_c("el-button", {
    staticClass: "button nd_link_btn",
    attrs: {
      icon: "icon-nice",
      disabled: _vm.uplousDisabled,
      type: "text"
    },
    on: {
      click: function click($event) {
        return _vm.toUplous();
      }
    }
  }, [_c("svg", {
    staticClass: "icon",
    staticStyle: {
      "vertical-align": "middle",
      width: "28px",
      height: "28px"
    },
    attrs: {
      "aria-hidden": "true"
    }
  }, [_c("use", {
    attrs: {
      "xlink:href": "#icon-nice"
    }
  })]), _vm._v(" 点赞 ")])], 1), _c("div", {
    staticClass: "news_list_recommend"
  }, [_c("div", {
    staticClass: "recommend_title maintint_before flex-row-alignc font-bold mainTintColor"
  }, [_vm._v("相关推荐")]), _c("div", {
    staticClass: "news_list"
  }, [_c("div", {
    staticClass: "list_con"
  }, [_c("ul", [_vm._l(_vm.relatedData, function (item, index) {
    return _c("li", {
      key: "related-" + index,
      staticClass: "list_noimg"
    }, [item.articleCoverStyle == "ARTICLE_COVER_NONE" ? _c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "资讯推荐列表",
          action: "点击",
          opt_label: item.articleTitle
        },
        expression: "{category:'资讯推荐列表', action:'点击', opt_label:item.articleTitle}"
      }],
      staticClass: "text-left",
      attrs: {
        target: "_blank",
        to: {
          name: "newNewsList",
          query: {
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: item.ammId
          }
        }
      }
    }, [_c("span", {
      staticClass: "list_heading font-bold text-left"
    }, [_vm._v(" " + _vm._s(item.articleTitle) + " ")]), _c("span", {
      staticClass: "list_subheading"
    }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读 · " + _vm._s(item.articleReleaseTime))])]) : _vm._e(), item.articleCoverStyle == "ARTICLE_COVER_ONE" ? _c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "资讯推荐列表",
          action: "点击",
          opt_label: item.articleTitle
        },
        expression: "{category:'资讯推荐列表', action:'点击', opt_label:item.articleTitle}"
      }],
      staticClass: "text-left flex-row-alignc",
      staticStyle: {
        width: "100%"
      },
      attrs: {
        target: "_blank",
        to: {
          name: "newNewsList",
          query: {
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: item.ammId
          }
        }
      }
    }, [_c("div", {
      staticClass: "list_oneimg_img"
    }, [_c("img", {
      attrs: {
        src: item.pictureOssUrls
      }
    })]), _c("div", {
      staticClass: "list_oneimg_con"
    }, [_c("span", {
      staticClass: "list_heading font-bold text-left"
    }, [_vm._v(_vm._s(item.articleTitle))]), _c("span", {
      staticClass: "list_subheading"
    }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读 · " + _vm._s(item.articleReleaseTime))])])]) : _vm._e(), item.articleCoverStyle == "ARTICLE_COVER_THREE" ? _c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "资讯推荐列表",
          action: "点击",
          opt_label: item.articleTitle
        },
        expression: "{category:'资讯推荐列表', action:'点击', opt_label:item.articleTitle}"
      }],
      staticClass: "text-left",
      attrs: {
        target: "_blank",
        to: {
          name: "newNewsList",
          query: {
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: item.ammId
          }
        }
      }
    }, [_c("span", {
      staticClass: "list_heading font-bold text-left"
    }, [_vm._v(_vm._s(item.articleTitle))]), _c("div", {
      staticClass: "four_img felx-row-between",
      staticStyle: {
        width: "100%"
      }
    }, _vm._l(item.pictureOssUrls, function (pItem, pIndex) {
      return _c("img", {
        key: pIndex,
        attrs: {
          src: pItem
        }
      });
    }), 0), _c("span", {
      staticClass: "list_subheading"
    }, [_vm._v(_vm._s(item.chickTotalNum == null ? "0" : item.chickTotalNum) + "人阅读 · " + _vm._s(item.articleReleaseTime))])]) : _vm._e()], 1);
  }), _vm.relatedTipShow || _vm.relatedlength < "4" ? _c("div", {
    staticClass: "news_more",
    staticStyle: {
      width: "100%"
    }
  }, [_c("div", {
    staticClass: "nodata"
  }, [_vm._v("没有更多数据了~")])]) : _c("div", {
    staticClass: "news_more",
    staticStyle: {
      width: "100%"
    }
  }, [_c("el-link", {
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.toMoreRelated(_vm.relatedPage.pageno, 5, _vm.ammid);
      }
    }
  }, [_vm._v("查看更多推荐> ")])], 1)], 2)])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;