"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "list-container"
  }, [_c("div", {
    staticClass: "width-full"
  }, [_c("el-table", {
    attrs: {
      data: _vm.listData,
      stripe: "",
      "cell-style": _vm.tableRowStyle,
      "default-sort": {
        prop: "arrivalTime",
        order: "descending"
      },
      "header-cell-style": {
        "background-color": "#eaf0fe",
        color: "#5A6991",
        "font-size": "16px"
      }
    }
  }, [_c("el-table-column", {
    attrs: {
      label: "出发城市",
      "show-overflow-tooltip": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.orginProvince) + _vm._s(scope.row.joinedStart ? "/" + scope.row.joinedStart : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "到达城市"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.destinationProvince) + _vm._s(scope.row.joinedEnd ? "/" + scope.row.joinedEnd : ""))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "车辆信息"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", {
          staticClass: "name"
        }, [_vm._v(_vm._s(scope.row.joinedCarInfo ? scope.row.joinedCarInfo : "无"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "发车时间",
      prop: "loadTime",
      sortable: ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.vsTypeCode == 10 ? "长期运力" : scope.row.vsStartSetOutTime || scope.row.vsEndSetOutTime ? "".concat(_vm.formatDate(new Date(scope.row.vsStartSetOutTime), "MM月dd日"), "-").concat(_vm.formatDate(new Date(scope.row.vsEndSetOutTime), "MM月dd日")) : "电话联系"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      label: "参考价格"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("span", [_vm._v(_vm._s(scope.row.minPrice > 0 ? scope.row.minPrice + "-" + scope.row.topPrice + "元/吨" : scope.row.sinPrice ? scope.row.sinPrice + "元/吨" : "暂无价格"))])];
      }
    }])
  }), _c("el-table-column", {
    attrs: {
      prop: "releaseTime",
      label: "发布时间",
      sortable: ""
    }
  }), _c("el-table-column", {
    attrs: {
      label: "操作"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(scope) {
        return [_c("div", {
          directives: [{
            name: "track-event",
            rawName: "v-track-event",
            value: {
              category: "车源列表查看详情",
              action: "点击",
              opt_label: scope.row.joinedStart + "-" + scope.row.joinedEnd,
              opt_value: scope.row.vsid
            },
            expression: "{category:'车源列表查看详情', action:'点击', opt_label:scope.row.joinedStart+'-'+scope.row.joinedEnd, opt_value:scope.row.vsid}"
          }],
          staticClass: "detail-btn",
          on: {
            click: function click($event) {
              return _vm.clickToDetail(scope.row.vsid);
            }
          }
        }, [_vm._v(" 查看详情")])];
      }
    }])
  })], 1)], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;