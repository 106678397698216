"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pubgoods-container",
    attrs: {
      id: "pubgoods"
    }
  }, [_c("trade-banner"), _c("div", {
    staticClass: "tab"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center margin-auto"
  }, [_c("div", {
    staticClass: "color-666666 font-22"
  }, [_vm._v("化工物流/交易大厅")]), _c("div", {
    staticClass: "height-full flex-row-start"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab-item flex-row-center font-22",
      class: [index == 3 ? "active-tab-item" : ""],
      on: {
        click: function click($event) {
          return _vm.tabChange(item.url);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)])]), _c("div", {
    staticClass: "pubgoods_back flex-column-justc"
  }, [_c("div", {
    staticClass: "pub_header text-left font-18 color-444444"
  }, [_vm._v("发布货源")]), _vm._m(0), _c("div", {
    staticClass: "pub_body"
  }, [_c("el-form", {
    ref: "pubForm",
    attrs: {
      model: _vm.pubForm,
      rules: _vm.pubRules,
      "label-position": "right"
    },
    on: {
      validate: function validate($event) {
        return _vm.canPub();
      }
    }
  }, [_c("div", {
    staticClass: "pub_title text-left flex-row-alignc color-212121 font-18"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("路线信息")])], 1), _c("el-form-item", {
    attrs: {
      label: "出发城市",
      prop: "gsData.startLocation"
    }
  }, [_c("el-cascader", {
    staticClass: "start-city",
    attrs: {
      options: _vm.startCitys,
      placeholder: "请选择出发城市",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.startCityChange
    },
    model: {
      value: _vm.pubForm.gsData.startLocation,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "startLocation", $$v);
      },
      expression: "pubForm.gsData.startLocation"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "到达城市",
      prop: "gsData.endLocation"
    }
  }, [_c("el-cascader", {
    staticClass: "end-city",
    attrs: {
      options: _vm.endCitys,
      placeholder: "请选择到达城市",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.endCityChange
    },
    model: {
      value: _vm.pubForm.gsData.endLocation,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "endLocation", $$v);
      },
      expression: "pubForm.gsData.endLocation"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "联系人",
      prop: "gsData.contact"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请填写联系人"
    },
    model: {
      value: _vm.pubForm.gsData.contact,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "contact", $$v);
      },
      expression: "pubForm.gsData.contact"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "联系电话",
      prop: "gsData.phone"
    }
  }, [_c("el-input", {
    attrs: {
      type: "phone",
      autocomplete: "off",
      clearable: true,
      placeholder: "请填写联系电话"
    },
    model: {
      value: _vm.pubForm.gsData.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "phone", $$v);
      },
      expression: "pubForm.gsData.phone"
    }
  })], 1), _c("el-divider"), _c("div", {
    staticClass: "pub_title text-left flex-row-alignc color-212121 font-18"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("货物信息")])], 1), _c("el-form-item", {
    attrs: {
      label: "货物名称",
      prop: "gsData.goodsName"
    }
  }, [_c("el-input", {
    attrs: {
      autocomplete: "off",
      clearable: true,
      placeholder: "请填写货物名称"
    },
    model: {
      value: _vm.pubForm.gsData.goodsName,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "goodsName", $$v);
      },
      expression: "pubForm.gsData.goodsName"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "货物类型",
      prop: "gsData.gtid"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择货物类型"
    },
    model: {
      value: _vm.pubForm.gsData.gtid,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "gtid", $$v);
      },
      expression: "pubForm.gsData.gtid"
    }
  }, _vm._l(_vm.gtType, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.goodsTypeName,
        value: item.gtid + ""
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticClass: "weight_input",
    attrs: {
      label: "货物重量",
      prop: "gsData.goodsWeigth"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请填写货物重量"
    },
    model: {
      value: _vm.pubForm.gsData.goodsWeigth,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "goodsWeigth", $$v);
      },
      expression: "pubForm.gsData.goodsWeigth"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("吨")])], 2)], 1), _c("el-form-item", {
    attrs: {
      label: "所需车型",
      prop: "gsData.vehicleTypeId"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择所需车型"
    },
    model: {
      value: _vm.pubForm.gsData.vehicleTypeId,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "vehicleTypeId", $$v);
      },
      expression: "pubForm.gsData.vehicleTypeId"
    }
  }, _vm._l(_vm.ctType, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.vehicleTypeName,
        value: item.vehicleTypeId + ""
      }
    });
  }), 1)], 1), _c("el-divider"), _c("div", {
    staticClass: "pub_title text-left flex-row-alignc color-212121 font-18"
  }, [_c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _c("span", [_vm._v("其他信息")])], 1), _c("el-form-item", {
    attrs: {
      label: "运输类型"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择运输类型"
    },
    model: {
      value: _vm.pubForm.gsData.useVcType,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "useVcType", $$v);
      },
      expression: "pubForm.gsData.useVcType"
    }
  }, _vm._l(_vm.useVcType, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.defineValue,
        value: item.defineCode
      }
    });
  }), 1)], 1), _c("el-form-item", {
    attrs: {
      label: "装车时间"
    }
  }, [_c("el-date-picker", {
    attrs: {
      type: "date",
      placeholder: "请选择装车时间"
    },
    model: {
      value: _vm.pubForm.gsData.arrivalTime,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "arrivalTime", $$v);
      },
      expression: "pubForm.gsData.arrivalTime"
    }
  })], 1), _c("el-form-item", {
    attrs: {
      label: "装卸方式"
    }
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择装卸方式"
    },
    model: {
      value: _vm.pubForm.gsData.loadSourceCode,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "loadSourceCode", $$v);
      },
      expression: "pubForm.gsData.loadSourceCode"
    }
  }, _vm._l(_vm.handingway, function (item, index) {
    return _c("el-option", {
      key: index,
      attrs: {
        label: item.defineValue,
        value: item.defineCode
      }
    });
  }), 1)], 1), _c("el-form-item", {
    staticClass: "margin_price",
    attrs: {
      label: "保证金"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请填写保证金"
    },
    model: {
      value: _vm.pubForm.gsData.margin,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "margin", $$v);
      },
      expression: "pubForm.gsData.margin"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1), _c("el-form-item", {
    staticClass: "refer_price",
    attrs: {
      label: "参考价格"
    }
  }, [_c("div", {
    staticClass: "minprice_input"
  }, [_c("el-form-item", [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请填写价格"
    },
    model: {
      value: _vm.pubForm.gsData.minPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "minPrice", $$v);
      },
      expression: "pubForm.gsData.minPrice"
    }
  })], 1)], 1), _c("div", {
    staticClass: "line text-center margin-left-15 margin-right-15"
  }, [_vm._v("至")]), _c("div", {
    staticClass: "topprice_input flex-row-alignc"
  }, [_c("el-form-item", {
    attrs: {
      prop: "gsData.topPrice"
    }
  }, [_c("el-input", {
    staticStyle: {
      "vertical-align": "middle"
    },
    attrs: {
      type: "number",
      placeholder: "请填写价格"
    },
    model: {
      value: _vm.pubForm.gsData.topPrice,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "topPrice", $$v);
      },
      expression: "pubForm.gsData.topPrice"
    }
  }, [_c("template", {
    slot: "append"
  }, [_vm._v("元")])], 2)], 1)], 1)]), _c("el-form-item", {
    attrs: {
      label: "备注"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      rows: 1,
      placeholder: "这里是备注"
    },
    model: {
      value: _vm.pubForm.gsData.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.pubForm.gsData, "remark", $$v);
      },
      expression: "pubForm.gsData.remark"
    }
  })], 1)], 1)], 1)]), _c("div", {
    staticClass: "margin-top-60 margin-bottom-50"
  }, [_c("el-button", {
    directives: [{
      name: "track-event",
      rawName: "v-track-event",
      value: {
        category: "发布货源",
        action: "点击",
        opt_label: ""
      },
      expression: "{category:'发布货源', action:'点击', opt_label: ''}"
    }],
    staticClass: "pub-btn",
    attrs: {
      type: "primary",
      disabled: _vm.isempty
    },
    on: {
      click: function click($event) {
        return _vm.topubgoods("pubForm");
      }
    }
  }, [_vm._v(" 立即发布 ")])], 1), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "tip"
  }, [_c("img", {
    staticClass: "width-21",
    attrs: {
      src: require("../../assets/static/img/common/warm-FD9E31.png")
    }
  }), _c("span", {
    staticClass: "margin-left-15 text-left"
  }, [_vm._v("请确保发布的内容真实有效、资质合规，同时承诺严格遵守国家法律法规，积极防范各类安全事故，禁止违规操作。若有违反，自行承担责任，与平台无关！")])]);
}];
render._withStripped = true;