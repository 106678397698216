"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _default = exports.default = {
  name: 'Paging',
  props: {
    name: String,
    pageSize: {
      type: Number,
      default: 10
    },
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    layout: {
      type: String
    },
    pagingPosition: {
      type: String
    }
  },
  data: function data() {
    return {
      isShowJumper: false,
      isShowTotal: false,
      toPage: '',
      //跳转到x页
      pageGroup: 10 //可见分页数量 默认10个分页数
    };
  },
  created: function created() {
    console.log('created');
    this.isShowTotal = this.layout.indexOf('total') !== -1;
    this.isShowJumper = this.layout.indexOf('jumper') !== -1;
  },
  mounted: function mounted() {
    console.log('mounted', this.layout);
  },
  computed: {
    totalPage: function totalPage() {
      return Math.ceil(this.total / this.pageSize);
    },
    pagelist: function pagelist() {
      var list = [];
      var count = Math.floor(this.pageGroup / 2),
        center = this.currentPage;
      var left = 1,
        right = this.totalPage;
      if (this.totalPage > this.pageGroup) {
        if (this.currentPage > count + 1) {
          if (this.currentPage < this.totalPage - count) {
            left = this.currentPage - count;
            right = this.currentPage + count - 1;
          } else {
            left = this.totalPage - this.pageGroup + 1;
          }
        } else {
          right = this.pageGroup;
        }
      }

      // 遍历添加到数组里
      while (left <= right) {
        list.push({
          text: left,
          val: left
        });
        left++;
      }
      return list;
    }
  },
  methods: {
    // 回车事件
    submit: function submit(toPage, e) {
      // console.log('e.keyCode',toPage,e.keyCode)
      // key.Code === 13表示回车键 
      if (e.keyCode === 13) {
        //逻辑处理
        this.changePage(toPage);
      }
    },
    changePage: function changePage(idx) {
      if (idx != this.currentPage && idx > 0 && idx <= this.totalPage) {
        // 触发父组件事件  pageChange会转换成小写pagechange
        this.$emit('change', {
          curPage: Number(idx)
        });
      }
    }
  }
};