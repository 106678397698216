"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'machinehaltpage',
  data: function data() {
    return {
      haltDate: '',
      haltTime: ''
    };
  },
  created: function created() {
    this.haltDate = this.$route.query.haltDate ? this.$route.query.haltDate : '即日';
    this.haltTime = this.$route.query.haltTime ? this.$route.query.haltTime : '3';
  }
};