"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointmentFour = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-four.vue"));
var _default = exports.default = {
  components: {
    appointmentFour: _appointmentFour.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/logistics/banner-01.jpg")
      }],
      resolveList: [{
        title: '一键询价',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/01.png")
      }, {
        title: '在线找车',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/02.png")
      }, {
        title: 'TMS系统',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/03.png")
      }, {
        title: '安心保障',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/04.png")
      }, {
        title: '工作台',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/05.png")
      }, {
        title: '订单监控',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/06.png")
      }, {
        title: '货物跟踪',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/07.png")
      }, {
        title: '撮合系统',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/08.png")
      }, {
        title: '金融供应链',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/09.png")
      }, {
        title: '7*24小时客服',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/10.png")
      }],
      serviceList: [{
        title: '庞大的车源库',
        desc: '平台15万合作运力，200多万条车源路线积累，分布全国的联盟网点，地方解决能力强',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/11.png")
      }, {
        title: '强大的系统支持',
        desc: '智能配载系统，快速适配车源，全程定位导航系统，运输进度追踪，400万条MSDS信息库，保障运输规范',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/12.png")
      }, {
        title: '完善的线上交易流程',
        desc: '线上协议，保障车货双方权益；在途追踪，随时查看订单进度；车/货保险，防范高额损失；平台付款，保障资金安全',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/13.png")
      }, {
        title: '成熟的服务经验',
        desc: '重点客户4932家，覆盖全国482个城市，服务多个化工细分领域，解决疑难杂症',
        url: require("../../assets/static/img/softBusinessLogistics/logistics/14.png")
      }],
      programList: [{
        title: '全国大专线物流方案',
        desc: '危化镖局以200万条运力路线池为依托，精选辐射全国482个城市的干线网络形成点对点的专线运输网络，旨在解决货主用户不定期不定路线的货源运输需求。',
        header: '方案一'
      }, {
        title: '危险品零担物流方案',
        desc: '依托于危化镖局海量车货源，联合加盟物流网点的本地化优势，快速建立起全国零担干线网络。',
        header: '方案二',
        dot: ['依靠联盟网点在当地的落地配优势，建立起全国落地分拨网络;', '目前已覆盖华东、华南、华中落地分拨网点及零担专线，全国网络持续铺设中。']
      }, {
        title: '多式联运解决方案',
        desc: '联合国内一流空运、水运服务商，提供水、陆、空多式联运服务，优化运输组织，提升物流效率，有效降低运输成本。',
        header: '方案三',
        dot: ['提供鉴定报告、定制包装、前置仓、报关、清关、空运等国际危险品空运一站式运输解决方案；', '联合江苏、安徽、湖北、重庆等港务公司开展水陆、江海联运。每日班次，船期稳定。']
      }],
      SpecialList: [{
        title: '方案一：招投标运输服务',
        desc: '招标、投标双向对接，危化镖局有12000家优质承运商可以提供专业服务，并且有专门的TMS运输管理系统，能有效节省20%-30%的运输费用，为企业达成降本增效的目的。'
      }, {
        title: '方案二：危废处置解决方案',
        desc: '危化危废不分家，危化镖局能为产废方提供处置、运输一条龙服务。覆盖HW2-HW49类危废处置资质。全国合作的危废处置方有1000多家。'
      }, {
        title: '方案三：新能源运输解决方案',
        desc: '平台发挥资源整合优势，适配新能源运输车辆，优先调度回程车承运，降低运输成本，合理规划运输路线，实现多装多卸，除对操作人员一对一培训外，还对每票货物提供货运险、GPS在途轨迹检查等来保障货物安全运输。'
      }, {
        title: '方案四：仓配一体解决方案',
        desc: '联合合作方提供危险品仓储配送一体化服务，提供装卸、检验、储存、分拣、包装、盘点、再加工、代发货、配送等其他一整套物流服务。可以节省化工企业仓储成本以及配送的成本，有效提高存货周转率，实现降本增效。'
      }]
    };
  }
};