"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.replace");
require("core-js/modules/es6.regexp.split");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "mall-main bgc-FFFFFF"
  }, [_c("el-row", {
    staticClass: "flex-row-start margin-bottom-30"
  }, [_c("div", {
    staticClass: "back-btn font-20",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")])]), _c("el-row", [_c("div", {
    staticClass: "font-36 font-bold color-282828 text-align-left"
  }, [_vm._v(_vm._s(_vm.detailInfo.title))]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-20"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_vm.detailInfo.merchantStatus == "1" && _vm.detailInfo.trueFlag != "1" ? _c("img", {
    staticClass: "jingxuan margin-right-10",
    attrs: {
      src: require("../../../../assets/static/img/\u7CBE\u9009.png")
    }
  }) : _vm._e(), _vm.detailInfo.city || _vm.detailInfo.province ? _c("div", {
    staticClass: "text-tag font-22 margin-right-10 color-FD8B26",
    staticStyle: {
      border: "1px solid #FD8B26"
    }
  }, [_vm._v(" " + _vm._s(_vm.city) + " ")]) : _vm._e(), _vm.detailInfo.vehicleCondition ? _c("div", {
    staticClass: "text-tag font-22 margin-right-10",
    staticStyle: {
      border: "1px solid #DCDCDC"
    }
  }, [_vm._v(" " + _vm._s(_vm.detailInfo.vehicleCondition) + " ")]) : _vm._e()]), _c("div", {
    staticClass: "color-999999 font-20 font-weight-300"
  }, [_vm._v("发布于" + _vm._s(new Date(_vm.detailInfo.releaseTime).Format("yyyy年MM月dd日")))])]), _vm.detailInfo.price || _vm.detailInfo.leaveFactoryDate || _vm.detailInfo.mileage ? _c("div", {
    staticClass: "flex-row-start align-item-center bgc-f6f7f8 margin-top-35 font-12 border-box",
    staticStyle: {
      height: "120px",
      padding: "30px 0 35px 0"
    }
  }, [_vm.detailInfo.price ? _c("div", {
    staticClass: "text-align-left padding-left-50 padding-right-50 border-box",
    staticStyle: {
      "border-right": "1px solid #DCDCDC",
      width: "33.33%"
    },
    style: {
      borderRight: _vm.detailInfo.leaveFactoryDate ? " 1px solid #DCDCDC" : "none"
    }
  }, [_c("div", {
    staticClass: "font-20 font-weight-300 color-999999"
  }, [_vm._v("车辆售价")]), _c("div", {
    staticClass: "font-30 font-weight-500 color-FD8B26"
  }, [_vm._v(_vm._s(_vm.detailInfo.price) + "万元")])]) : _vm._e(), _vm.detailInfo.leaveFactoryDate ? _c("div", {
    staticClass: "text-align-left padding-left-50 padding-right-50 border-box",
    staticStyle: {
      width: "33.33%"
    },
    style: {
      borderRight: _vm.detailInfo.mileage ? " 1px solid #DCDCDC" : "none"
    }
  }, [_c("div", {
    staticClass: "font-20 font-weight-300 color-999999"
  }, [_vm._v("出厂年份")]), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222"
  }, [_vm._v(_vm._s(_vm.detailInfo.leaveFactoryDate))])]) : _vm._e(), _vm.detailInfo.mileage ? _c("div", {
    staticClass: "text-align-left padding-left-50 padding-right-50 border-box",
    style: {
      width: "33.33%"
    }
  }, [_c("div", {
    staticClass: "font-20 font-weight-300 color-999999"
  }, [_vm._v("行驶里程")]), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222"
  }, [_vm._v(_vm._s(_vm.detailInfo.mileage) + "万")])]) : _vm._e()]) : _vm._e(), _c("div", {
    staticClass: "font-30 font-weight-400 color-white flex-row-center align-item-center margin-top-40 radius-10",
    staticStyle: {
      background: "linear-gradient(90deg, #1377FF 0%, #6EB6FE 100%)",
      height: "80px"
    },
    on: {
      click: function click($event) {
        _vm.showAppDialog = true;
      }
    }
  }, [_vm._v("联系客服")])]), _c("el-row", [_c("el-divider"), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222 text-align-left margin-bottom-30"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "flex-row-start align-item-center flex-warp"
  }, [_vm.detailInfo.carType ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("车辆类型")]) : _vm._e(), _vm.detailInfo.carType ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.carType))]) : _vm._e(), _vm.detailInfo.horsepower ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("马力")]) : _vm._e(), _vm.detailInfo.horsepower ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.horsepower) + "马力")]) : _vm._e(), _vm.detailInfo.loadWeight ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("核定载重")]) : _vm._e(), _vm.detailInfo.loadWeight ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.loadWeight) + "吨")]) : _vm._e(), _vm.detailInfo.carLength ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("车长")]) : _vm._e(), _vm.detailInfo.carLength ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.carLength))]) : _vm._e(), _vm.detailInfo.cargoType ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("可承载货物类型")]) : _vm._e(), _vm.detailInfo.cargoType ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.cargoType.split(",").join("、")))]) : _vm._e(), _vm.detailInfo.emission ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("排放标准")]) : _vm._e(), _vm.detailInfo.emission ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.emission))]) : _vm._e(), _vm.detailInfo.brand ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("品牌")]) : _vm._e(), _vm.detailInfo.brand ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.brand == "其他" ? _vm.detailInfo.brandName : _vm.detailInfo.brand))]) : _vm._e(), _vm.detailInfo.address ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("看车地址")]) : _vm._e(), _vm.detailInfo.address ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.address.replace(/\//g, "")))]) : _vm._e(), _vm.detailInfo.carSeries ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("车系")]) : _vm._e(), _vm.detailInfo.carSeries ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.detailInfo.carSeries))]) : _vm._e()])], 1), _vm.detailInfo.content ? _c("el-row", [_c("el-divider"), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222 text-align-left margin-bottom-30"
  }, [_vm._v("卖家说")]), _c("div", {
    staticClass: "font-26 color-212121 text-align-left",
    domProps: {
      innerHTML: _vm._s(_vm.detailInfo.content)
    }
  })], 1) : _vm._e(), _vm.detailInfo && _vm.detailInfo.picUrl ? _c("el-row", _vm._l(_vm.subPicUrl(_vm.detailInfo.picUrl, false, false), function (item, index) {
    return _c("img", {
      key: index,
      staticClass: "width-percent100 margin-top-20 margin-bottom-20",
      attrs: {
        src: item,
        width: "1120"
      }
    });
  }), 0) : _vm._e()], 1), _c("el-dialog", {
    staticClass: "call-dialog",
    attrs: {
      visible: _vm.showAppDialog,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAppDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone"
  }, [_c("img", {
    attrs: {
      src: require("../../../../assets/static/img/\u8F66\u8F86\u4E70\u5356\u5BA2\u670D\u5F39\u7A97.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showAppDialog = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;