"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "chemicalStorage",
    style: {
      height: _vm.mainHeight
    },
    attrs: {
      id: "chemicalStorage"
    }
  }, [_c("div", {
    staticClass: "storage-content"
  }, [_vm._m(0), _vm._m(1), _c("merchantForm", {
    attrs: {
      plateCode: "CCZL"
    }
  }), _c("foot-nav", {
    ref: "foot"
  })], 1)]);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "top-banner"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/banner.jpg"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-column-center"
  }, [_c("div", {
    staticClass: "chemicalStorage-main flex-column-center"
  }, [_c("div", {
    staticClass: "area introduction"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("园区概况")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("鑫展旺物流园是漳州市目前唯一规划的化工集中储存和集散分拨中心，也是福建省首家专业的公用型化工品保税仓，占地约220亩，园区一期建有16栋甲、乙、丙类仓库以及66个50立方的储罐。可为化工生产、经营企业提供仓储、运输、分拨、管理等全方位服务。还可为广大化工品进出口贸易企业提供保税仓储、抽样、商检等服务。我们希望通过鑫展旺物流完善的化工“供应链一体化”标准服务，采用线上线下结合的新模式，协助客户整合采购、运输、仓储、销售、服务等各自独立环节，为用户实现商流、物流、信息流、资金流提供全方位的服务，助力客户业务发展。")])]), _c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/introduction.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area traffic"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("交通便利")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区位于素有“闽南金三角”之称的福建省漳州市芗城区。是福建省与国内外各大城市交通运输集散中心，也是一带一路的重要节点，周边6公里范围内有三个高速出入口，距厦门42公里（全程高速），距市区16公里，核心圈覆盖漳州、厦门、泉州、龙岩、福州、汕头等城市，可辐射至广东、浙江、江西等省市，地理区位优势明显。")])]), _c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/traffic.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area storage"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("仓储区")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区一期建有8栋甲类仓库、6栋乙类仓库、2栋丙类仓库。仓库全部采用单层建筑、有月台出入货很方便。室内最低处净高9米，具备使用多层货架堆放货物以提高货物储存量的条件，既可大大节约储存空间，降低储存成本，又便于进出货并提高工作效率；屋面采用双层保温设计，可降低室内温度保证产品质量；地面采用五层做法的绿色地坪漆面，美观、耐磨、不发火花，并针对不同类别的仓库配备不同的探测、预警、救灾等方面的设施。")])]), _c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/storage.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area cans"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("储罐区")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区一期建有50立方的甲乙类储罐46个、酸碱罐20个，全部采用单管单罐的方式作为储罐货物单独进出管路，可确保物料之间不相互污染；并且每个储罐都装有流量计，设计为上下两层分装装置，既有利于槽车和桶装两种模式的分装，又满足了客户不同包装的条件要求。分装区域采用耐磨、不发火花、导静电的地面；每个储罐均装有自动喷淋冷却系统，保持罐内恒温，可为产品质量保驾护航，而且每个罐和呼吸阀都有氮气，可大大减少货物的挥发损耗。")])]), _c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/can.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area transport"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("专业的运输服务")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区具备2、3、4、5、6、8、9类危化的运输资质，有专业的危化品运输车辆、证件齐全经验丰富的驾驶队伍、可确保客户货物运输的安全性与时效性。还可为客户提供由广东、福建、浙江、上海、江苏发往全国主要城市延伸的运输服务，并正在全国各大中城市布局落地配网点，通过分驳网点，高效、快捷的把货物集中管理并提供最后一公里的配送，为客户解决发货难、运输成本高的难题。")])]), _c("img", {
    attrs: {
      src: require("../../assets/static/img/chemical_storage/transport.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area protect"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("多样化安全保障措施")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区三面环山，拥有得天独厚的天然屏障，并运用智能化管理平台对进入园区储存的化工品进行自动库位分配、分类储存、分库管理、避免把互斥的产品存放在同一区域，大大减少了人工操作带来的失误，杜绝混放所导致的危险源，可为化工仓储提供安全保障。同时，园区投保了财产综合险，所有入驻的货物均列入保险的保障范围。")]), _c("p", [_vm._v("园区制定了保安管理制度、消控室管理制度、微型消防站管理及应急制度、岗位安全操作规程等配套管理制度，并对员工定时进行安全制度与安全操作培训，考核合格的员工方可正式上岗。")]), _c("p", [_vm._v("园区内建有应急指挥中心，微型消防站，配置了专业的消防设施和先进的探测系统、报警系统、装有近百个监控系统、并配备了消防水池、污水收集池、事故应急水池、缓冲池、危废暂存间、污水处理站等配套设施，可确保入驻用户的货物安全。")])]), _c("img", {
    staticClass: "protect-img",
    attrs: {
      src: require("../../assets/static/img/chemical_storage/product.jpg"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "area service"
  }, [_c("div", {
    staticClass: "title flex-column-alignc"
  }, [_c("div", [_vm._v("齐全的配套服务")]), _c("div", {
    staticClass: "sep"
  })]), _c("div", {
    staticClass: "content"
  }, [_c("p", [_vm._v("园区配套建设了危废暂存间、空桶棚、停车场、办公和生活区，可为化工经营企业提供涵盖甲、乙、丙类仓库租赁、储罐租赁、办公场所租赁等租赁服务；同时可为入驻园区的企业提供仓库及货物仓储设施、设备齐全，具备现场完成仓储、装卸、搬运、分拣的作业场地及托管、代收、发货、分拣、装卸、储罐分装、理货、贴标、运输、配送等操作服务：还可为部分有需求的客户提供代办危化品经营许可证、行业资格证、注册公司等增值服务，并提供保险、仓单质押、担保贷款等金融服务。")])]), _c("img", {
    staticClass: "service-img",
    attrs: {
      src: require("../../assets/static/img/chemical_storage/service.jpg"),
      alt: ""
    }
  })])])]);
}];
render._withStripped = true;