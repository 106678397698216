"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "n-content",
    attrs: {
      id: "msdsList"
    }
  }, [_c("div", {
    staticClass: "head"
  }, [_c("div", {
    staticClass: "head-content"
  }, [_c("div", {
    staticClass: "scroll_left"
  }, [_c("img", {
    attrs: {
      src: _vm.searchIcoSrc
    }
  })]), _c("div", {
    staticClass: "center flex-row-center"
  }, [_c("el-input", {
    staticClass: "input-with-select",
    attrs: {
      placeholder: "请输入cas号、化学式名称"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.toSearch.apply(null, arguments);
      }
    },
    model: {
      value: _vm.searchInput,
      callback: function callback($$v) {
        _vm.searchInput = $$v;
      },
      expression: "searchInput"
    }
  }, [_c("div", {
    staticClass: "flex-row-center",
    attrs: {
      slot: "append",
      icon: "el-icon-search"
    },
    slot: "append"
  }, [_c("a", {
    staticClass: "rl-white mouse-pointer",
    on: {
      click: _vm.toSearch
    }
  }, [_vm._v("搜索一下")])])])], 1)])]), _c("div", {
    staticClass: "body",
    style: _vm.isSearch == "true" ? "padding-top:84.33px;" : ""
  }, [_c("div", {
    staticClass: "body-content flex-row-justc"
  }, [_c("div", {
    staticClass: "n-center"
  }, [_c("list", {
    attrs: {
      colunm: "推荐",
      carouselData: _vm.carouselData,
      isNodata: _vm.isNodata,
      isNoMoredata: _vm.isNoMoredata,
      listData: _vm.listData,
      menuActive: _vm.menuActive
    },
    scopedSlots: _vm._u([{
      key: "listLi",
      fn: function fn(scope) {
        return _vm._l(_vm.listData.content, function (item, index) {
          return _c("li", {
            key: "list-" + index,
            staticClass: "list-rightImg font-16 mouse-pointer",
            on: {
              click: _vm.itemDetail
            }
          }, [_c("div", {
            staticClass: "felx-row-between"
          }, [_c("div", {
            staticClass: "list-txt text-align-left"
          }, [item.name ? _c("el-row", {
            staticClass: "list-title font-20 text-color-222 font-bold margin-bottom-10"
          }, [_vm._v(_vm._s(item.name))]) : _vm._e(), item.enAliasName ? _c("el-row", [_c("span", {
            staticClass: "text-color-222"
          }, [_vm._v("英文名称：")]), _c("span", {
            staticClass: "text-color-666"
          }, [_vm._v(_vm._s(item.enAliasName))])]) : _vm._e(), item.cnAliasName ? _c("el-row", [_c("span", {
            staticClass: "text-color-222"
          }, [_vm._v("中文别名：")]), _c("span", {
            staticClass: "text-color-666"
          }, [_vm._v(_vm._s(item.cnAliasName))])]) : _vm._e(), _c("el-row", {
            attrs: {
              gutter: 5
            }
          }, [item.cas ? _c("el-col", {
            attrs: {
              span: 8
            }
          }, [_c("span", {
            staticClass: "text-color-222"
          }, [_vm._v("CAS号：")]), _c("span", {
            staticClass: "text-color-666"
          }, [_vm._v(_vm._s(item.cas))])]) : _vm._e(), item.unCode ? _c("el-col", {
            attrs: {
              span: 8
            }
          }, [_c("span", {
            staticClass: "text-color-222"
          }, [_vm._v("UN号：")]), _c("span", {
            staticClass: "text-color-666"
          }, [_vm._v(_vm._s(item.unCode))])]) : _vm._e(), item.hazardCategory ? _c("el-col", {
            attrs: {
              span: 8
            }
          }, [_c("span", {
            staticClass: "text-color-222"
          }, [_vm._v("危险性类别：")]), _c("span", {
            staticClass: "text-color-666"
          }, [_vm._v(_vm._s(_vm.getHazardCategory(item.hazardCategory).txt))])]) : _vm._e()], 1)], 1), _c("div", {
            staticClass: "list-img"
          }, [item.hazardCategory ? _c("img", {
            attrs: {
              src: _vm.getHazardCategory(item.hazardCategory).src,
              alt: ""
            }
          }) : _vm._e()])]), _c("el-divider")], 1);
        });
      }
    }, {
      key: "listFooterBtn",
      fn: function fn(scope) {
        return [_c("el-button", {
          staticClass: "hasdata",
          staticStyle: {
            "margin-bottom": "56px"
          },
          attrs: {
            type: "primary"
          },
          on: {
            click: _vm.getMoreMsdsList
          }
        }, [_vm._v("阅读更多资讯")])];
      }
    }])
  })], 1), _c("div", {
    staticClass: "n-right"
  }, [_c("div", {
    staticClass: "n-right_con",
    staticStyle: {
      width: "100%",
      margin: "0 auto"
    }
  }, [_vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "one-" + index
    }, [item.showPosition == "RIGHT_ONE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan",
      staticStyle: {
        "margin-bottom": "34px"
      }
    }, [_c("a", {
      attrs: {
        target: "_blank",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "two-" + index
    }, [item.showPosition == "RIGHT_TWO" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: "_blank",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "three-" + index
    }, [item.showPosition == "RIGHT_THREE" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: "_blank",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  }), _c("div", {
    staticClass: "news_right_box news_right_list"
  }, [_c("el-card", {
    staticClass: "box-card"
  }, [_c("div", {
    staticClass: "flex-row-sb align-item-center",
    attrs: {
      slot: "header"
    },
    slot: "header"
  }, [_c("span", {
    staticClass: "padding-left-20 divider flex-row-start align-item-center"
  }, [_vm._v("热门资讯")]), _c("router-link", {
    staticClass: "hotspot_more left flex-row-center mouse-pointer",
    attrs: {
      target: "_blank",
      to: {
        name: "newslist",
        query: {
          isSearch: false,
          searchVal: "",
          isDetail: false
        }
      }
    }
  }, [_c("img", {
    staticClass: "width-14 margin-right-5",
    attrs: {
      src: require("../../assets/static/img/news/03.png")
    }
  }), _vm._v(" 换一批 ")])], 1), _vm._l(_vm.hotspotData, function (hitem, hindex) {
    return _c("div", {
      key: hindex,
      staticClass: "item minortint flex-row-alignc"
    }, [_c("router-link", {
      directives: [{
        name: "track-event",
        rawName: "v-track-event",
        value: {
          category: "最新资讯列表",
          action: "点击",
          opt_label: hitem.articleTitle
        },
        expression: "{category:'最新资讯列表', action:'点击', opt_label:hitem.articleTitle}"
      }],
      staticClass: "hotspot_link flex-row-alignc",
      attrs: {
        target: "_blank",
        to: {
          name: "newslist",
          query: {
            menuActive: _vm.menuActive,
            isDetail: true,
            isSearch: false,
            cmmid: 0,
            searchVal: "",
            ammid: hitem.ammId
          }
        }
      }
    }, [_c("span", {
      staticClass: "hotspot_title ellipsis mouse-pointer"
    }, [_vm._v(_vm._s(hitem.articleTitle))])])], 1);
  }), _vm.hotspotData.length == 0 ? _c("div", {
    staticClass: "item minortint flex-row-alignc"
  }, [_c("div", {
    staticClass: "ellipsis center",
    staticStyle: {
      width: "100%",
      "text-align": "center"
    }
  }, [_vm._v(" 暂无资讯~ ")])]) : _vm._e()], 2)], 1), _vm._l(_vm.advertData, function (item, index) {
    return _c("div", {
      key: "four-" + index
    }, [item.showPosition == "RIGHT_FOUR" ? _c("div", {
      staticClass: "news_right_box news_right_slogan"
    }, [_c("a", {
      attrs: {
        target: "_blank",
        href: item.ordinaryJumpUrl ? item.ordinaryJumpUrl : "javascript:void(0);"
      }
    }, [_c("img", {
      staticClass: "news_right_img",
      attrs: {
        src: item.advertOssUrl
      }
    })])]) : _vm._e()]);
  })], 2)])])]), _c("el-dialog", {
    staticClass: "call-dialog",
    attrs: {
      visible: _vm.showAppDialog,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAppDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone"
  }, [_c("img", {
    attrs: {
      src: require("../../assets/static/img/common/appdownload.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showAppDialog = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])]), _c("footNav"), _c("sideNav", {
    attrs: {
      side_bind: "msdsList"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;