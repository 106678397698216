"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-row-sb align-item-center"
  }, [_vm._m(0), _c("div", {
    staticClass: "width-560"
  }, [_c("div", {
    staticClass: "grid-content bg-purple-light pop_right",
    staticStyle: {
      display: "inline-block"
    },
    attrs: {
      id: "login_content"
    }
  }, [_c("el-tabs", {
    attrs: {
      stretch: true
    },
    model: {
      value: _vm.tabName,
      callback: function callback($$v) {
        _vm.tabName = $$v;
      },
      expression: "tabName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "短信登录",
      name: "first"
    }
  }, [_c("messageLogin")], 1), _c("el-tab-pane", {
    attrs: {
      label: "密码登录",
      name: "second"
    }
  }, [_c("passwordLogin")], 1)], 1)], 1)]), _c("protocol")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "width-440 height-620"
  }, [_c("div", {
    staticClass: "grid-content bg-purple flex-colmun-center pop_left"
  }, [_c("img", {
    staticClass: "pop_logo",
    attrs: {
      src: require("../../assets/static/img/pop_logo.jpg"),
      alt: ""
    }
  })])]);
}];
render._withStripped = true;