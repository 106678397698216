"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.regexp.replace");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("div", {
    staticClass: "mall-main bgc-FFFFFF"
  }, [_c("el-row", {
    staticClass: "flex-row-start margin-bottom-30"
  }, [_c("div", {
    staticClass: "back-btn font-20",
    on: {
      click: function click($event) {
        return _vm.$router.go(-1);
      }
    }
  }, [_vm._v(" 返回 ")])]), _c("el-row", [_c("div", {
    staticClass: "font-36 font-bold color-282828 text-align-left"
  }, [_vm._v(_vm._s(_vm.qzDetail.contactPerson || "") + _vm._s(_vm.qzDetail.jobStatus || "离职-随时到岗"))]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-20"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_vm.qzDetail.merchantStatus == "1" && _vm.qzDetail.trueFlag != "1" ? _c("img", {
    staticClass: "jingxuan margin-right-10",
    attrs: {
      src: require("../../../../assets/static/img/\u7CBE\u9009.png")
    }
  }) : _vm._e(), _vm.qzDetail.city || _vm.qzDetail.province ? _c("div", {
    staticClass: "text-tag font-22 margin-right-10 color-FD8B26",
    staticStyle: {
      border: "1px solid #FD8B26"
    }
  }, [_vm._v(" " + _vm._s(_vm.city) + " ")]) : _vm._e()]), _c("div", {
    staticClass: "color-999999 font-20 font-weight-300"
  }, [_vm._v("发布于" + _vm._s(new Date(_vm.qzDetail.releaseTime).Format("yyyy年MM月dd日")))])]), _c("div", {
    staticClass: "font-30 font-weight-400 color-white flex-row-center align-item-center margin-top-40 radius-10",
    staticStyle: {
      background: "linear-gradient(90deg, #1377FF 0%, #6EB6FE 100%)",
      height: "80px"
    },
    on: {
      click: function click($event) {
        _vm.showAppDialog = true;
      }
    }
  }, [_vm._v("联系客服咨询")])]), _c("el-row", [_c("el-divider"), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222 text-align-left margin-bottom-30"
  }, [_vm._v("基本信息")]), _c("div", {
    staticClass: "flex-row-start align-item-center flex-warp"
  }, [_vm.qzDetail.postName || _vm.qzDetail.post ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("期望职位")]) : _vm._e(), _vm.qzDetail.postName || _vm.qzDetail.post ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.qzDetail.post == "其他" ? _vm.qzDetail.postName : _vm.qzDetail.post) + " ")]) : _vm._e(), _c("div", {
    staticClass: "table-title"
  }, [_vm._v("期望薪资")]), _vm.qzDetail.monthlySalary ? _c("div", {
    staticClass: "table-content line-clamp1"
  }, [_vm._v(_vm._s(_vm.qzDetail.monthlySalary || "面议"))]) : _vm._e(), _vm.qzDetail.intendAddress ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("意向工作地点")]) : _vm._e(), _vm.qzDetail.intendAddress ? _c("div", {
    staticClass: "table-content line-clamp1",
    staticStyle: {
      width: "916px"
    }
  }, [_vm._v(_vm._s(_vm.qzDetail.intendAddress.replace(/\//g, "").replace(/,/g, "、")))]) : _vm._e(), _vm.qzDetail.welfare ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("福利待遇要求")]) : _vm._e(), _vm.qzDetail.welfare ? _c("div", {
    staticClass: "table-content line-clamp1",
    staticStyle: {
      width: "916px"
    }
  }, [_vm._v(_vm._s(_vm.qzDetail.welfare))]) : _vm._e(), _vm.qzDetail.certificate ? _c("div", {
    staticClass: "table-title"
  }, [_vm._v("从业证书")]) : _vm._e(), _vm.qzDetail.certificate ? _c("div", {
    staticClass: "table-content line-clamp1",
    staticStyle: {
      width: "916px"
    }
  }, [_vm._v(_vm._s(_vm.qzDetail.certificate))]) : _vm._e()])], 1), _vm.qzDetail.content ? _c("el-row", [_c("el-divider"), _c("div", {
    staticClass: "font-30 font-weight-500 color-222222 text-align-left margin-bottom-30"
  }, [_vm._v("个人简介")]), _c("div", {
    staticClass: "font-26 color-212121 text-align-left",
    domProps: {
      innerHTML: _vm._s(_vm.qzDetail.content)
    }
  })], 1) : _vm._e(), _vm.detailInfo && _vm.detailInfo.picUrl ? _c("el-row", _vm._l(_vm.subPicUrl(_vm.detailInfo.picUrl, false, false), function (item, index) {
    return _c("img", {
      key: index,
      staticClass: "width-percent100 margin-top-20 margin-bottom-20",
      attrs: {
        src: item,
        width: "1120"
      }
    });
  }), 0) : _vm._e()], 1), _c("el-dialog", {
    staticClass: "call-dialog",
    attrs: {
      visible: _vm.showAppDialog,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showAppDialog = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone"
  }, [_c("img", {
    attrs: {
      src: require("../../../../assets/static/img/\u62DB\u8058\u6C42\u804C\u5BA2\u670D\u5F39\u7A97.png"),
      alt: ""
    }
  })]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showAppDialog = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;