"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: "downguide",
  data: function data() {
    return {
      isDown: true
    };
  },
  mounted: function mounted() {
    //对ref为content的dom添加滑动监听
    var that = this;
    that.$parent.$refs.content.addEventListener('scroll', that.throttle(that.handleScroll));
  },
  methods: {
    //监听滑动调用的函数
    throttle: function throttle(fn) {
      var interval = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 300;
      var that = this;
      var canRun = true;
      return function () {
        var _arguments = arguments;
        if (!canRun) return;
        canRun = false;
        setTimeout(function () {
          fn.apply(that, _arguments);
          canRun = true;
        }, interval);
      };
    },
    handleScroll: function handleScroll() {
      var that = this;
      var scrollTop = that.$parent.$refs.content.scrollTop;
      var viewHeight = document.documentElement.clientHeight;
      var scrollHeight = that.$parent.$refs.content.scrollHeight;
      // 向下引导箭头
      if (viewHeight + viewHeight * 0.3 + scrollTop >= scrollHeight) {
        that.isDown = false;
      } else {
        that.isDown = true;
      }
    }
  }
};