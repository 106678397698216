"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "paging clearfix"
  }, [_c("ul", {
    staticClass: "page-list",
    class: _vm.pagingPosition ? _vm.pagingPosition + " clearfix" : "left clearfix"
  }, [_c("li", {
    on: {
      click: function click($event) {
        return _vm.changePage(_vm.currentPage - 1);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-left"
  })]), _vm._l(_vm.pagelist, function (item) {
    return _c("li", {
      class: {
        active: _vm.currentPage == item.val
      },
      domProps: {
        textContent: _vm._s(item.text)
      },
      on: {
        click: function click($event) {
          return _vm.changePage(item.val);
        }
      }
    }, [_vm._v("1")]);
  }), _c("li", {
    on: {
      click: function click($event) {
        return _vm.changePage(_vm.currentPage + 1);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-right"
  })])], 2)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;