"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("vue-seamless-scroll", {
    staticClass: "seamless-warp2",
    attrs: {
      data: _vm.listData,
      "class-option": _vm.classOption
    }
  }, [_c("ul", {
    staticClass: "item"
  }, _vm._l(_vm.listData, function (item, index) {
    return _c("li", {
      key: index
    }, [_c("img", {
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    }), _c("div", {
      staticClass: "font-16 color-212121"
    }, [_vm._v(_vm._s(item.title))])]);
  }), 0)]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;