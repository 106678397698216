"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appointment = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _default = exports.default = {
  components: {
    appointment: _appointment.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        path: 'TMS',
        url: require("../../assets/static/img/softBusinessLogistics/software/banner-01.jpg")
      }, {
        path: 'WMS',
        url: require("../../assets/static/img/softBusinessLogistics/WMS/banner-01.jpg")
      }, {
        path: 'wisdom5G',
        url: require("../../assets/static/img/softBusinessLogistics/wisdom5G/banner-01.jpg")
      }, {
        path: 'chain',
        url: require("../../assets/static/img/softBusinessLogistics/chain/banner-01.jpg")
      }]
    };
  },
  methods: {
    toPage: function toPage(name) {
      this.$router.push({
        name: name
      });
    }
  }
};