"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
var _typeof = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/typeof.js");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.weak-map");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
var _util = _interopRequireWildcard(require("../../assets/static/js/utils/util"));
var _mallImgs = _interopRequireDefault(require("../../assets/static/js/cooperationMall/mallImgs"));
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && {}.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
var _default = exports.default = {
  name: "leftImgListRow",
  props: {
    rowData: {
      type: Object,
      require: true,
      default: Object
    },
    randomIndex: {
      type: Number,
      required: true,
      default: Number
    },
    activeClassifyItem: {
      type: Object,
      require: true,
      default: Object
    }
  },
  data: function data() {
    return {};
  },
  computed: {
    img: function img() {
      var imgSrc = null;
      if (this.rowData && this.rowData.picUrl) {
        var imgArr = (0, _util.subPicUrl)(this.rowData.picUrl);
        imgSrc = imgArr && imgArr.length > 0 ? imgArr[0] : null;
      } else if (this.getMallImg(this.rowData)) {
        imgSrc = this.getMallImg(this.rowData);
      }
      return imgSrc;
    },
    // 车辆类型/行车里程/车龄
    secondDesc: function secondDesc() {
      if (this.rowData) {
        var carType = this.rowData.carType ? this.rowData.carType : '';
        var mileage = this.rowData.mileage ? this.rowData.mileage + '万公里' : '';
        var purchaseTimeType = this.rowData.purchaseTimeType ? this.rowData.purchaseTimeType : '';
        var arr;
        // 买车
        if (this.activeClassifyItem.classifyCode == 'MMC') arr = new Array(carType, purchaseTimeType);
        if (this.activeClassifyItem.classifyCode == 'MC') arr = new Array(carType, mileage);
        return arr.notEmpty().join('·');
      }
    },
    city: function city() {
      if (this.rowData) {
        if (this.rowData.city && this.rowData.city.indexOf('全省') < 0) {
          return this.rowData.city;
        } else {
          return this.rowData.province;
        }
      }
    }
  },
  filters: {
    time: function time(val) {
      return _util.default.timeToString(val);
    }
  },
  created: function created() {},
  methods: {
    // 超出省略号展示
    txtEllipsis: _util.txtEllipsis,
    // 获取随机色
    getRandomColor: _util.getRandomColor,
    // 取出对应分类的默认图
    getMallImg: function getMallImg(data) {
      var mallImg = '';
      if ((!data.picUrl || data.picUrl == '{}') && this.rowData.merchantStatus == '1') {
        // 精选商户信息使用
        _mallImgs.default.data.filter(function (item) {
          if (data.classifyCode == item.classifyCode || data.classifyName == item.classifyName) {
            mallImg = item.img;
          }
        });
      }
      return mallImg;
    }
  }
};