"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.rowData ? _c("div", {
    staticClass: "leftImgListRow padding-top-5 padding-bottom-5"
  }, [_c("div", {
    staticClass: "left-img inline-block vertical-top overflow-hidden"
  }, [_vm.img ? _c("el-image", {
    staticClass: "left-img radius-8 text-align-center overflow-hidden",
    attrs: {
      src: _vm.img,
      fit: "cover"
    }
  }, [_c("div", {
    staticClass: "left-img radius-8 font-16 color-999999",
    attrs: {
      slot: "placeholder"
    },
    slot: "placeholder"
  }, [_vm._v(" 加载中"), _c("span", {
    staticClass: "dot"
  }, [_vm._v("...")])]), _c("div", {
    staticClass: "left-img inline-block font-30 radius-8",
    attrs: {
      slot: "error"
    },
    slot: "error"
  }, [_c("i", {
    staticClass: "el-icon-picture-outline"
  })])]) : _c("div", {
    staticClass: "left-img radius-8 text-align-center overflow-hidden inline-block color-white font-40",
    style: "background-color:" + _vm.getRandomColor(_vm.randomIndex)
  }, [_vm._v(" " + _vm._s(_vm.txtEllipsis(_vm.rowData.classifyName, 4)) + " ")])], 1), _c("div", {
    staticClass: "right-txt inline-block margin-left-20 vertical-top"
  }, [_c("div", {
    staticClass: "width-full font-20 color-222222 font-bold ellipsis"
  }, [_vm._v(_vm._s(_vm.rowData.title || ""))]), _c("el-row", {
    staticClass: "margin-top-15 font-16"
  }, [_c("el-col", {
    staticClass: "min-height-1 color-666666 ellipsis",
    attrs: {
      span: 16
    }
  }, [_vm._v(_vm._s(_vm.secondDesc || _vm.rowData.content || ""))]), _vm.rowData.releaseTime ? _c("el-col", {
    staticClass: "text-align-right color-999999",
    attrs: {
      span: 8
    }
  }, [_vm._v(_vm._s(_vm._f("time")(_vm.rowData.releaseTime)))]) : _vm._e()], 1), _c("div", {
    staticClass: "color-F75923 font-20"
  }, [_vm._v(_vm._s(_vm.rowData.price ? _vm.rowData.price + "万元" : ""))]), _c("div", {
    staticClass: "margin-top-10"
  }, [_vm.rowData.merchantStatus == "1" ? _c("span", {
    staticClass: "inline-block padding-top-1 padding-bottom-1 padding-left-10 padding-right-10 vertical-middle color-white font-14 bgc-linear-FF332D-FF8450 margin-right-10 radius-4"
  }, [_vm._v("精选")]) : _vm._e(), _vm.rowData.city || _vm.rowData.province ? _c("span", {
    staticClass: "inline-block padding-left-10 padding-right-10 vertical-middle color-white font-14 bgc-transparent color-FF7814 border-solid margin-right-10 radius-4",
    staticStyle: {
      "border-color": "rgba(255,120,20,.5)"
    }
  }, [_vm._v(_vm._s(_vm.city))]) : _vm._e(), _vm.rowData.vehicleCondition ? _c("span", {
    staticClass: "inline-block padding-left-10 padding-right-10 vertical-middle color-white font-14 bgc-transparent color-666666 border-solid radius-4",
    staticStyle: {
      "border-color": "rgba(102,102,102,.5)"
    }
  }, [_vm._v(_vm._s(_vm.rowData.vehicleCondition))]) : _vm._e()])], 1)]) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;