"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.rowData ? _c("div", {
    staticClass: "avatarListRow",
    on: {
      click: _vm.toDetail
    }
  }, [_c("el-row", [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("div", {
    staticClass: "ellipsis"
  }, [_c("el-col", {
    attrs: {
      span: 18
    }
  }, [_c("span", {
    staticClass: "inline-block font-20 color-222222 font-bold"
  }, [_vm._v(_vm._s(_vm.rowData.contactPerson || ""))]), _c("span", {
    staticClass: "font-16 color-222222 margin-left-10"
  }, [_vm._v(_vm._s(_vm.rowData.jobStatus || "离职-随时到岗"))])])], 1), _vm.rowData.postNature || _vm.rowData.workTime || _vm.rowData.birthDate || _vm.rowData.certificate ? _c("div", {
    staticClass: "width-full line-height-1 font-16 color-666666 vertical-middle margin-top-10 overflow-hidden"
  }, [_vm._v(_vm._s(_vm.desc))]) : _c("div", {
    staticClass: "width-full line-height-normal font-16 color-666666 vertical-middle margin-top-10"
  }, [_vm._v(_vm._s(_vm.rowData.content))]), _vm.rowData.post || _vm.rowData.monthlySalary ? _c("div", {
    staticClass: "width-full margin-top-5"
  }, [_c("img", {
    staticClass: "post-icon inline-block margin-right-5",
    attrs: {
      src: require("../../assets/static/img/cooperationMall/post_ico_gray.png")
    }
  }), _c("span", {
    staticClass: "font-18 color-212121 vertical-middle"
  }, [_vm._v(" " + _vm._s(_vm.rowData.post == "其他" ? _vm.rowData.postName : _vm.rowData.post) + " "), _vm.rowData.post ? [_vm._v("·")] : _vm._e(), _vm._v(" " + _vm._s(_vm.rowData.monthlySalary ? _vm.rowData.monthlySalary : "面议"))], 2)]) : _vm._e()]), _c("el-col", {
    staticClass: "text-align-right",
    attrs: {
      span: 6
    }
  }, [_c("div", {
    staticClass: "relative radius-circle inline-block"
  }, [_c("el-avatar", {
    key: _vm.img,
    staticClass: "overflow-hidden vertical-middle",
    attrs: {
      src: _vm.img ? _vm.img[0] : _vm.avatarNormalIco,
      fit: "cover",
      size: 70
    },
    on: {
      error: true
    }
  }, [_c("img", {
    attrs: {
      src: "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
    }
  })]), _c("el-avatar", {
    staticClass: "sex-icon absolute-top",
    class: _vm.rowData.sex == "1" ? "bgc-3396FB" : "bgc-ff6969",
    attrs: {
      size: 22
    },
    on: {
      error: true
    }
  }, [_c("img", {
    key: _vm.rowData.sex,
    staticClass: "inline-block margin-auto",
    attrs: {
      src: _vm.rowData.sex == "1" ? _vm.boyIcon : _vm.girlIcon,
      alt: ""
    }
  })])], 1)])], 1), _c("el-row", {
    staticClass: "margin-top-20"
  }, [_c("el-col", {
    staticClass: "vertical-middle",
    attrs: {
      span: 18
    }
  }, [_vm.rowData.merchantStatus == "1" ? _c("span", {
    staticClass: "inline-block padding-top-1 padding-bottom-1 padding-left-10 padding-right-10 vertical-middle color-white font-14 bgc-linear-FF332D-FF8450 margin-right-10 radius-4"
  }, [_vm._v("精选")]) : _vm._e(), _vm.rowData.city || _vm.rowData.province ? _c("span", {
    staticClass: "inline-block padding-left-10 padding-right-10 vertical-middle color-white font-14 bgc-transparent color-FF7814 border-solid radius-4",
    staticStyle: {
      "border-color": "rgba(255,120,20,.5)"
    }
  }, [_vm._v(_vm._s(_vm.city))]) : _vm._e()]), _c("el-col", {
    staticClass: "text-align-right font-16 color-999999 vertical-middle",
    attrs: {
      span: 6
    }
  }, [_vm._v(_vm._s(_vm.time))])], 1)], 1) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;