"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointment = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment.vue"));
var _default = exports.default = {
  components: {
    appointment: _appointment.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/chain/banner-01.jpg")
      }],
      deviceList: [{
        url: require("../../assets/static/img/softBusinessLogistics/chain/08.png"),
        title: '自主研发微功耗传感器技术',
        desc: '超低功耗有机气体传感，自主传感材料核心技术，可实现多种VOCs气体监测',
        bgColor: 'rgba(39, 106, 239, 0.1)',
        textLineColor: 'rgba(39, 106, 239, 1)'
      }, {
        url: require("../../assets/static/img/softBusinessLogistics/chain/09.png"),
        title: '低功耗设计和无线通讯',
        desc: '整机采用低功耗设计，低功耗无线通讯，无需布线，大幅缩短安装调试时间',
        bgColor: 'rgba(84, 186, 158, 0.1)',
        textLineColor: 'rgba(84, 186, 158, 1)'
      }, {
        url: require("../../assets/static/img/softBusinessLogistics/chain/10.png"),
        title: '大数据平台',
        desc: '现场的浓度数据和报警信息传输至大数据平台，可通过电脑端、中控系统查看当前浓度值，分析浓度变化和预测等功能',
        bgColor: 'rgba(39, 106, 239, 0.1)',
        textLineColor: 'rgba(39, 106, 239, 1)'
      }, {
        url: require("../../assets/static/img/softBusinessLogistics/chain/10.png"),
        title: '设备微型化',
        desc: '简洁的外观设计和轻巧的体积，可以安装在多种狭窄的空间，无需外置电源布线，节省施工和安装成本',
        bgColor: 'rgba(84, 186, 158, 0.1)',
        textLineColor: 'rgba(84, 186, 158, 1)'
      }]
    };
  }
};