import { render, staticRenderFns } from "./wisdom5G.vue?vue&type=template&id=56d34073&scoped=true"
import script from "./wisdom5G.vue?vue&type=script&lang=js"
export * from "./wisdom5G.vue?vue&type=script&lang=js"
import style0 from "./wisdom5G.vue?vue&type=style&index=0&id=56d34073&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56d34073",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\vescort-platform-pc-website\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d34073')) {
      api.createRecord('56d34073', component.options)
    } else {
      api.reload('56d34073', component.options)
    }
    module.hot.accept("./wisdom5G.vue?vue&type=template&id=56d34073&scoped=true", function () {
      api.rerender('56d34073', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/softBusinessLogistics/wisdom5G.vue"
export default component.exports