"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "trade-nav"
  }, [_c("img", {
    staticClass: "height-54",
    attrs: {
      src: require("../../assets/static/img/trade/02.png")
    }
  }), _c("div", {
    staticClass: "margin-top-60 flex-row-center align-item-center"
  }, [_c("div", {
    staticClass: "data-item flex-col-center"
  }, [_c("span", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v(_vm._s(_vm.detailData.goodsSourceTotal) + "万")]), _c("span", {
    staticClass: "font-16",
    staticStyle: {
      color: "#ffffffe6"
    }
  }, [_vm._v("货源总数")])]), _c("div", {
    staticClass: "data-item flex-col-center"
  }, [_c("span", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v(_vm._s(_vm.detailData.vehicleSourceTotal) + "万")]), _c("span", {
    staticClass: "font-16",
    staticStyle: {
      color: "#ffffffe6"
    }
  }, [_vm._v("车源总数")])]), _c("div", {
    staticClass: "data-item flex-col-center"
  }, [_c("span", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v(_vm._s(_vm.detailData.informationTotal) + "万条")]), _c("span", {
    staticClass: "font-16",
    staticStyle: {
      color: "#ffffffe6"
    }
  }, [_vm._v("资讯总数")])]), _c("div", {
    staticClass: "data-item flex-col-center"
  }, [_c("span", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v(_vm._s(_vm.detailData.gsSameDayUpdateCount))]), _c("span", {
    staticClass: "font-16",
    staticStyle: {
      color: "#ffffffe6"
    }
  }, [_vm._v("24小时更新数")])]), _c("div", {
    staticClass: "data-item flex-col-center"
  }, [_c("span", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v(_vm._s(_vm.detailData.freightValuationTotal) + "亿")]), _c("span", {
    staticClass: "font-16",
    staticStyle: {
      color: "#ffffffe6"
    }
  }, [_vm._v("运费估值")])])])]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;