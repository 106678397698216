"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointmentTwo = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-two.vue"));
var _default = exports.default = {
  components: {
    appointmentTwo: _appointmentTwo.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/TMS/banner-01.jpg")
      }],
      functionList: [{
        title: '智能配置',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/01.png"),
        desc: '解决混载、空载的问题，告别运力浪费，提升企业利润'
      }, {
        title: '在途管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/02.jpg"),
        desc: '有效针对车辆状况、司机状态、运输过程进行全程跟踪，掌控安全，降低事故率'
      }, {
        title: '司机管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/03.jpg"),
        desc: '人车分离，司机动态更新，责任到位，加强企业运输效率'
      }, {
        title: '运费测算',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/04.jpg"),
        desc: '运费一键导入，随时修改，全员均可查询，提升员工报价速度和准确度，保障每一单的利润'
      }, {
        title: '订单管理',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/05.jpg"),
        desc: '打理所有订单，无缝对接货主、司机、承运商，条目清晰，收支明白'
      }, {
        title: '保证金机制',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/06.jpg"),
        desc: '对于交易大厅中的订单推出保证金机制，保障企业的权益'
      }],
      projectList: [{
        title: '车货源云端共享',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/07.jpg"),
        desc: '无法处理的货源或多余的运力可以即时分享到交易大厅，通过云端大数据进行智能调度，帮企业解决货源配载难题和货车空载运力浪费的问题。'
      }, {
        title: '代招投标服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/08.jpg"),
        desc: '企业提供路线运费报价，如有适合的化工企业进行招投标，企业会被优先推荐匹配，快速帮企业获得一手货源订单。'
      }, {
        title: '落地配联盟',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/09.jpg"),
        desc: '落地配联盟成员享有更为专业的OTMS，联盟内成员资源进行免费共享，借助系统后台可快速链接，实现降本增效！'
      }, {
        title: '软件服务',
        imgUrl: require("../../assets/static/img/softBusinessLogistics/TMS/10.jpg"),
        desc: '根据企业不同需求，有专业技术团队根据企业的业务流程开发定制化TMS，有效帮助企业提升运输效率和降低运营成本。'
      }]
    };
  }
};