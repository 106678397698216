"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _appointmentFive = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-five.vue"));
var _default = exports.default = {
  components: {
    appointmentFive: _appointmentFive.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/finance/banner-01.jpg")
      }],
      serviceList: [{
        title: '合法合规',
        desc: '严格按照税务流程，业务全流程合法合规，安全有保障',
        url: require("../../assets/static/img/softBusinessLogistics/finance/01.png")
      }, {
        title: '资金安全',
        desc: '签订正式合同协议，平台结算回款，最快当日可回款',
        url: require("../../assets/static/img/softBusinessLogistics/finance/02.png")
      }, {
        title: '管理费低',
        desc: '享受政府财税政策福利，大幅度降低企业成本',
        url: require("../../assets/static/img/softBusinessLogistics/finance/03.png")
      }, {
        title: '服务高效',
        desc: '专业商务人员一对一服务，快速解决您的需求',
        url: require("../../assets/static/img/softBusinessLogistics/finance/04.png")
      }]
    };
  }
};