"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appointmentFive = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment-five.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _default = exports.default = {
  components: {
    appointmentFive: _appointmentFive.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/insurance/banner-01.jpg")
      }],
      insuranceList: [{
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/04.jpg")
      }, {
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/05.jpg")
      }, {
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/06.jpg")
      }, {
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/07.jpg")
      }, {
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/08.jpg")
      }, {
        imgUrl: require("../../assets/static/img/softBusinessLogistics/insurance/09.jpg")
      }]
    };
  }
};