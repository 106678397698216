"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "reg_content",
    staticStyle: {
      "overflow-x": "hidden",
      "overflow-y": "auto",
      "scroll-behavior": "smooth"
    }
  }, [_c("el-container", {
    staticStyle: {
      "min-width": "1190px",
      "overflow-x": "hidden"
    }
  }, [_c("el-header", {
    attrs: {
      height: "100px"
    }
  }, [_c("regHeader")], 1), _c("el-main", [_c("div", {
    staticClass: "reg_bg",
    style: "background-image:url(" + _vm.bgSrc + ");"
  }, [_c("img", {
    staticStyle: {
      opacity: "0"
    },
    attrs: {
      src: _vm.bgSrc,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "reg_main"
  }, [_c("div", {
    staticClass: "reg_main_content"
  }, [_c("regCarousel", {
    attrs: {
      indicatorPosition: "outside",
      carouselWidth: _vm.carouselWidth,
      carouselHeight: "520px",
      carouselData: _vm.carouselData
    }
  }), _c("regLogin", {
    attrs: {
      loginType: "REG_PAGE",
      landingPageJumpLink: _vm.landingPageJumpLink,
      userId: _vm.userId,
      pageId: _vm.pageId,
      template: _vm.template,
      uuid: _vm.uuid
    }
  })], 1)])]), _c("el-footer", {
    attrs: {
      height: "165px"
    }
  }, [_c("regFooter")], 1)], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;