"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = _interopRequireDefault(require("../../assets/static/js/utils/util"));
var _default = exports.default = {
  name: "listRow",
  props: {
    rowData: {
      type: Object,
      require: true,
      default: Object
    }
  },
  data: function data() {
    return {
      avatarNormalIco: require("../../assets/static/img/commonAvatar/avatar_\u9556\u5E08.jpg")
    };
  },
  filters: {
    timeFilter: function timeFilter(time) {
      if (time) {
        return _util.default.timeToString(time);
      }
    },
    city: function city(rowData) {
      if (rowData) {
        if (rowData.city && rowData.city.indexOf('全省') < 0) {
          return rowData.city;
        } else {
          return rowData.province;
        }
      }
    }
  },
  created: function created() {},
  methods: {}
};