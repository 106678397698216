"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _util = _interopRequireDefault(require("../../assets/static/js/utils/util.js"));
var _cookie = require("@/assets/static/js/utils/cookie.js");
var _default = exports.default = {
  props: {
    sourceData: Array,
    sortConfig: Object
  },
  inject: ['reload'],
  computed: {
    listData: function listData() {
      this.sourceData.filter(function (item) {
        item.arrivalTime = _util.default.timeToDate(item.arrivalTime, 'YY-MM-dd');
        item.releaseTime = _util.default.timeToString(item.releaseTime);
        item.joinedStart = _util.default.joinCityAndDistrict(item.startCity, item.startDistrict, '/');
        item.joinedEnd = _util.default.joinCityAndDistrict(item.endCity, item.endDistrict, '/');
      });
      return this.sourceData;
    }
  },
  created: function created() {
    //  console.log('sourceData', this.sourceData);
  },
  methods: {
    tableRowStyle: function tableRowStyle(_ref) {
      var row = _ref.row,
        column = _ref.column,
        rowIndex = _ref.rowIndex,
        columnIndex = _ref.columnIndex;
      if (columnIndex === 4) {
        //第3列字体颜色
        return 'color: #FD9E31;!important;';
      }
      if (columnIndex === 6) {
        return 'border-right:1px solid #DDEAFA !important';
      }
      if (columnIndex === 0) {
        return 'border-left: 1px solid #DDEAFA !important;';
      }
    },
    // 跳转详情
    clickToDetail: function clickToDetail(gsid) {
      var _this = this;
      // 暂且禁止访问详情
      // return
      var that = this;
      var userStorage = (0, _cookie.getCookie)('user');
      if (!userStorage) {
        that.$root.loginscene = 'VS_DETAIL'; //注册登录场景
        that.$root.loginsceneid = "".concat(gsid);
        that.$root.dialogFormVisible = true;
        this.inter = setInterval(function () {
          userStorage = (0, _cookie.getCookie)('user');
          if (userStorage) {
            document.getElementById("".concat(gsid)).click();
            clearInterval(_this.inter);
          }
        }, 500);
      } else {
        // 使用vue路由机制,将前缀保留
        var routeUrl = this.$router.resolve({
          path: "/goodsdetail",
          query: {
            gsid: gsid
          }
        });
        window.open(routeUrl.href, '_blank');
      }
    },
    //排序
    clickToSortByArrivalTime: function clickToSortByArrivalTime() {
      this.$emit("sortatime");
    },
    clickToSortByReleaseTime: function clickToSortByReleaseTime() {
      this.$emit("sortrtime");
    }
  },
  beforeDestroy: function beforeDestroy() {
    if (this.inter) {
      clearInterval(this.inter);
    }
  }
};