"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "vue-particle-line"
  }, [_c("div", {
    staticClass: "slot-wraper"
  }, [_vm._t("default")], 2), _c("canvas", {
    staticClass: "canvas",
    attrs: {
      id: "canvas"
    }
  })]);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;