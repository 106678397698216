"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "carousel_content",
    style: "--width:" + _vm.carouselWidth + ";"
  }, [_c("el-carousel", {
    attrs: {
      "indicator-position": _vm.indicatorPosition,
      height: _vm.carouselHeight,
      arrow: "never",
      trigger: "click"
    }
  }, _vm._l(_vm.carouselData, function (item, index) {
    return item.imgUrl ? _c("el-carousel-item", {
      key: index
    }, [_c("a", {
      staticClass: "carouselItem",
      attrs: {
        href: item.imgJumpUrl ? item.imgJumpUrl : "javascript:void(0);",
        target: item.imgJumpUrl ? "_blank" : ""
      }
    }, [_c("img", {
      attrs: {
        src: item.imgUrl,
        alt: ""
      }
    })])]) : _vm._e();
  }), 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;