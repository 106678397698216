"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "machinehalt_content"
  }, [_c("el-container", [_c("el-header"), _c("el-main", [_c("table", {
    staticClass: "machinehalt_main",
    attrs: {
      border: "0",
      cellpadding: "0"
    }
  }, [_c("tbody", [_c("tr", [_c("td", [_c("p", {
    staticClass: "font-bold font-28"
  }, [_vm._v("网站停机维护时间通知")])])]), _c("tr", [_c("td", [_c("p", {
    staticClass: "text-align-left text_indent font-24"
  }, [_vm._v("尊敬的用户，为了给您带来更好的体验，危化镖局将于 "), _c("b", [_vm._v("05月14日 22：00")]), _vm._v(" 至 "), _c("b", [_vm._v("05月14日 23：10")]), _vm._v(" 起 进行服务器系统升级，预计完成时间为 "), _c("b", [_vm._v("1 小时")]), _vm._v("。期间危化镖局暂停服务，我们将尽快为您恢复，由此带来的不便敬请谅解，感谢您的理解和支持！")])])]), _c("tr", [_c("td", [_c("p", {
    staticClass: "text-align-right font-24"
  }, [_vm._v("危化镖局")]), _c("p", {
    staticClass: "text-align-right font-24"
  }, [_vm._v("2018年05月14日")])])])])])])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;