"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "page"
  }, [_c("el-carousel", {
    staticClass: "height-516",
    attrs: {
      "indicator-position": "none",
      arrow: "never"
    }
  }, _vm._l(_vm.bannerList, function (item, index) {
    return _c("el-carousel-item", {
      key: index,
      staticClass: "height-516"
    }, [_c("img", {
      staticClass: "mouse-pointer height-516 width-full",
      attrs: {
        src: item.url,
        alt: ""
      },
      on: {
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    })]);
  }), 1), _vm._m(0), _c("div", {
    staticClass: "cont-02",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-21212 font-36 font-weight-500"
  }, [_vm._v("合作财险公司")]), _c("div", {
    staticClass: "flex-row-sb align-item-center flex-warp margin-top-10"
  }, _vm._l(_vm.insuranceList, function (item) {
    return _c("img", {
      staticClass: "margin-top-40 width-400",
      attrs: {
        src: item.imgUrl
      }
    });
  }), 0)])]), _vm._m(1), _vm._m(2), _c("appointment-five", {
    attrs: {
      sourceType: "50"
    }
  }), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-01",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-21212 font-36 font-weight-500"
  }, [_vm._v("镖局保险")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-50"
  }, [_vm._v(" 危化镖局平台旨在搭建一站式物流保险管理服务平台，为企业在物流过程中提供快速投保、助赔、风险防范和管理等全面服务。平台保险产品以货运险、 物流责任险为主，不断延伸创新各种保险产品，全方位满足客户的保险需求！ ")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-60"
  }, [_c("div", {
    staticClass: "flex-col-center"
  }, [_c("img", {
    staticClass: "width-380",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/01.jpg")
    }
  }), _c("div", {
    staticClass: "text flex-col-center"
  }, [_c("span", {
    staticClass: "font-24 color-212121"
  }, [_vm._v("化工生产企业")]), _c("span", {
    staticClass: "font-16 color-6E6E6E margin-top-10"
  }, [_vm._v("货运险")])])]), _c("div", {
    staticClass: "flex-col-center"
  }, [_c("img", {
    staticClass: "width-380",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/02.jpg")
    }
  }), _c("div", {
    staticClass: "text flex-col-center"
  }, [_c("span", {
    staticClass: "font-24 color-212121"
  }, [_vm._v("物流企业")]), _c("span", {
    staticClass: "font-16 color-6E6E6E margin-top-10"
  }, [_vm._v("物流责任险、雇主险")])])]), _c("div", {
    staticClass: "flex-col-center"
  }, [_c("img", {
    staticClass: "width-380",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/03.jpg")
    }
  }), _c("div", {
    staticClass: "text flex-col-center"
  }, [_c("span", {
    staticClass: "font-24 color-212121"
  }, [_vm._v("司机")]), _c("span", {
    staticClass: "font-16 color-6E6E6E margin-top-10"
  }, [_vm._v("意外险、承运人责任险")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-21212 font-36 font-weight-500"
  }, [_vm._v("货运险")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25"
  }, [_vm._v("5大特色，为您保驾护航")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-80"
  }, [_c("img", {
    staticClass: "width-340 margin-left-100",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/10.png")
    }
  }), _c("div", {
    staticClass: "item-r font-20 color-333333"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15 width-21",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png")
    }
  }), _c("span", [_vm._v("覆盖全国范围（港澳台、西藏除外）")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15 width-21",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png")
    }
  }), _c("span", [_vm._v("可承保新能源电池、储能柜")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15 width-21",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png")
    }
  }), _c("span", [_vm._v("5个工作日内理赔结案（重大事故除外）")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15 width-21",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png")
    }
  }), _c("span", [_vm._v("费率低至万分之三点五")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15 width-21",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png")
    }
  }), _c("span", [_vm._v("最高额度2000万")])])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "main-item"
  }, [_c("div", {
    staticClass: "color-21212 font-36 font-weight-500"
  }, [_vm._v("个人意外险")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-25"
  }, [_vm._v("4大特色，保障司机运输途中的个人安全")]), _c("div", {
    staticClass: "flex-row-sb align-item-center margin-top-80"
  }, [_c("div", {
    staticClass: "item-r font-20 color-333333"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png"),
      width: "21px",
      height: "21px"
    }
  }), _c("span", [_vm._v("保险公司不爱保？——阳光保！")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png"),
      width: "21px",
      height: "21px"
    }
  }), _c("span", [_vm._v("保险公司保费高？——我来降！")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png"),
      width: "21px",
      height: "21px"
    }
  }), _c("span", [_vm._v("物流公司投保少？——我来加！")])]), _c("div", {
    staticClass: "flex-row-start align-item-center margin-top-20"
  }, [_c("img", {
    staticClass: "margin-right-15",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/12.png"),
      width: "21px",
      height: "21px"
    }
  }), _c("span", [_vm._v("保险方案不灵活？——我随您！")])])]), _c("img", {
    staticClass: "width-340 margin-right-120",
    attrs: {
      src: require("../../assets/static/img/softBusinessLogistics/insurance/11.png")
    }
  })])])]);
}];
render._withStripped = true;