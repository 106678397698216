"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.array.fill");
var _default = exports.default = {
  name: 'mouse-line',
  props: {
    // 界面上连接线条的粗细，默认0.3
    lineWidth: {
      default: .5
    },
    // 透明度
    tmd: {
      default: 0.3
    },
    // 星星(原点)数量
    nb: {
      default: 210
    }
  },
  mounted: function mounted() {
    // 透明度
    var tmd = this.tmd;
    // 原点数量 
    var nb = this.nb;
    //console.log("星星数量:"+nb);
    // 页面渲染完成后加载
    this.$nextTick(function () {
      try {
        if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
          //
        } else {
          $(function () {
            var canvas = document.querySelector('#canvas');
            var ctx = canvas.getContext('2d');
            ////  console.log(canvas);
            //canvas.style.background = "black";
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
            // 界面上连接线条的粗细，默认0.3
            ctx.lineWidth = this.lineWidth;
            ctx.strokeStyle = new Color(150).style;
            var mousePosition = {
              x: 30 * canvas.width / 100,
              y: 30 * canvas.height / 100
            };
            var dots = {
              // 界面上彩色小原点数量
              nb: nb,
              distance: 100,
              d_radius: 150,
              array: []
            };
            function colorValue(min) {
              return Math.floor(Math.random() * 255 + min);
            }
            function createColorStyle(r, g, b) {
              // 最后一个参数调节透明度
              return 'rgba(' + r + ',' + g + ',' + b + ', ' + tmd + ')';
            }
            function mixComponents(comp1, weight1, comp2, weight2) {
              return (comp1 * weight1 + comp2 * weight2) / (weight1 + weight2);
            }
            function averageColorStyles(dot1, dot2) {
              var color1 = dot1.color,
                color2 = dot2.color;
              var r = mixComponents(color1.r, dot1.radius, color2.r, dot2.radius),
                g = mixComponents(color1.g, dot1.radius, color2.g, dot2.radius),
                b = mixComponents(color1.b, dot1.radius, color2.b, dot2.radius);
              return createColorStyle(Math.floor(r), Math.floor(g), Math.floor(b));
            }
            function Color(min) {
              min = min || 0;
              this.r = colorValue(min);
              this.g = colorValue(min);
              this.b = colorValue(min);
              this.style = createColorStyle(this.r, this.g, this.b);
            }
            function Dot() {
              this.x = Math.random() * canvas.width;
              this.y = Math.random() * canvas.height;
              this.vx = -.5 + Math.random();
              this.vy = -.5 + Math.random();
              this.radius = Math.random() * 2;
              this.color = new Color();
              ////  console.log(this);
            }
            Dot.prototype = {
              draw: function draw() {
                ctx.beginPath();
                ctx.fillStyle = this.color.style;
                ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2, false);
                ctx.fill();
              }
            };
            function createDots() {
              for (var i = 0; i < dots.nb; i++) {
                dots.array.push(new Dot());
              }
            }
            function moveDots() {
              for (var i = 0; i < dots.nb; i++) {
                var dot = dots.array[i];
                if (dot.y < 0 || dot.y > canvas.height) {
                  //dot.vx = dot.vx;
                  dot.vy = -dot.vy;
                } else if (dot.x < 0 || dot.x > canvas.width) {
                  dot.vx = -dot.vx;
                  //dot.vy = dot.vy;
                }
                dot.x += dot.vx;
                dot.y += dot.vy;
              }
            }
            function connectDots() {
              for (var i = 0; i < dots.nb; i++) {
                for (var j = 0; j < dots.nb; j++) {
                  var i_dot = dots.array[i];
                  var j_dot = dots.array[j];
                  if (i_dot.x - j_dot.x < dots.distance && i_dot.y - j_dot.y < dots.distance && i_dot.x - j_dot.x > -dots.distance && i_dot.y - j_dot.y > -dots.distance) {
                    if (i_dot.x - mousePosition.x < dots.d_radius && i_dot.y - mousePosition.y < dots.d_radius && i_dot.x - mousePosition.x > -dots.d_radius && i_dot.y - mousePosition.y > -dots.d_radius) {
                      ctx.beginPath();
                      ctx.strokeStyle = averageColorStyles(i_dot, j_dot);
                      ctx.moveTo(i_dot.x, i_dot.y);
                      ctx.lineTo(j_dot.x, j_dot.y);
                      ctx.stroke();
                      ctx.closePath();
                    }
                  }
                }
              }
            }
            function drawDots() {
              for (var i = 0; i < dots.nb; i++) {
                var dot = dots.array[i];
                dot.draw();
              }
            }
            function animateDots() {
              ctx.clearRect(0, 0, canvas.width, canvas.height);
              moveDots();
              connectDots();
              drawDots();
              requestAnimationFrame(animateDots);
            }
            $('canvas').on('mousemove', function (e) {
              mousePosition.x = e.pageX;
              mousePosition.y = e.pageY;
            });
            $('canvas').on('mouseleave', function () {
              mousePosition.x = canvas.width / 2;
              mousePosition.y = canvas.height / 2;
              // e
            });
            createDots();
            requestAnimationFrame(animateDots);
          });
        }
      } catch (ex) {

        // ex
      }
    });
  }
};