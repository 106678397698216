"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "right"
  }, _vm._l(_vm.advertiseData, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("a", {
      attrs: {
        href: item.ordinaryJumpUrl,
        target: "_blank"
      }
    }, [_c("img", {
      attrs: {
        src: item.advertOssUrl,
        alt: ""
      }
    })])]);
  }), 0);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;