"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-form", {
    ref: "regForm",
    attrs: {
      model: _vm.regForm,
      rules: _vm.regRules
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "validatePhone"
    }
  }, [_c("el-input", {
    attrs: {
      type: "phone",
      autocomplete: "off",
      clearable: true,
      placeholder: "手机号",
      maxlength: "11"
    },
    model: {
      value: _vm.regForm.validatePhone,
      callback: function callback($$v) {
        _vm.$set(_vm.regForm, "validatePhone", $$v);
      },
      expression: "regForm.validatePhone"
    }
  }, [_c("img", {
    staticClass: "margin-left-5 width-21",
    attrs: {
      slot: "prefix",
      src: require("../../assets/static/img/login/01.png")
    },
    slot: "prefix"
  })])], 1), _c("el-form-item", {
    staticClass: "code_con",
    attrs: {
      prop: "validateCode"
    }
  }, [_c("el-input", {
    attrs: {
      type: "messagecode",
      autocomplete: "off",
      clearable: true,
      placeholder: "短信验证码"
    },
    nativeOn: {
      keyup: function keyup($event) {
        if (!$event.type.indexOf("key") && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        return _vm.toReg("regForm");
      }
    },
    model: {
      value: _vm.regForm.validateCode,
      callback: function callback($$v) {
        _vm.$set(_vm.regForm, "validateCode", $$v);
      },
      expression: "regForm.validateCode"
    }
  }, [_c("template", {
    staticClass: "codeBtn",
    slot: "append"
  }, [_c("el-popover", {
    ref: "popover",
    attrs: {
      "popper-class": "slidingPictures",
      trigger: "click",
      placement: "top"
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("div", {
    staticClass: "sliding-pictures"
  }, [_c("div", {
    staticClass: "vimg"
  }, [_c("canvas", {
    attrs: {
      id: "sliderBlock2"
    }
  }), _c("canvas", {
    attrs: {
      id: "codeImg2"
    }
  })]), _c("div", {
    staticClass: "slider"
  }, [_c("div", {
    staticClass: "track",
    class: {
      pintuTrue: _vm.puzzle
    }
  }, [_vm._v(" " + _vm._s(_vm.tips) + " ")]), _c("div", {
    staticClass: "button el-icon-s-grid",
    on: {
      mousedown: function mousedown($event) {
        $event.preventDefault();
        return _vm.drag.apply(null, arguments);
      }
    }
  })]), _c("div", {
    staticClass: "operation"
  }, [_c("span", {
    staticClass: "el-icon-circle-close",
    attrs: {
      title: "关闭验证码"
    },
    on: {
      click: function click($event) {
        _vm.visible = false;
      }
    }
  }), _c("span", {
    staticClass: "el-icon-refresh-left",
    attrs: {
      title: "刷新验证码"
    },
    on: {
      click: _vm.canvasInit
    }
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.codeBtnShow,
      expression: "codeBtnShow"
    }],
    staticClass: "color-3269F6",
    attrs: {
      slot: "reference"
    },
    slot: "reference"
  }, [_vm._v("获取验证码")])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.codeBtnShow,
      expression: "!codeBtnShow"
    }],
    staticClass: "count"
  }, [_c("span", [_vm._v(_vm._s(_vm.count))]), _vm._v(" s")])], 1), _c("div", {
    staticClass: "min-height-full flex-col-center",
    attrs: {
      slot: "prefix"
    },
    slot: "prefix"
  }, [_c("img", {
    staticClass: "margin-left-5 width-21",
    attrs: {
      src: require("../../assets/static/img/login/03.png")
    }
  })])], 2)], 1), _c("div", {
    staticClass: "toReg text-left margin-bottom-45"
  }, [_vm._v(" 未注册危化镖局账号的手机号，登录时将自动注册，且代表您已同意"), _c("el-link", {
    staticStyle: {
      display: "initial"
    },
    attrs: {
      type: "primary",
      underline: false
    },
    on: {
      click: function click($event) {
        _vm.$root.innerVisible = true;
      }
    }
  }, [_vm._v("《用户服务协议》")])], 1), _c("el-form-item", [_c("el-button", {
    staticClass: "login_btn",
    attrs: {
      type: "primary",
      disabled: _vm.submitDisabled
    },
    on: {
      click: function click($event) {
        return _vm.toReg("regForm");
      }
    }
  }, [_vm._v("登录")])], 1)], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;