"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.string.link");
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    style: {
      position: _vm.$route.name == "home" ? "absolute" : "relative",
      width: "100%"
    }
  }, [_c("el-row", {
    staticClass: "nav flex-row-sb align-item-center",
    style: {
      "background-color": _vm.navConfig.bgColor,
      color: _vm.navConfig.textColor
    }
  }, [_c("el-col", {
    staticClass: "nav-l text-left mouse-pointer",
    attrs: {
      span: 4
    }
  }, [_vm.showWhiteImg ? _c("img", {
    staticClass: "logo",
    attrs: {
      src: require("../assets/static/img/home/new/logo-white.png")
    },
    on: {
      click: function click($event) {
        return _vm.toPage("/home");
      }
    }
  }) : _c("img", {
    staticClass: "logo",
    attrs: {
      src: require("../assets/static/img/home/new/logo-3269F6.png")
    },
    on: {
      click: function click($event) {
        return _vm.toPage("/home");
      }
    }
  })]), _c("el-col", {
    staticClass: "nav-c",
    attrs: {
      span: 16
    }
  }, _vm._l(_vm.configNav, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "nav-item",
      style: {
        color: _vm.navConfig.textColor
      },
      on: {
        mouseenter: function mouseenter($event) {
          $event.stopPropagation();
          return _vm.mouseenter(item);
        },
        mouseleave: function mouseleave($event) {
          $event.stopPropagation();
          return _vm.mouseleave.apply(null, arguments);
        },
        click: function click($event) {
          return _vm.toPage(item.path);
        }
      }
    }, [_vm._v(_vm._s(item.name) + " "), item.subItems ? _c("i", {
      class: {
        "el-icon-arrow-up": _vm.currentNavItem === item && item.subItems && _vm.isMaskVisible,
        "el-icon-arrow-down": _vm.currentNavItem !== item || !item.subItems || !_vm.isMaskVisible
      }
    }) : _vm._e()]);
  }), 0), _c("el-col", {
    staticClass: "nav-r flex-row-end align-item-center",
    attrs: {
      span: 4
    }
  }, [_c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$root.isLogin,
      expression: "$root.isLogin"
    }],
    staticClass: "logged_in"
  }, [_c("el-menu", {
    attrs: {
      "default-active": _vm.activeIndex,
      mode: "horizontal",
      "active-text-color": "#909399"
    }
  }, [_c("el-submenu", {
    attrs: {
      index: "1"
    }
  }, [_c("template", {
    slot: "title"
  }, [_c("img", {
    staticClass: "user_ico",
    attrs: {
      src: require("../assets/static/img/user.png")
    }
  })]), _c("el-menu-item", {
    staticClass: "loggedin_item",
    attrs: {
      index: "1-1"
    },
    on: {
      click: function click($event) {
        return _vm.toTg();
      }
    }
  }, [_vm._v("我要推广")]), _c("el-menu-item", {
    staticClass: "loggedin_item",
    attrs: {
      index: "1-4"
    },
    on: {
      click: function click($event) {
        return _vm.toLogoff();
      }
    }
  }, [_vm._v("退出登录")])], 2)], 1)], 1), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.$root.isLogin,
      expression: "!$root.isLogin"
    }],
    staticClass: "loginreg_ico mouse-pointer flex-row-start font-18",
    on: {
      click: function click($event) {
        return _vm.clickToLogin();
      }
    }
  }, [_vm.showWhiteImg ? _c("img", {
    staticClass: "header_ico",
    attrs: {
      src: require("../assets/static/img/common/white-login-icon.png")
    }
  }) : _c("img", {
    staticClass: "header_ico",
    attrs: {
      src: require("../assets/static/img/common/black-login-icon.png")
    }
  }), _vm._v(" 登录 | 注册 ")])])], 1), _c("div", {
    staticClass: "mask",
    attrs: {
      id: "mask"
    },
    on: {
      mouseleave: function mouseleave($event) {
        $event.stopPropagation();
        return _vm.mouseleave.apply(null, arguments);
      }
    }
  }, [_c("div", {
    staticClass: "placeholder"
  }), _vm.currentNavItem.subItems ? _c("el-row", {
    staticClass: "maskRow"
  }, [_c("el-col", {
    staticStyle: {
      "font-size": "20px",
      opacity: "0"
    },
    attrs: {
      span: 4
    }
  }, [_vm._v("占位符")]), _c("el-col", {
    staticClass: "mask-item",
    attrs: {
      span: 16
    }
  }, [_c("span", {
    staticClass: "nav-name"
  }, [_vm._v(_vm._s(_vm.currentNavItem.name))]), _c("ul", _vm._l(_vm.currentNavItem.subItems, function (citem, index) {
    return _c("li", {
      key: index,
      staticClass: "subItem",
      style: {
        paddingLeft: citem.isSecond ? "1.2em" : "0"
      },
      on: {
        click: function click($event) {
          return _vm.toItemPage(citem.link);
        }
      }
    }, [_c("div", [_vm._v(_vm._s(citem.text))]), _c("span", {
      staticClass: "text-line"
    })]);
  }), 0)]), _c("el-col", {
    staticStyle: {
      "font-size": "20px",
      opacity: "0"
    },
    attrs: {
      span: 4
    }
  }, [_vm._v("占位符")])], 1) : _vm._e()], 1), _c("el-dialog", {
    staticClass: "tg",
    attrs: {
      visible: _vm.dialogTgVisible,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.dialogTgVisible = $event;
      }
    }
  }, [_c("div", {
    staticClass: "tgWrapper",
    style: _vm.backgroundImage
  }, [_c("div", {
    staticClass: "qrCodetg"
  }, [_c("img", {
    staticStyle: {
      width: "100%",
      height: "100%"
    },
    attrs: {
      src: _vm.qrCodetg,
      alt: ""
    }
  })]), _c("div", {
    staticClass: "tgUrl"
  }, [_c("el-input", {
    staticClass: "tgInput",
    attrs: {
      value: _vm.tgUrl
    }
  }, [_c("el-button", {
    attrs: {
      slot: "append"
    },
    on: {
      click: function click($event) {
        return _vm.toCopyUrl(_vm.tgUrl);
      }
    },
    slot: "append"
  }, [_vm._v("复制")])], 1)], 1)])])], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;