"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'reg_carousel',
  components: {},
  props: ['indicatorPosition', 'carouselWidth', 'carouselHeight', 'carouselData'],
  data: function data() {
    return {};
  },
  created: function created() {
    console.log("carouselData", this.carouselData);
  }
};