"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sideNav = _interopRequireDefault(require("../../components/side-nav.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _default = exports.default = {
  name: 'groundPage',
  components: {
    sideNav: _sideNav.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {};
  }
};