"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'home',
  props: {
    showPrice: {
      type: Boolean,
      require: true,
      default: false
    },
    priceDialogData: {
      type: Object,
      require: true,
      default: {}
    },
    loading: {
      type: Boolean,
      require: true,
      default: false
    }
  },
  data: function data() {
    return {};
  },
  filters: {
    routeEllipsis: function routeEllipsis(value) {
      if (!value) {
        return '';
      }
      if (value.length > 4) {
        return value.slice(0, 4);
      } else {
        return value;
      }
    }
  },
  methods: {
    toCall: function toCall() {
      this.$parent.showCallDialog();
    },
    toClose: function toClose() {
      this.$parent.closePriceDialog();
    }
  }
};