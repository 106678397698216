"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "routerContent",
    style: {
      height: _vm.mainHeight
    },
    attrs: {
      id: "products"
    }
  }, [_vm._m(0), _c("downGuid"), _c("div", {
    staticClass: "products_content"
  }, [_c("router-view"), _c("footNav")], 1), _c("sideNav", {
    attrs: {
      side_bind: "products"
    }
  })], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "products_header"
  }, [_c("div", {
    staticClass: "pd_title"
  })]);
}];
render._withStripped = true;