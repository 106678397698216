"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _appointment = _interopRequireDefault(require("../../components/softBusinessLogistics/appointment.vue"));
var _footNav = _interopRequireDefault(require("../../components/foot-nav"));
var _default = exports.default = {
  components: {
    appointment: _appointment.default,
    footNav: _footNav.default
  },
  data: function data() {
    return {
      bannerList: [{
        url: require("../../assets/static/img/softBusinessLogistics/wisdom5G/banner-01.jpg")
      }],
      list1: [{
        title: '政策解读',
        desc: '依法公开涉企政策，并及时跟进解读准确传递权威信息 和政策意图'
      }, {
        title: '申办指南',
        desc: '列明基本流程、受理单位、申报材料、咨询方式等内容，并细化到每个环节'
      }, {
        title: '线上申报',
        desc: '一窗受理企业申报材料，及时将信息推向各处理部门，信息多跑路，企业少跑腿'
      }]
    };
  },
  methods: {
    toPage: function toPage(name) {
      this.$router.push({
        name: name
      });
    }
  }
};