"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _default = exports.default = {
  name: 'pt',
  data: function data() {
    return {
      initQCode: this.$root.baseUrl + '/appDownload.dhtml?ncrypt=()(c)L33E/NY32aX1VkUU366w==',
      //安卓下载码链接
      halist: [{
        index: 0,
        haClassName: 'car_box',
        haTitle: '快速找车',
        haImgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/index/index_search_ico.png',
        haPostcon: ['快速输入货型、吨位、路线', '200公里范围内快速定位所需承运商或车源', '订单信息及时推送，报价实时回传']
      }, {
        index: 1,
        haClassName: 'good_box',
        haTitle: '高效配货',
        haImgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/index/index_car_ico.png',
        haPostcon: ['物流软件登记车源情况、当日货源情况、发车情况', '根据路线及车源载货信息，实时推荐沿途货源', '实时报价、预约，并线上结算费用']
      }, {
        index: 3,
        haClassName: 'match_box',
        haTitle: '智能匹配',
        haImgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/index/index_matching_ico.png',
        haPostcon: ['按照路线、范围进行智能车源货源匹配', '按照车源、货源的大类分类进行智能推送订单', '按照区域配送，推送落地配网点及仓储服务']
      }],
      zxlist: [{
        imgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/column_img2.png',
        lmTitle: '热门推荐'
      }, {
        imgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/column_img4.png',
        lmTitle: '交通快报'
      }, {
        imgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/column_img3.png',
        lmTitle: '物流资讯'
      }, {
        imgSrc: 'https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/products/column_img1.png',
        lmTitle: '化工时事'
      }],
      //化工资讯专栏轮播数据
      swiperOption: {
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        loop: true,
        slidesPerView: 4,
        spaceBetween: 25
      } //化工资讯专栏轮播配置
    };
  },
  methods: {
    //化工资讯专栏跳转危化头条
    toNews: function toNews(val) {
      this.$router.push({
        name: 'newslist',
        params: {
          'fromProducts': val
        },
        query: {
          isSearch: false,
          isDetail: false
        }
      });
    }
  }
};