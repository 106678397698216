"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
require("core-js/modules/es6.function.name");
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "goods-container",
    attrs: {
      id: "goods"
    }
  }, [_c("trade-banner"), _c("div", {
    staticClass: "tab"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center margin-auto"
  }, [_c("div", {
    staticClass: "color-666666 font-22"
  }, [_vm._v("化工物流/交易大厅")]), _c("div", {
    staticClass: "height-full flex-row-start"
  }, _vm._l(_vm.tabList, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "tab-item flex-row-center font-22",
      class: [index == 0 ? "active-tab-item" : ""],
      on: {
        click: function click($event) {
          return _vm.tabChange(item.url);
        }
      }
    }, [_vm._v(" " + _vm._s(item.name) + " ")]);
  }), 0)])]), _c("div", {
    staticClass: "search"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("el-cascader", {
    staticClass: "start-city",
    attrs: {
      options: _vm.startCitys,
      placeholder: "出发城市",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.startCityChange
    },
    model: {
      value: _vm.startValue,
      callback: function callback($$v) {
        _vm.startValue = $$v;
      },
      expression: "startValue"
    }
  }), _c("img", {
    staticClass: "width-18 margin-left-20 margin-right-20",
    attrs: {
      src: require("../../assets/static/img/trade/huan.png"),
      alt: ""
    },
    on: {
      click: _vm.clickToExchangeCity
    }
  }), _c("el-cascader", {
    staticClass: "end-city",
    attrs: {
      options: _vm.endCitys,
      placeholder: "到达城市",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.endCityChange
    },
    model: {
      value: _vm.endValue,
      callback: function callback($$v) {
        _vm.endValue = $$v;
      },
      expression: "endValue"
    }
  }), _c("el-select", {
    staticClass: "goods-type",
    attrs: {
      placeholder: "货物类型",
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.goodsTypeId,
      callback: function callback($$v) {
        _vm.goodsTypeId = $$v;
      },
      expression: "goodsTypeId"
    }
  }, _vm._l(_vm.goodsTypes, function (item) {
    return _c("el-option", {
      key: item.gtid,
      attrs: {
        value: item.gtid,
        label: item.goodsTypeCode
      }
    });
  }), 1), _c("el-select", {
    staticClass: "car-type",
    attrs: {
      placeholder: "车辆类型",
      clearable: ""
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.carTypeId,
      callback: function callback($$v) {
        _vm.carTypeId = $$v;
      },
      expression: "carTypeId"
    }
  }, _vm._l(_vm.carTypes, function (item) {
    return _c("el-option", {
      key: item.vehicleTypeId,
      attrs: {
        value: item.vehicleTypeId,
        label: item.vehicleTypeName
      }
    });
  }), 1), _c("el-date-picker", {
    staticClass: "time",
    attrs: {
      type: "date",
      "value-format": "yyyy-MM-dd HH:mm:ss",
      "picker-options": _vm.pickerOptions,
      placeholder: "装车时间"
    },
    on: {
      change: _vm.handleChange
    },
    model: {
      value: _vm.timeValue,
      callback: function callback($$v) {
        _vm.timeValue = $$v;
      },
      expression: "timeValue"
    }
  })], 1), _c("div", {
    staticClass: "flex-row-end align-item-center"
  }, [_c("el-button", {
    staticClass: "search-regest",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.regest
    }
  }, [_vm._v("重置")]), _c("el-button", {
    directives: [{
      name: "track-event",
      rawName: "v-track-event",
      value: {
        category: "货源列表搜索",
        action: "点击",
        opt_label: _vm.start + "-" + _vm.end
      },
      expression: "{category:'货源列表搜索', action:'点击', opt_label:start+'-'+end}"
    }],
    staticClass: "search-btn",
    attrs: {
      type: "primary"
    },
    on: {
      click: _vm.clickToSearch
    }
  }, [_vm._v("搜索")])], 1)])]), _vm.centerAdvertise.length ? _c("a", {
    attrs: {
      href: _vm.centerAdvertise[0].ordinaryJumpUrl,
      target: "_blank"
    }
  }, [_c("img", {
    staticClass: "width-1280",
    attrs: {
      src: _vm.centerAdvertise[0].advertOssUrl
    }
  })]) : _vm._e(), _c("div", {
    staticClass: "main"
  }, [_vm.loaded && _vm.goodsData.length == 0 ? _c("div", {
    staticClass: "nodata"
  }, [_c("img", {
    staticClass: "width-300",
    attrs: {
      src: require("../../assets/static/img/no_data.png")
    }
  }), _c("div", [_vm._v("暂无数据~")])]) : _vm._e(), _vm.goodsData.length > 0 ? _c("goods-list", {
    attrs: {
      sourceData: _vm.goodsData,
      sortConfig: _vm.sortConfig
    },
    on: {
      sortatime: _vm.clickToSortByArrivalTime,
      sortrtime: _vm.clickToSortByReleaseTime
    }
  }) : _vm._e()], 1), _c("div", {
    staticClass: "bottompage"
  }, [_c("div", {
    staticClass: "main-item flex-row-end"
  }, [_c("paging", {
    attrs: {
      name: _vm.name,
      "page-size": _vm.$root.pageSize,
      total: _vm.goodsTotal,
      layout: "jumper,total",
      "current-page": _vm.page,
      pagingPosition: "left"
    },
    on: {
      change: _vm.pageChange
    }
  })], 1)]), _c("foot-nav")], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;