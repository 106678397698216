"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _messageLogin = _interopRequireDefault(require("../../components/loginreg/message-login.vue"));
var _protocol = _interopRequireDefault(require("../../components/loginreg/protocol.vue"));
// import passwordLogin from '../../components/loginreg/password-login.vue'
var _default = exports.default = {
  name: 'reg_login',
  components: {
    messageLogin: _messageLogin.default,
    // passwordLogin,
    protocol: _protocol.default
  },
  props: ['loginType', 'landingPageJumpLink', 'pageId', 'userId', 'template', 'uuid'],
  data: function data() {
    return {
      activeName: 'first' //默认tab
    };
  }
};