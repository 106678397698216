"use strict";

var telsData = [{
  outName: '张经理',
  name: '张祖铠',
  cityArr: ['山东省', '湖南省'],
  tel: '18030259153'
}, {
  outName: '黄经理',
  name: '黄文忠',
  cityArr: ['福建省', '广东省'],
  tel: '18906059861'
}, {
  outName: '陈经理',
  name: '陈锦铭',
  cityArr: ['安徽省', '江苏省'],
  tel: '18020742526'
}, {
  outName: '张经理',
  name: '张志强',
  cityArr: ['浙江省', '上海'],
  tel: '18059698000'
}, {
  outName: '李经理',
  name: '李伟强',
  cityArr: ['河南省', '湖北省'],
  tel: '18106974342'
}, {
  outName: '姚经理',
  name: '姚欣然',
  cityArr: ['河北省', '山西省'],
  tel: '18060910751'
}];
module.exports = {
  telsData: telsData
};