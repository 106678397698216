"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "login_content"
  }, [_c("el-tabs", {
    attrs: {
      stretch: true
    },
    model: {
      value: _vm.activeName,
      callback: function callback($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: "短信登录",
      name: "first"
    }
  }, [_c("messageLogin", {
    attrs: {
      loginType: _vm.loginType,
      landingPageJumpLink: _vm.landingPageJumpLink,
      pageId: _vm.pageId,
      userId: _vm.userId,
      template: _vm.template,
      uuid: _vm.uuid
    }
  })], 1)], 1), _c("protocol")], 1);
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;