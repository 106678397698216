// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/static/img/landingpage/reg_logo.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/static/img/landingpage/reg_tel1.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".reg_header[data-v-1c217eb1] {\n  width: 6.25rem;\n  height: 100%;\n  margin: 0 auto;\n  display: flex;\n  align-items: flex-end;\n  padding-bottom: 0.130208rem;\n  background-image: #fff;\n  background-position: center;\n  text-align: left;\n}\n.reg_header .reg_logo[data-v-1c217eb1] {\n  display: inline-block;\n  width: 0.973958rem;\n  height: 0.15625rem;\n  margin-right: 0.078125rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 100% 100%;\n  background-repeat: no-repeat;\n}\n.reg_header .reg_tel[data-v-1c217eb1] {\n  display: inline-block;\n  width: 1.041667rem;\n  height: 0.151042rem;\n  line-height: 0.244792rem;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-size: 1rem 0.151042rem;\n  background-position-y: center;\n  background-position-x: right;\n  position: relative;\n}\n.reg_header .reg_tel[data-v-1c217eb1]::before {\n  content: \"\";\n  position: absolute;\n  left: 0;\n  top: 0;\n  width: 0.005208rem;\n  height: 0.151042rem;\n  background-color: #498fff;\n}", ""]);
// Exports
module.exports = exports;
