"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _sideNav = _interopRequireDefault(require("@/components/side-nav.vue"));
var _footNav = _interopRequireDefault(require("@/components/foot-nav"));
var _merchant_form = _interopRequireDefault(require("@/components/form/merchant_form.vue"));
var _default = exports.default = {
  name: 'chemicalStorage',
  components: {
    footNav: _footNav.default,
    sideNav: _sideNav.default,
    merchantForm: _merchant_form.default
  }
};