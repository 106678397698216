import { render, staticRenderFns } from "./aboutus.vue?vue&type=template&id=bb8d05c6&scoped=true"
import script from "./aboutus.vue?vue&type=script&lang=js"
export * from "./aboutus.vue?vue&type=script&lang=js"
import style0 from "./aboutus.vue?vue&type=style&index=0&id=bb8d05c6&lang=scss&scoped=scoped"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb8d05c6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\vescort-platform-pc-website\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('bb8d05c6')) {
      api.createRecord('bb8d05c6', component.options)
    } else {
      api.reload('bb8d05c6', component.options)
    }
    module.hot.accept("./aboutus.vue?vue&type=template&id=bb8d05c6&scoped=true", function () {
      api.rerender('bb8d05c6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/aboutus/aboutus.vue"
export default component.exports