"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "specialVehicle_content detail-container",
    attrs: {
      id: "specialVehicle"
    }
  }, [_c("el-carousel", {
    staticClass: "height-516"
  }, [_c("el-carousel-item", {
    staticClass: "height-516"
  }, [_c("img", {
    staticClass: "mouse-pointer height-516 width-full",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/banner-01.jpg")
    }
  })])], 1), _c("div", {
    staticClass: "cont-02"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_c("div", {
    staticClass: "flex-col-start"
  }, [_vm._m(0), _c("el-divider"), _vm._m(1), _c("div", {
    staticClass: "flex-row-start margin-top-50"
  }, [_vm._m(2), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _vm._m(3), _c("el-divider", {
    attrs: {
      direction: "vertical"
    }
  }), _vm._m(4)], 1)], 1), _c("img", {
    staticClass: "width-555",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/02.png")
    }
  })])]), _vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8), _c("div", {
    staticClass: "cont-05"
  }, [_c("div", {
    staticClass: "main-item flex-row-sb align-item-center"
  }, [_vm._m(9), _c("el-form", {
    ref: "svForm",
    attrs: {
      model: _vm.svForm,
      rules: _vm.svRules,
      "show-message": false
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "concactName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入您的姓名",
      clearable: "",
      maxlength: 10
    },
    model: {
      value: _vm.svForm.concactName,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "concactName", $$v);
      },
      expression: "svForm.concactName"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5",
    staticStyle: {
      opacity: "0"
    }
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "concactPhone"
    }
  }, [_c("el-input", {
    attrs: {
      type: "phone",
      autocomplete: "off",
      clearable: "",
      maxlength: 11,
      placeholder: "请输入您的联系电话"
    },
    model: {
      value: _vm.svForm.concactPhone,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "concactPhone", $$v);
      },
      expression: "svForm.concactPhone"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-row", {
    staticClass: "city-row",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("el-form-item", {
    attrs: {
      prop: "startCityStr"
    }
  }, [_c("el-cascader", {
    staticClass: "city",
    attrs: {
      model: _vm.svForm.startCityStr,
      options: _vm.citys,
      placeholder: "请选择出发城市",
      size: "medium",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.startCityChange
    }
  })], 1), _c("div", {
    staticClass: "flex-row-center font-20 color-212121",
    staticStyle: {
      height: "40px"
    }
  }, [_c("i", {
    staticClass: "el-icon-minus"
  })]), _c("el-form-item", {
    attrs: {
      prop: "endCityStr"
    }
  }, [_c("el-cascader", {
    staticClass: "city",
    attrs: {
      model: _vm.svForm.endCityStr,
      options: _vm.citys,
      placeholder: "请选择到达城市",
      size: "medium",
      props: {
        expandTrigger: "hover",
        value: "label"
      },
      clearable: ""
    },
    on: {
      change: _vm.endCityChange
    }
  })], 1)], 1), _c("el-form-item", {
    attrs: {
      prop: "goodsName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入货物名称",
      clearable: "",
      maxlength: 10
    },
    model: {
      value: _vm.svForm.goodsName,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "goodsName", $$v);
      },
      expression: "svForm.goodsName"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5",
    staticStyle: {
      opacity: "0"
    }
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "goodsWeight"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请输入货物重量",
      clearable: "",
      oninput: "if(value.length>5)value=value.slice(0,5)"
    },
    model: {
      value: _vm.svForm.goodsWeight,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "goodsWeight", $$v);
      },
      expression: "svForm.goodsWeight"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "freight"
    }
  }, [_c("el-input", {
    attrs: {
      type: "number",
      placeholder: "请输入期望运费"
    },
    model: {
      value: _vm.svForm.freight,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "freight", $$v);
      },
      expression: "svForm.freight"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5"
  }, [_vm._v("*")])]), _c("template", {
    slot: "append"
  }, [_c("el-select", {
    attrs: {
      placeholder: "请选择"
    },
    model: {
      value: _vm.svForm.freightUnit,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "freightUnit", $$v);
      },
      expression: "svForm.freightUnit"
    }
  }, [_c("el-option", {
    attrs: {
      label: "元/吨",
      value: "0"
    }
  }), _c("el-option", {
    attrs: {
      label: "元/车",
      value: "1"
    }
  })], 1)], 1)], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "companyName"
    }
  }, [_c("el-input", {
    attrs: {
      placeholder: "请输入公司名称",
      clearable: "",
      maxlength: 32
    },
    model: {
      value: _vm.svForm.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "companyName", $$v);
      },
      expression: "svForm.companyName"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5",
    staticStyle: {
      opacity: "0"
    }
  }, [_vm._v("*")])])], 2)], 1), _c("el-form-item", {
    attrs: {
      prop: "remark"
    }
  }, [_c("el-input", {
    attrs: {
      type: "textarea",
      placeholder: "请输入对车型、包装方式的要求",
      autosize: {
        minRows: 4,
        maxRows: 4
      },
      maxlength: "100"
    },
    model: {
      value: _vm.svForm.remark,
      callback: function callback($$v) {
        _vm.$set(_vm.svForm, "remark", $$v);
      },
      expression: "svForm.remark"
    }
  }, [_c("template", {
    slot: "prepend"
  }, [_c("span", {
    staticClass: "color-red font-16 margin-left-5",
    staticStyle: {
      opacity: "0"
    }
  }, [_vm._v("*")])])], 2)], 1), _c("el-button", {
    staticClass: "submit_btn text-align-center text-white font-20 mouse-pointer",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        return _vm.submitForm("svForm");
      }
    }
  }, [_vm._v("立即提交并获取报价")])], 1)], 1)]), _c("foot-nav", {
    ref: "foot"
  }), _c("price-dialog", {
    attrs: {
      showPrice: _vm.showPrice,
      priceDialogData: _vm.svForm,
      loading: _vm.priceLoading
    }
  }), _c("el-dialog", {
    staticClass: "phone_dialog call-dialog",
    attrs: {
      visible: _vm.showPhone,
      "show-close": false
    },
    on: {
      "update:visible": function updateVisible($event) {
        _vm.showPhone = $event;
      }
    }
  }, [_c("div", {
    staticClass: "phone_dialog_con"
  }, [_c("div", {
    staticClass: "phone_num"
  }, [_vm._v(_vm._s(_vm.telStr))])]), _c("div", {
    staticClass: "close",
    on: {
      click: function click($event) {
        _vm.showPhone = false;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-circle-close"
  })])]), _c("div", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }, [_vm._v(_vm._s(_vm.isLogin))])], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-row-start align-item-center"
  }, [_c("img", {
    staticClass: "width-54",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/01.png")
    }
  }), _c("span", {
    staticClass: "font-36 font-weight-500 color-21212 margin-left-30"
  }, [_vm._v("产品案例")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("ul", {
    staticClass: "font-18 color-555555 line-height-16 text-left",
    staticStyle: {
      "margin-left": "2em"
    }
  }, [_c("li", [_vm._v("2019年，浙江某化工企业需要长期运输8.5万吨危化品货物，在平台提交需求到对接成功，只耗时7天，就解决了长期运力问题；")]), _c("li", [_vm._v("月度物流费用相比往年同期降低16.75%；")]), _c("li", [_vm._v("委托方对服务满意，安全与速度稳定保质！")]), _c("li", [_vm._v("承运方广东XXX物流有限公司返程空载率相比之前降低37%，季度结算营收，同比增长达8%。")]), _c("li", {
    staticClass: "color-3269F6"
  }, [_vm._v("三赢，是我们的目标！")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-start text-align-left"
  }, [_c("span", {
    staticClass: "font-16 color-B2B2B2"
  }, [_vm._v("总货量")]), _c("span", {
    staticClass: "font-40 color-3269F6 margin-top-10"
  }, [_vm._v("8.5"), _c("span", {
    staticClass: "font-16"
  }, [_vm._v("万吨/年")])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-start text-align-left"
  }, [_c("span", {
    staticClass: "font-16 color-B2B2B2"
  }, [_vm._v("返程空载率下降")]), _c("span", {
    staticClass: "font-40 color-3269F6 margin-top-10"
  }, [_vm._v("37%")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-start text-align-left"
  }, [_c("span", {
    staticClass: "font-16 color-B2B2B2"
  }, [_vm._v("物流费用降低")]), _c("span", {
    staticClass: "font-40 color-3269F6 margin-top-10"
  }, [_vm._v("16.75%")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03"
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("运费为啥会低？")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20 line-height-20"
  }, [_c("span", [_vm._v("平台每日数万零散货源，")]), _c("span", {
    staticClass: "color-3269F6"
  }, [_vm._v("精准匹配来回货源，降低空载")]), _c("span", [_vm._v("，单趟运费自然低！")])]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/03.jpg")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#fff"
    }
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("服务为啥会好？")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20 line-height-20"
  }, [_c("span", [_vm._v("镖局拥有")]), _c("span", {
    staticClass: "color-3269F6"
  }, [_vm._v("经验丰富的专业运作团队")]), _c("span", [_vm._v("，提供7*24小时专员服务，结合算法技术，匹配资质专业运输车辆，通过自有TMS系统监督，保证服务品质时刻在线！")])]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/04.jpg")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-03",
    staticStyle: {
      "background-color": "#f6f7f8"
    }
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "font-36 color-212121 font-weight-500"
  }, [_vm._v("安全为啥有保障？")]), _c("div", {
    staticClass: "font-18 color-555555 margin-top-20 line-height-20"
  }, [_c("span", [_vm._v("全网首家将MSDS实际应用于仓储、运输的化工系统软件平台，我们联合")]), _c("span", {
    staticClass: "color-3269F6"
  }, [_vm._v("数十名国家级安全员")]), _c("span", [_vm._v("联合开发")]), _c("span", {
    staticClass: "color-3269F6"
  }, [_vm._v("400万份MSDS")]), _c("span", [_vm._v("数据，同时通过各项安全监控设备，配合大数据智能提醒，保障货物存储和运输的安全。")])]), _c("img", {
    staticClass: "width-1280 margin-top-60",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/05.jpg")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "cont-04"
  }, [_c("div", {
    staticClass: "main-item flex-col-center"
  }, [_c("div", {
    staticClass: "font-36 color-white font-weight-500"
  }, [_vm._v("专车服务流程")]), _c("img", {
    staticClass: "width-1280 margin-top-90",
    attrs: {
      src: require("../../assets/static/img/specialVehicle/07.png")
    }
  })])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "flex-col-start align-item-start",
    staticStyle: {
      transform: "translateY(-40px)"
    }
  }, [_c("div", {
    staticClass: "font-36 font-weight-500 color-212121"
  }, [_vm._v("填写货源信息获取报价")]), _c("div", {
    staticClass: "font-20 color-5A6991 margin-top-60"
  }, [_vm._v("30分钟内，将有物流专员来解决您的问题")]), _c("div", {
    staticClass: "font-20 color-5A6991 margin-top-30"
  }, [_vm._v("您也可主动给我们致电")]), _c("div", {
    staticClass: "font-36 color-3269F6 margin-top-30"
  }, [_vm._v("400-886-3156")])]);
}];
render._withStripped = true;