"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    ref: "content",
    staticClass: "routerContent",
    style: {
      height: _vm.mainHeight
    },
    attrs: {
      id: "businessjoin"
    }
  }, [_c("div", {
    staticClass: "businessjoin_content"
  }, [_c("div", {
    staticClass: "join_bg1_1"
  }, [_vm._m(0), _vm._m(1), _c("a", {
    staticClass: "join_btn mouse-pointer",
    attrs: {
      href: "javascript:"
    },
    on: {
      click: function click($event) {
        return _vm.goAnchor("#join_form");
      }
    }
  }, [_vm._v("我要招标")])]), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5), _c("div", {
    staticClass: "join_bg4",
    attrs: {
      id: "join_form"
    }
  }, [_c("join-form", {
    attrs: {
      "business-type": "10",
      "title-txt": "请填写您的招标意向",
      "sub-title-txt": "留下您的联系方式，2-3个工作日内联系您~"
    }
  })], 1), _c("foot-nav", {
    ref: "foot"
  })], 1), _c("downGuid")], 1);
};
var staticRenderFns = exports.staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_header"
  }, [_c("div", {
    staticClass: "join_title white_title font-bold"
  }, [_vm._v("真实的成功案例")]), _c("div", {
    staticClass: "join_subtitle white_title"
  }, [_vm._v("了解已合作的真实案例，借鉴成功经验")])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_main"
  }, [_c("div", {
    staticClass: "main_con text-left"
  }, [_c("div", {
    staticClass: "title font-bold"
  }, [_vm._v("产品案例")]), _c("div", {
    staticClass: "text"
  }, [_c("li", [_vm._v("2019年，承运浙江XX集团的年度运输任务 85000吨,从业务接入到投标结束，耗时15天，投标结束后，10天内即开始承运；")]), _c("li", [_vm._v("月度物流费用相比往年同期降低16.75%；")]), _c("li", [_vm._v("委托方对服务满意，安全与速度稳定保质！")]), _c("li", [_vm._v("承运方广东XXX物流有限公司返程空载率相比之前降低37%，季度结算营收，同比增长达8%。")]), _c("li", [_c("span", {
    staticStyle: {
      "font-size": "16px",
      color: "#2f6aff",
      "font-weight": "bold"
    }
  }, [_vm._v("三赢，是我们的目标！")])])]), _c("div", {
    staticClass: "join_data"
  }, [_c("div", {
    staticClass: "data_box"
  }, [_c("div", {
    staticClass: "data_title"
  }, [_vm._v("总货量")]), _c("div", {
    staticClass: "data_con"
  }, [_vm._v("8.5"), _c("small", {
    staticStyle: {
      "font-weight": "normal"
    }
  }, [_vm._v("万吨/年")])])]), _c("div", {
    staticClass: "data_box"
  }, [_c("div", {
    staticClass: "data_title"
  }, [_vm._v("返程空载率下降")]), _c("div", {
    staticClass: "data_con"
  }, [_vm._v("37%")])]), _c("div", {
    staticClass: "data_box"
  }, [_c("div", {
    staticClass: "data_title"
  }, [_vm._v("物流费用降低")]), _c("div", {
    staticClass: "data_con"
  }, [_vm._v("16.75%")])])])])]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg1_2"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/businessjoin/join_pcbg1_2.png",
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg1_3"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/businessjoin/join_pcbg1_3.png",
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg1_4"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/businessjoin/join_pcbg1_4.png",
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "join_bg3"
  }, [_c("img", {
    attrs: {
      src: "https://whbj-yellowpage.oss-cn-shenzhen.aliyuncs.com/pcarticle/businessjoin/join_pcbg3.png",
      alt: ""
    }
  })]);
}];
render._withStripped = true;