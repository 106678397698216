"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.function.name");
var _messageLogin = _interopRequireDefault(require("./message-login.vue"));
var _passwordLogin = _interopRequireDefault(require("./password-login.vue"));
var _protocol = _interopRequireDefault(require("./protocol.vue"));
var _default = exports.default = {
  name: 'login',
  components: {
    messageLogin: _messageLogin.default,
    passwordLogin: _passwordLogin.default,
    protocol: _protocol.default
  },
  props: ['name'],
  data: function data() {
    return {
      tabName: this.name ? this.name : 'first',
      activeName: 'first' //弹窗tab默认acive
    };
  },
  mounted: function mounted() {
    console.log('tabname', this.name);
  }
};