"use strict";

var _interopRequireDefault = require("E:/vescort-platform-pc-website/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _vue = _interopRequireDefault(require("vue"));
var _vuex = _interopRequireDefault(require("vuex"));
_vue.default.use(_vuex.default);
var state = {
  metaInfo: {
    title: '危化镖局-化工供应链一站式综合服务平台',
    keywords: '危化镖局,危险品,危险品运输,危化品,危化品运输,危险品物流,危化品物流,危险化学品,货运,危司机,易罐,危废,危运,化工仓储,找罐车,' + '找高栏车,拉货,货源,车源,货主,车主,司机,配货,苯,沥青,甲醇,酒精,汽油,柴油,涂料,黑货,干净货,危化品车,化工运输,危险品仓库,中国危险品物流网',
    description: '危化镖局致力于打造危险化学品一站式运输和管理平台,联合行业危化物流,建立辐射全国的危化配送网络,形成全国统一标准服务的综合性' + '服务平台,并汇聚行业相关资讯及政府最新政策打造行业信息分享互动平台,通过内容、线上线下网络为广大危化危废企业提供综合性解决方案,并为政府、' + '货主及物流管理者提供监管平台及数据化分析平台。\n'
  }
};
var mutations = {
  CAHNGE_META_INFO: function CAHNGE_META_INFO(state, metaInfo) {
    state.metaInfo = metaInfo;
  }
};
var _default = exports.default = new _vuex.default.Store({
  state: state,
  mutations: mutations,
  actions: {}
});