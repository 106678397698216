"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = exports.render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _vm.rowData ? _c("div", {
    staticClass: "listRow"
  }, [_c("el-row", {
    staticClass: "header-row",
    attrs: {
      type: "flex",
      justify: "space-between"
    }
  }, [_c("el-col", {
    staticClass: "title-txt font-20 font-bold ellipsis",
    attrs: {
      span: 18
    }
  }, [_vm._v(_vm._s(_vm.rowData.title))]), _c("el-col", {
    staticClass: "address-txt font-20 text-align-right ellipsis",
    attrs: {
      span: 6
    }
  }, [_vm._v(_vm._s(_vm._f("city")(_vm.rowData)))])], 1), _c("div", {
    staticClass: "body-txt font-16 line-clamp2 text-color-666"
  }, [_vm._v(_vm._s(_vm.rowData.content))]), _c("el-row", {
    staticClass: "footer-row"
  }, [_c("el-col", {
    staticClass: "user",
    attrs: {
      span: 12
    }
  }, [_c("el-avatar", {
    key: _vm.rowData.ossHeadUrl,
    staticClass: "vertical-middle margin-right-10",
    attrs: {
      src: _vm.rowData.ossHeadUrl ? _vm.rowData.ossHeadUrl : _vm.avatarNormalIco,
      fit: "cover",
      size: 30,
      alt: "头像"
    },
    on: {
      error: true
    }
  }, [_c("img", {
    attrs: {
      src: "https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png"
    }
  })]), _c("div", {
    staticClass: "user-name font-16 ellipsis"
  }, [_vm._v(_vm._s(_vm.rowData.nickname || _vm.rowData.contactPerson))])], 1), _c("el-col", {
    staticClass: "time font-16 text-align-right ellipsis",
    attrs: {
      span: 12
    }
  }, [_vm._v(_vm._s(_vm._f("timeFilter")(_vm.rowData.releaseTime)))])], 1)], 1) : _vm._e();
};
var staticRenderFns = exports.staticRenderFns = [];
render._withStripped = true;